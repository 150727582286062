import React, { useState, useContext } from 'react'
import { classes, stylesheet } from 'typestyle'
import { em } from 'csx'
import { DropdownMenu, getMedia, MediaType, SlugType } from 'vo-components'
import ModalSlug from '../allsite/ModalSlug'
import ModalCollection from '../allsite/ModalCollection'
import { ModalFormMode } from '../../models/ModalModel'
import Media from '../../components/Media'
import { useImmediateUploader } from '../../components/useUploader'
import { PageKey, PageContext } from '../../components/PageContext'
import { CollectionDetailContext } from './CollectionDetail'
import ModalCustomerReview from '../allsite/ModalCustomerReview'

const styles = stylesheet({
    titleContainer: {
        flexDirection: 'row',
        paddingTop: em(1),
    },
    tagsContainer: {
        marginTop: em(2),
    },
    bannerContainer: {
        paddingTop: em(2),
    },
    buttonsContainer: {
        flex: 1,
    },
    posterUploadIndicatorContainer: {
        right: 'unset',
        bottom: 'unset',
        left: 5,
        top: 5,
    },
    actionButtonContainer: {
        bottom: 'unset',
        left: 'unset',
    },
})

const shouldShowBanner = (pageKey: PageKey) => [PageKey.PORTFOLIO_COLLECTION_ASSET_LIST, PageKey.PROJECT_ASSET_LIST].includes(pageKey)

export default function CollectionDetailTitleBanner() {
    const { cacheHandlerKey, collection, baseUrl } = useContext(CollectionDetailContext)
    const { pageKey } = useContext(PageContext)
    const [isModalSlugOpen, setIsModalSlugOpen] = useState(false)
    const [isModalCollectionOpen, setIsModalCollectionOpen] = useState(false)
    const [isModalReviewOpen, setIsModalReviewOpen] = useState(false)
    const { uploadProgress, open, getInputProps, outputUrl } = useImmediateUploader({
        id: collection.collection_id,
        handler: 'CollectionVideoPoster',
        originalUrl: collection.banner?.poster || undefined,
        noClick: true,
    })
    const bannerMediaType = getMedia(collection.banner?.contentType || '')
    return (
        <>
            <ModalSlug
                type={SlugType.Collection}
                cacheHandlerKey={cacheHandlerKey}
                isOpen={isModalSlugOpen}
                onClose={() => setIsModalSlugOpen(false)}
                collection={collection}
                portfolioSection={collection.portfolio_section || undefined}
                baseUrl={baseUrl}
                shouldRedirect
            />
            <ModalCollection
                mode={ModalFormMode.UPDATE}
                cacheHandlerKey={cacheHandlerKey}
                listingStatus={collection.listing_status || ''}
                isOpen={isModalCollectionOpen}
                portfolioSection={collection.portfolio_section || undefined}
                collection={collection}
                onClose={() => setIsModalCollectionOpen(false)}
            />
            {pageKey === PageKey.CUSTOMER_SESSION_ASSET_LIST && (
                <ModalCustomerReview isOpen={isModalReviewOpen} onClose={() => setIsModalReviewOpen(false)} collection={collection} />
            )}
            <div className="columns is-vcentered">
                {shouldShowBanner(pageKey) && (
                    <div className="column">
                        <div className={styles.bannerContainer}>
                            <figure className="image is-16by9">
                                <Media
                                    file={collection.banner || undefined}
                                    title={`Banner for ${collection.title}`}
                                    videoPosterUrl={outputUrl}
                                    videoPosterUploadProgress={uploadProgress}
                                />
                            </figure>
                        </div>
                    </div>
                )}

                <div className="column">
                    <div className={classes('is-flex', styles.titleContainer)}>
                        <div>
                            <h1 className="is-size-1 title">{collection.title}</h1>
                        </div>
                        <div className={classes('buttons is-right', styles.buttonsContainer)}>
                            <DropdownMenu className="has-background-info" align="right">
                                <button onClick={() => setIsModalCollectionOpen(true)} className="button is-white dropdown-item">
                                    Edit Collection
                                </button>
                                {bannerMediaType === MediaType.VIDEO && (
                                    <>
                                        <input {...getInputProps()} />
                                        <button onClick={() => open()} className="button is-white dropdown-item">
                                            Upload banner poster
                                        </button>
                                    </>
                                )}
                                <button onClick={() => setIsModalSlugOpen(true)} className="button is-white dropdown-item">
                                    Change URL
                                </button>
                            </DropdownMenu>
                            {collection.customer_review && pageKey === PageKey.CUSTOMER_SESSION_ASSET_LIST && (
                                <button onClick={() => setIsModalReviewOpen(true)} className="button is-success">
                                    See Review
                                </button>
                            )}
                        </div>
                    </div>
                    {collection.description && <div dangerouslySetInnerHTML={{ __html: collection.description }} />}
                    {collection.tags && (
                        <div className={classes('tags', styles.tagsContainer)}>
                            {collection.tags.map((tag, index) => (
                                <span key={`tag-${tag || ''}-${index}`} className="tag">
                                    {tag}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
