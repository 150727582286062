import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import slugify from 'slugify'
import { ValueType } from 'react-select'
import { isArray } from 'util'

const components = {
    DropdownIndicator: null,
}

interface Tag {
    label: string
    value: string
}

interface InputTagProps {
    tags?: string[]
    onChange: (tags: string[]) => void
}

export default function InputTag({ tags, onChange }: InputTagProps) {
    const [input, setInput] = useState('')

    const handleChange = (value: ValueType<Tag[]>) => {
        if (value && isArray(value)) {
            onChange(value.map(val => val.label))
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (!input) return
        if (e.key === 'Tab' || e.key === 'Enter') {
            onChange([
                ...(tags || []),
                slugify(input, {
                    lower: true,
                }),
            ])
            setInput('')
            e.preventDefault()
        }
    }
    const handleInputChange = (inputValue: string) => {
        setInput(inputValue)
    }

    return (
        <CreatableSelect<Tag[]>
            components={components}
            inputValue={input}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Type something and press enter..."
            value={(tags || []).map((tag, index) => ({ label: tag, value: `${tag}-${index}` }))}
        />
    )
}
