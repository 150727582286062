import React from 'react'
import { LottieBanana } from './Lottie'

interface ErrorProps {
    errorTrace?: any
    retry?: () => void
    isCompact?: boolean
}

export default function Error({ errorTrace, retry, isCompact }: ErrorProps) {
    return (
        <div className="container has-text-centered">
            {isCompact ? (
                <p className="is-size-6 is-subtitle">Something went wrong! {(errorTrace || 'no trace.').toString()}</p>
            ) : (
                <>
                    <LottieBanana height={320} />
                    <h1 className="is-size-1 is-title">Uh oh...</h1>
                    <p className="is-size-6 is-subtitle">
                        Something went wrong! Report this error with the error message below to help us fix this error.
                    </p>
                    <article className="message is-small">
                        <div className="message-body">{(errorTrace || 'no trace.').toString()}</div>
                    </article>
                </>
            )}

            {retry && (
                <button
                    style={{ marginTop: 20 }}
                    className="button is-info"
                    onClick={() => {
                        retry()
                    }}
                >
                    Retry
                </button>
            )}
        </div>
    )
}
