import React, { useMemo, useContext, useEffect, useState } from 'react'
import { ProfileAboutMe, ProfileType, File, Maybe, Theme } from 'vo-components'
import { useForm } from 'react-hook-form'
import { useProfileMutation, useProfileQuery } from '../../graphql/useProfile'
import { classes, stylesheet } from 'typestyle'
import { em, percent } from 'csx'
import Circle from 'react-circle'
import { useImmediateUploader } from '../../components/useUploader'
import Error from '../../components/Error'

interface AboutMeFormModel {
    blurb?: string
    title?: string
    content?: string
}

interface AboutMeContext {
    pageKey: string
    form: ProfileAboutMe
    file?: Maybe<File>
}

const AboutMeContext = React.createContext<AboutMeContext>({} as AboutMeContext)

interface AboutMeRatio {
    label: string
    className: string
}

const PageKeyRatioMap = new Map<string, AboutMeRatio>([
    ['ABOUT_ME_HOME', { label: '1:1', className: 'is-1by1' }],
    ['ABOUT_ME_CONTACT', { label: '4:5', className: 'is-4by5' }],
])

const coverImageStyles = stylesheet({
    imageEmptyContainer: {
        borderWidth: 1,
        borderRadius: 5,
        borderStyle: 'dashed',
        borderColor: Theme.colors.greyLight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: em(2),
        paddingBottom: em(2),
        height: percent(100),
    },
    imageContainer: {
        position: 'relative',
        alignSelf: 'center',
        width: percent(100),
        height: percent(100),
    },
    image: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: percent(100),
        height: percent(100),
        overflow: 'hidden',
    },
    imageOverlay: {
        backgroundColor: Theme.colors.transparent,
        opacity: 0,
        transition: 'opacity 300ms ease-out',
        $nest: {
            '&:hover': {
                opacity: 1,
            },
        },
    },
    actionButtons: {
        position: 'absolute',
        top: 0,
        right: 0,
        paddingTop: em(0.5),
        paddingRight: em(0.5),
    },
    uploadIndicatorContainer: {
        right: 'unset',
        bottom: 'unset',
        left: 5,
        top: 5,
    },
})

export function AboutMeCoverImage() {
    const { file, pageKey } = useContext(AboutMeContext)
    const { uploadProgress, open, getInputProps, getRootProps, isDragActive, outputUrl } = useImmediateUploader({
        id: pageKey,
        handler: 'Profile',
        originalUrl: file?.public_url || undefined,
    })
    const ratio = useMemo(() => PageKeyRatioMap.get(pageKey), [pageKey])
    return (
        <div className={coverImageStyles.imageContainer} {...getRootProps()}>
            <input {...getInputProps()} />
            {!outputUrl && (
                <div
                    className={coverImageStyles.imageEmptyContainer}
                    style={{
                        backgroundColor: isDragActive ? Theme.colors.whiteBis : 'transparent',
                    }}
                >
                    <p className="is-size-5 has-text-centered title">Drop an image for About Me section</p>
                    <p className="is-size-7 subtitle">Note: suggested ratio is {ratio?.label}</p>
                    <button onClick={() => open()} className="button is-info">
                        Select a file
                    </button>
                </div>
            )}
            {outputUrl && (
                <figure className={`image ${ratio?.className}`}>
                    <div className="has-ratio is-clipped">
                        <div
                            style={{
                                backgroundImage: `url(${outputUrl})`,
                            }}
                            className={coverImageStyles.image}
                        />
                    </div>
                </figure>
            )}
            {uploadProgress && (
                <div className={classes('is-overlay', coverImageStyles.uploadIndicatorContainer)}>
                    <Circle animate={false} progress={uploadProgress || 0} size="20" showPercentage={false} lineWidth="35" />
                </div>
            )}
        </div>
    )
}

const formStyles = stylesheet({
    formControls: {
        marginTop: em(0.5),
    },
    submitInnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
})

interface AboutMeFormProps {
    contentRows?: number
}

export function AboutMeForm({ contentRows }: AboutMeFormProps) {
    const [shouldShowCheckMark, setShouldShowCheckMark] = useState(false)
    const { form, pageKey } = useContext(AboutMeContext)
    const { register, handleSubmit, watch } = useForm<AboutMeFormModel>({
        submitFocusError: true,
        validateCriteriaMode: 'all',
        defaultValues: {
            blurb: form.about_me_blurb || '',
            title: form.about_me_title || '',
            content: form.about_me_content || '',
        },
    })
    const { blurb, title, content } = watch()

    const [save, { loading, error, data }] = useProfileMutation({
        pageKey: pageKey,
        type: ProfileType.AboutMe,
        value: {
            about_me_blurb: blurb || null,
            about_me_title: title || null,
            about_me_content: content || null,
        },
    })

    // Only show checkmark for a certain period after the mutation succeeded.
    useEffect(() => {
        if (data) {
            setShouldShowCheckMark(true)
            const timer = setTimeout(() => {
                setShouldShowCheckMark(false)
            }, 1500)
            return () => {
                clearTimeout(timer)
            }
        }
    }, [data])

    return (
        <form
            onSubmit={handleSubmit(() => {
                save().catch(console.error)
            })}
        >
            <div>
                <div className="field">
                    <label className="label">Blurb</label>
                    <div className="control">
                        <input name="blurb" ref={register} className="input" type="text" placeholder="Message above title. e.g. Hi there," />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Title</label>
                    <div className="control">
                        <input name="title" ref={register} className="input" type="text" placeholder="e.g. NICE TO MEET YOU" />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Content</label>
                    <div className="control">
                        <textarea
                            name="content"
                            ref={register}
                            className="textarea has-fixed-size"
                            placeholder="Content below title. Introduce yourself."
                            rows={contentRows}
                        />
                    </div>
                </div>
            </div>

            <div className={formStyles.formControls}>
                <div className="columns">
                    <div className={classes('column is-narrow', formStyles.submitInnerContainer)}>
                        {error && <p className="has-text-danger">Whoops! Something went wrong</p>}
                    </div>
                    <div className="column">
                        <div className="field is-grouped is-grouped-right">
                            <p className="control">
                                <button type="submit" className={classes('button is-primary', loading && 'is-loading')}>
                                    <span>Save</span>
                                    {shouldShowCheckMark && (
                                        <span className="icon is-small">
                                            <i className="fas fa-check" />
                                        </span>
                                    )}
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

interface AboutMeProps {
    pageKey: string
    children: React.ReactNode
}

export function AboutMe({ pageKey, children }: AboutMeProps) {
    const { loading, error, data, refetch } = useProfileQuery({ pageKey })
    const shouldShowForm = useMemo(() => !loading && !error, [loading, error])
    const form = (data?.showProfile?.value || {}) as ProfileAboutMe
    const file = data?.showProfile?.file
    return (
        <AboutMeContext.Provider
            value={{
                pageKey,
                file,
                form,
            }}
        >
            {error && <Error errorTrace={error} retry={refetch} />}
            {shouldShowForm && children}
        </AboutMeContext.Provider>
    )
}
