import { useQuery, useMutation } from '@apollo/client'
import { MutationArgs, baseFile } from './customTypes'
import {
    SHOW_SLUG,
    LIST_COLLECTIONS_BY_LISTING_STATUS,
    SHOW_CUSTOMER,
    DELETE_COLLECTION,
    CREATE_COLLECTION,
    UPDATE_COLLECTION,
    UPDATE_SLUG,
    SORT_COLLECTION,
    CollectionInput,
    PortfolioSection,
    MutationCreateCollectionArgs,
    Mutation,
    Maybe,
    Slug,
    QueryShowSlugArgs,
    CollectionConnection,
    QueryListCollectionsByListingStatusArgs,
    Collection,
    SlugPortfolioSection,
    MutationUpdateCollectionArgs,
    MutationDeleteCollectionArgs,
    MutationUpdateSlugArgs,
    SlugType,
    MutationSortCollectionArgs,
    Customer,
    QueryShowCustomerArgs,
    CollectionAssetType,
    CollectionSegmentsArgs,
    UPDATE_COLLECTION_ACTIVE_CUSTOMER_PHASE,
    MutationUpdateCollectionActiveCustomerPhaseArgs,
    CollectionCustomerPhase,
} from 'vo-components'
import { FileUpload } from '../models/FileModel'
import { generateNextSortKey } from '../util/sortKey'

export type CollectionCacheHandlerKey = 'PORTFOLIO_SECTION' | 'COLLECTION' | 'CUSTOMER_SESSION' | 'UNKNOWN'

interface CollectionsCreateMutationArgs extends CollectionInput, MutationArgs {
    cacheHandlerKey: CollectionCacheHandlerKey
    file?: FileUpload
    portfolioSection?: PortfolioSection
    customer?: Customer
    latestSortKey?: string
}

export function useCollectionsCreateMutation({
    cacheHandlerKey,
    portfolioSection,
    customer,
    listing_status,
    title,
    description,
    sort_key,
    tags,
    file,
    duration_in_hour,
    hourly_price,
    latestSortKey,
    onCompleted,
    onError,
}: CollectionsCreateMutationArgs) {
    const listingStatus = listing_status || 'COLLECTION'
    const sortKey = sort_key || generateNextSortKey(latestSortKey)
    return useMutation<Partial<Mutation>, MutationCreateCollectionArgs>(CREATE_COLLECTION, {
        onCompleted,
        onError,
        variables: {
            input: {
                listing_status: listingStatus,
                portfolio_section_key: portfolioSection?.portfolio_section_key,
                customer_id: customer?.customer_id,
                sort_key: sortKey,
                title,
                description,
                tags,
                duration_in_hour,
                hourly_price,
            },
        },
        update: (cache, { data: newData }) => {
            if (!newData?.createCollection) return
            const newCollection: Collection = {
                ...newData.createCollection,
                banner: file?.file && {
                    ...baseFile,
                    public_url: file.previewUrl,
                    contentType: file.file.type,
                },
            }
            const handlerMap = new Map<CollectionCacheHandlerKey, () => void>([
                [
                    'PORTFOLIO_SECTION',
                    () => {
                        if (!portfolioSection?.slug) return
                        const data = cache.readQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs>({
                            query: SHOW_SLUG,
                            variables: { type: SlugType.PortfolioSection, slug: portfolioSection.slug },
                        })
                        if (!data?.showSlug) return
                        const slugValue = data.showSlug.value as SlugPortfolioSection
                        const slugPortfolio = slugValue.portfolio_section as PortfolioSection
                        cache.writeQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs>({
                            query: SHOW_SLUG,
                            variables: { type: SlugType.PortfolioSection, slug: portfolioSection.slug },
                            data: {
                                ...data,
                                showSlug: {
                                    ...data?.showSlug,
                                    value: {
                                        ...slugValue,
                                        portfolio_section: {
                                            ...slugPortfolio,
                                            collections: {
                                                ...slugPortfolio.collections,
                                                items: [newCollection, ...(slugValue.portfolio_section?.collections?.items || [])],
                                            },
                                        },
                                    },
                                },
                            },
                        })
                    },
                ],
                [
                    'COLLECTION',
                    () => {
                        const data = cache.readQuery<
                            { listCollectionsByListingStatus: Maybe<CollectionConnection> },
                            QueryListCollectionsByListingStatusArgs
                        >({
                            query: LIST_COLLECTIONS_BY_LISTING_STATUS,
                            variables: { listing_status: listingStatus },
                        })
                        cache.writeQuery<{ listCollectionsByListingStatus: Maybe<CollectionConnection> }, QueryListCollectionsByListingStatusArgs>({
                            query: LIST_COLLECTIONS_BY_LISTING_STATUS,
                            variables: { listing_status: listingStatus },
                            data: {
                                ...data,
                                listCollectionsByListingStatus: {
                                    ...data?.listCollectionsByListingStatus,
                                    items: [newCollection, ...(data?.listCollectionsByListingStatus?.items || [])],
                                },
                            },
                        })
                    },
                ],
                [
                    'CUSTOMER_SESSION',
                    () => {
                        if (!customer) return
                        const data = cache.readQuery<{ showCustomer: Maybe<Customer> }, QueryShowCustomerArgs>({
                            query: SHOW_CUSTOMER,
                            variables: { customer_id: customer.customer_id },
                        })
                        if (!data?.showCustomer) return
                        cache.writeQuery<{ showCustomer: Maybe<Customer> }, QueryShowCustomerArgs>({
                            query: SHOW_CUSTOMER,
                            variables: { customer_id: customer.customer_id || '' },
                            data: {
                                ...data,
                                showCustomer: {
                                    ...data?.showCustomer,
                                    sessions: {
                                        ...data?.showCustomer?.sessions,
                                        items: [newCollection, ...(data?.showCustomer?.sessions?.items || [])],
                                    },
                                },
                            },
                        })
                    },
                ],
            ])
            try {
                const handler = handlerMap.get(cacheHandlerKey)
                if (handler) {
                    handler()
                }
            } catch (e) {
                console.debug('no cache to be updated for `useCollectionsCreateMutation`')
            }
        },
    })
}

interface CollectionsUpdateMutationArgs extends CollectionInput, MutationArgs {
    cacheHandlerKey: CollectionCacheHandlerKey
    file?: FileUpload
    portfolioSection?: PortfolioSection
    customer?: Customer
    collection: Collection
}

export function useCollectionsUpdateMutation({
    cacheHandlerKey,
    listing_status,
    portfolioSection,
    customer,
    collection,
    title,
    description,
    tags,
    file,
    duration_in_hour,
    hourly_price,
    onCompleted,
}: CollectionsUpdateMutationArgs) {
    const listingStatus = collection.listing_status || listing_status
    return useMutation<Partial<Mutation>, MutationUpdateCollectionArgs>(UPDATE_COLLECTION, {
        onCompleted,
        variables: {
            collection_id: collection?.collection_id || '',
            input: {
                listing_status: listingStatus,
                sort_key: collection?.sort_key,
                title,
                description,
                tags,
                duration_in_hour,
                hourly_price,
            },
        },
        update: (cache, { data: newData }) => {
            if (!newData?.updateCollection || !collection) return
            const updatedCollection: Collection = {
                ...newData.updateCollection,
                banner: file?.file
                    ? {
                          ...baseFile,
                          public_url: file.previewUrl,
                          contentType: file.file.type,
                      }
                    : newData.updateCollection.banner,
            }
            const handlerMap = new Map<CollectionCacheHandlerKey, () => void>([
                [
                    'PORTFOLIO_SECTION',
                    () => {
                        if (!portfolioSection?.slug) return
                        const data = cache.readQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs>({
                            query: SHOW_SLUG,
                            variables: { type: SlugType.PortfolioSection, slug: portfolioSection.slug },
                        })
                        if (!data?.showSlug) return
                        const slugValue = data.showSlug.value as SlugPortfolioSection
                        const slugPortfolio = slugValue.portfolio_section as PortfolioSection
                        cache.writeQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs>({
                            query: SHOW_SLUG,
                            variables: { type: SlugType.PortfolioSection, slug: portfolioSection.slug },
                            data: {
                                ...data,
                                showSlug: {
                                    ...data.showSlug,
                                    value: {
                                        ...slugValue,
                                        portfolio_section: {
                                            ...slugPortfolio,
                                            collections: {
                                                ...slugPortfolio.collections,
                                                items:
                                                    slugPortfolio.collections?.items.map(collectionItem =>
                                                        collectionItem.collection_id === collection.collection_id ? updatedCollection : collectionItem
                                                    ) || [],
                                            },
                                        },
                                    },
                                },
                            },
                        })
                    },
                ],
                [
                    'COLLECTION',
                    () => {
                        const data = cache.readQuery<
                            { listCollectionsByListingStatus: Maybe<CollectionConnection> },
                            QueryListCollectionsByListingStatusArgs
                        >({
                            query: LIST_COLLECTIONS_BY_LISTING_STATUS,
                            variables: { listing_status: listingStatus },
                        })
                        cache.writeQuery<{ listCollectionsByListingStatus: Maybe<CollectionConnection> }, QueryListCollectionsByListingStatusArgs>({
                            query: LIST_COLLECTIONS_BY_LISTING_STATUS,
                            variables: { listing_status: listingStatus },
                            data: {
                                ...data,
                                listCollectionsByListingStatus: {
                                    ...data?.listCollectionsByListingStatus,
                                    items:
                                        data?.listCollectionsByListingStatus?.items.map(collectionItem =>
                                            collectionItem.collection_id === collection.collection_id ? updatedCollection : collectionItem
                                        ) || [],
                                },
                            },
                        })
                    },
                ],
                [
                    'CUSTOMER_SESSION',
                    () => {
                        if (!customer) return
                        const data = cache.readQuery<{ showCustomer: Maybe<Customer> }, QueryShowCustomerArgs>({
                            query: SHOW_CUSTOMER,
                            variables: { customer_id: customer.customer_id },
                        })
                        if (!data?.showCustomer) return
                        cache.writeQuery<{ showCustomer: Maybe<Customer> }, QueryShowCustomerArgs>({
                            query: SHOW_CUSTOMER,
                            variables: { customer_id: customer.customer_id || '' },
                            data: {
                                ...data,
                                showCustomer: {
                                    ...data?.showCustomer,
                                    sessions: {
                                        ...data?.showCustomer?.sessions,
                                        items:
                                            data?.showCustomer?.sessions?.items.map(collectionItem =>
                                                collectionItem.collection_id === collection.collection_id ? updatedCollection : collectionItem
                                            ) || [],
                                    },
                                },
                            },
                        })
                    },
                ],
            ])
            try {
                const handler = handlerMap.get(cacheHandlerKey)
                if (handler) {
                    handler()
                }
            } catch (e) {
                console.debug('no cache to be updated for `useCollectionsUpdateMutation`')
            }
        },
    })
}

interface CollectionsDeleteMutationArgs extends MutationArgs {
    cacheHandlerKey: CollectionCacheHandlerKey
    portfolioSection?: PortfolioSection
    customer?: Customer
    collection: Collection
}

export function useCollectionsDeleteMutation({ cacheHandlerKey, portfolioSection, customer, collection }: CollectionsDeleteMutationArgs) {
    const listingStatus = collection.listing_status || 'PORTFOLIO'
    return useMutation<Partial<Mutation>, MutationDeleteCollectionArgs>(DELETE_COLLECTION, {
        variables: {
            collection_id: collection.collection_id,
        },
        update: cache => {
            const handlerMap = new Map<CollectionCacheHandlerKey, () => void>([
                [
                    'PORTFOLIO_SECTION',
                    () => {
                        if (!portfolioSection?.slug) return
                        const data = cache.readQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs>({
                            query: SHOW_SLUG,
                            variables: { type: SlugType.PortfolioSection, slug: portfolioSection.slug },
                        })
                        if (!data?.showSlug) return
                        const slugValue = data.showSlug.value as SlugPortfolioSection
                        const slugPortfolio = slugValue.portfolio_section as PortfolioSection
                        cache.writeQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs>({
                            query: SHOW_SLUG,
                            variables: { type: SlugType.PortfolioSection, slug: portfolioSection.slug },
                            data: {
                                ...data,
                                showSlug: {
                                    ...data.showSlug,
                                    value: {
                                        ...slugValue,
                                        portfolio_section: {
                                            ...slugPortfolio,
                                            collections: {
                                                ...slugPortfolio.collections,
                                                items:
                                                    slugPortfolio.collections?.items.filter(
                                                        collectionItem => collectionItem.collection_id !== collection.collection_id
                                                    ) || [],
                                            },
                                        },
                                    },
                                },
                            },
                        })
                    },
                ],
                [
                    'COLLECTION',
                    () => {
                        const data = cache.readQuery<
                            { listCollectionsByListingStatus: Maybe<CollectionConnection> },
                            QueryListCollectionsByListingStatusArgs
                        >({
                            query: LIST_COLLECTIONS_BY_LISTING_STATUS,
                            variables: { listing_status: listingStatus },
                        })
                        cache.writeQuery<{ listCollectionsByListingStatus: Maybe<CollectionConnection> }, QueryListCollectionsByListingStatusArgs>({
                            query: LIST_COLLECTIONS_BY_LISTING_STATUS,
                            variables: { listing_status: listingStatus },
                            data: {
                                ...data,
                                listCollectionsByListingStatus: {
                                    ...data?.listCollectionsByListingStatus,
                                    items:
                                        data?.listCollectionsByListingStatus?.items.filter(
                                            collectionItem => collectionItem.collection_id !== collection.collection_id
                                        ) || [],
                                },
                            },
                        })
                    },
                ],
                [
                    'CUSTOMER_SESSION',
                    () => {
                        if (!customer) return
                        const data = cache.readQuery<{ showCustomer: Maybe<Customer> }, QueryShowCustomerArgs>({
                            query: SHOW_CUSTOMER,
                            variables: { customer_id: customer.customer_id || '' },
                        })
                        if (!data?.showCustomer) return
                        cache.writeQuery<{ showCustomer: Maybe<Customer> }, QueryShowCustomerArgs>({
                            query: SHOW_CUSTOMER,
                            variables: { customer_id: customer.customer_id || '' },
                            data: {
                                ...data,
                                showCustomer: {
                                    ...data?.showCustomer,
                                    sessions: {
                                        ...data?.showCustomer?.sessions,
                                        items:
                                            data?.showCustomer?.sessions?.items.filter(
                                                collectionItem => collectionItem.collection_id !== collection.collection_id
                                            ) || [],
                                    },
                                },
                            },
                        })
                    },
                ],
            ])
            try {
                const handler = handlerMap.get(cacheHandlerKey)
                if (handler) {
                    handler()
                }
            } catch (e) {
                console.debug('no cache to be updated for `useCollectionsDeleteMutation`')
            }
        },
    })
}

interface CollectionsUpdateSlugMutationArgs extends MutationArgs {
    cacheHandlerKey: CollectionCacheHandlerKey
    portfolioSection?: PortfolioSection
    customer?: Customer
    collection: Collection
    slug: string
}

export function useCollectionsUpdateSlugMutation({
    cacheHandlerKey,
    portfolioSection,
    customer,
    collection,
    slug,
    onCompleted,
}: CollectionsUpdateSlugMutationArgs) {
    const listingStatus = collection.listing_status || 'PORTFOLIO'
    return useMutation<Partial<Mutation>, MutationUpdateSlugArgs>(UPDATE_SLUG, {
        onCompleted,
        variables: {
            input: {
                slug,
                type: SlugType.Collection,
                resource: collection.collection_id,
            },
        },
        update: (cache, { data: newData }) => {
            if (!newData?.updateSlug) return
            const handlerMap = new Map<CollectionCacheHandlerKey, () => void>([
                [
                    'PORTFOLIO_SECTION',
                    () => {
                        if (!portfolioSection?.slug) return
                        const data = cache.readQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs>({
                            query: SHOW_SLUG,
                            variables: { type: SlugType.PortfolioSection, slug: portfolioSection.slug },
                        })
                        if (!data?.showSlug) return
                        const slugValue = data.showSlug.value as SlugPortfolioSection
                        const slugPortfolio = slugValue.portfolio_section as PortfolioSection
                        cache.writeQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs>({
                            query: SHOW_SLUG,
                            variables: { type: SlugType.PortfolioSection, slug: portfolioSection.slug },
                            data: {
                                ...data,
                                showSlug: {
                                    ...data.showSlug,
                                    value: {
                                        ...slugValue,
                                        portfolio_section: {
                                            ...slugPortfolio,
                                            collections: {
                                                ...slugPortfolio.collections,
                                                items:
                                                    slugPortfolio.collections?.items.map(collectionItem => ({
                                                        ...collectionItem,
                                                        slug:
                                                            collectionItem.collection_id === collection.collection_id
                                                                ? newData.updateSlug?.slug
                                                                : collectionItem.slug,
                                                    })) || [],
                                            },
                                        },
                                    },
                                },
                            },
                        })
                    },
                ],
                [
                    'COLLECTION',
                    () => {
                        const data = cache.readQuery<
                            { listCollectionsByListingStatus: Maybe<CollectionConnection> },
                            QueryListCollectionsByListingStatusArgs
                        >({
                            query: LIST_COLLECTIONS_BY_LISTING_STATUS,
                            variables: { listing_status: listingStatus },
                        })
                        cache.writeQuery<{ listCollectionsByListingStatus: Maybe<CollectionConnection> }, QueryListCollectionsByListingStatusArgs>({
                            query: LIST_COLLECTIONS_BY_LISTING_STATUS,
                            variables: { listing_status: listingStatus },
                            data: {
                                ...data,
                                listCollectionsByListingStatus: {
                                    ...data?.listCollectionsByListingStatus,
                                    items:
                                        data?.listCollectionsByListingStatus?.items.map(collectionItem => ({
                                            ...collectionItem,
                                            slug:
                                                collectionItem.collection_id === collection.collection_id
                                                    ? newData.updateSlug?.slug
                                                    : collectionItem.slug,
                                        })) || [],
                                },
                            },
                        })
                    },
                ],
                [
                    'CUSTOMER_SESSION',
                    () => {
                        if (!customer) return
                        const data = cache.readQuery<{ showCustomer: Maybe<Customer> }, QueryShowCustomerArgs>({
                            query: SHOW_CUSTOMER,
                            variables: { customer_id: customer.customer_id },
                        })
                        if (!data?.showCustomer) return
                        cache.writeQuery<{ showCustomer: Maybe<Customer> }, QueryShowCustomerArgs>({
                            query: SHOW_CUSTOMER,
                            variables: { customer_id: customer.customer_id || '' },
                            data: {
                                ...data,
                                showCustomer: {
                                    ...data?.showCustomer,
                                    sessions: {
                                        ...data?.showCustomer?.sessions,
                                        items:
                                            data?.showCustomer?.sessions?.items.map(collectionItem => ({
                                                ...collectionItem,
                                                slug:
                                                    collectionItem.collection_id === collection.collection_id
                                                        ? newData.updateSlug?.slug
                                                        : collectionItem.slug,
                                            })) || [],
                                    },
                                },
                            },
                        })
                    },
                ],
            ])
            try {
                const handler = handlerMap.get(cacheHandlerKey)
                if (handler) {
                    handler()
                }
            } catch (e) {
                console.debug('no cache to be updated for `useCollectionsUpdateSlugMutation`')
            }
        },
    })
}

interface CollectionsSortMutationArgs extends MutationArgs {
    cacheHandlerKey: CollectionCacheHandlerKey
    portfolioSection?: PortfolioSection
    collection: Collection
    sortKey: string
}

export function useCollectionsSortMutation({ cacheHandlerKey, portfolioSection, collection, sortKey }: CollectionsSortMutationArgs) {
    const listingStatus = collection.listing_status || 'PORTFOLIO'
    return useMutation<Partial<Mutation>, MutationSortCollectionArgs>(SORT_COLLECTION, {
        variables: {
            collection_id: collection.collection_id,
            sort_key: sortKey,
        },
        update: (cache, { data: newData }) => {
            if (!newData?.sortCollection) return
            const updatedCollection: Collection = {
                ...collection,
                ...newData.sortCollection,
            }
            const handlerMap = new Map<CollectionCacheHandlerKey, () => void>([
                [
                    'PORTFOLIO_SECTION',
                    () => {
                        if (!portfolioSection?.slug) return
                        const data = cache.readQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs>({
                            query: SHOW_SLUG,
                            variables: { type: SlugType.PortfolioSection, slug: portfolioSection.slug },
                        })
                        if (!data?.showSlug) return
                        const slugValue = data.showSlug.value as SlugPortfolioSection
                        const slugPortfolio = slugValue.portfolio_section as PortfolioSection
                        cache.writeQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs>({
                            query: SHOW_SLUG,
                            variables: { type: SlugType.PortfolioSection, slug: portfolioSection.slug },
                            data: {
                                ...data,
                                showSlug: {
                                    ...data.showSlug,
                                    value: {
                                        ...slugValue,
                                        portfolio_section: {
                                            ...slugPortfolio,
                                            collections: {
                                                ...slugPortfolio.collections,
                                                items:
                                                    slugPortfolio.collections?.items.map(collectionItem =>
                                                        collectionItem.collection_id === collection.collection_id ? updatedCollection : collectionItem
                                                    ) || [],
                                            },
                                        },
                                    },
                                },
                            },
                        })
                    },
                ],
                [
                    'COLLECTION',
                    () => {
                        const data = cache.readQuery<
                            { listCollectionsByListingStatus: Maybe<CollectionConnection> },
                            QueryListCollectionsByListingStatusArgs
                        >({
                            query: LIST_COLLECTIONS_BY_LISTING_STATUS,
                            variables: { listing_status: listingStatus },
                        })
                        cache.writeQuery<{ listCollectionsByListingStatus: Maybe<CollectionConnection> }, QueryListCollectionsByListingStatusArgs>({
                            query: LIST_COLLECTIONS_BY_LISTING_STATUS,
                            variables: { listing_status: listingStatus },
                            data: {
                                ...data,
                                listCollectionsByListingStatus: {
                                    ...data?.listCollectionsByListingStatus,
                                    items:
                                        data?.listCollectionsByListingStatus?.items.map(collectionItem =>
                                            collectionItem.collection_id === collection.collection_id ? updatedCollection : collectionItem
                                        ) || [],
                                },
                            },
                        })
                    },
                ],
            ])
            try {
                const handler = handlerMap.get(cacheHandlerKey)
                if (handler) {
                    handler()
                }
            } catch (e) {
                console.debug('no cache to be updated for `useCollectionsSortMutation`')
            }
        },
        optimisticResponse: {
            __typename: 'Mutation',
            sortCollection: {
                __typename: 'Collection',
                ...collection,
                sort_key: sortKey,
            },
        },
    })
}

interface CollectionsUpdateActiveCustomerPhaseMutationArgs {
    collection: Collection
    activeCustomerPhase: CollectionCustomerPhase
}

export function useCollectionsUpdateActiveCustomerPhase({ collection, activeCustomerPhase }: CollectionsUpdateActiveCustomerPhaseMutationArgs) {
    return useMutation<Partial<Mutation>, MutationUpdateCollectionActiveCustomerPhaseArgs>(UPDATE_COLLECTION_ACTIVE_CUSTOMER_PHASE, {
        variables: {
            collection_id: collection.collection_id,
            input: {
                active_customer_phase: activeCustomerPhase,
            },
        },
    })
}

interface SlugCollectionQueryArgs {
    slug: string
    types: CollectionAssetType[]
}

export const COLLECTION_ASSETS_LIMIT = 500

export function useCollectionsSlugQuery({ slug, types }: SlugCollectionQueryArgs) {
    return useQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs & CollectionSegmentsArgs>(SHOW_SLUG, {
        variables: { type: SlugType.Collection, slug, types, limit: COLLECTION_ASSETS_LIMIT },
    })
}

interface CollectionsQueryArgs {
    listingStatus: string
}

export function useCollectionsQuery({ listingStatus }: CollectionsQueryArgs) {
    return useQuery<{ listCollectionsByListingStatus: Maybe<CollectionConnection> }, QueryListCollectionsByListingStatusArgs>(
        LIST_COLLECTIONS_BY_LISTING_STATUS,
        {
            variables: { listing_status: listingStatus },
        }
    )
}
