import React from 'react'
import { File, DynamicImage, VideoPlayer, getMedia, isLocalUrl, MediaType, Theme } from 'vo-components'
import { LottieBouncingFruits } from './Lottie'
import { classes, stylesheet } from 'typestyle'
import Circle from 'react-circle'

const styles = stylesheet({
    posterUploadIndicatorContainer: {
        right: 'unset',
        bottom: 'unset',
        left: 5,
        top: 5,
    },
    videoNotReadyContainer: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Theme.colors.greyDark,
    },
})

interface MediaProps {
    file?: File
    title?: string
    videoPosterUrl?: string
    videoPosterUploadProgress?: number
}

export default function Media({ file, title, videoPosterUrl, videoPosterUploadProgress }: MediaProps) {
    if (!file) return null
    const mediaType = getMedia(file.contentType || '')
    if (mediaType === MediaType.IMAGE) {
        return <DynamicImage alt={title} src={file.public_url || ''} srcSet={[{ maxScreenWidth: 480, imageWidth: 480 }]} width={1280} />
    }
    if (mediaType === MediaType.VIDEO) {
        return (
            <>
                <div className="has-ratio">
                    {(file.is_ready || isLocalUrl(file.public_url || '')) && file.public_url ? (
                        <VideoPlayer poster={videoPosterUrl} url={file.public_url} />
                    ) : (
                        <div className={classes('image is-16by9', styles.videoNotReadyContainer)}>
                            <div className="has-ratio">
                                <LottieBouncingFruits height="80%" />
                                <p className="has-size-5 has-text-centered">Processing video...</p>
                            </div>
                        </div>
                    )}
                </div>
                {videoPosterUploadProgress && (
                    <div className={classes('is-overlay', styles.posterUploadIndicatorContainer)}>
                        <Circle animate={false} progress={videoPosterUploadProgress || 0} size="20" showPercentage={false} lineWidth="35" />
                    </div>
                )}
            </>
        )
    }
    return null
}
