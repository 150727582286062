import React, { useRef } from 'react'
import { classes, stylesheet } from 'typestyle'
import { em, viewWidth, percent } from 'csx'
import { useSpring, animated } from 'react-spring'
import { useClickAway, useKey } from 'react-use'

import { Theme, Modal } from 'vo-components'
import Error from './Error'

const styles = stylesheet({
    overlay: {
        backgroundColor: Theme.colors.overlayDarker,
        display: 'flex',
        flexDirection: 'column-reverse',
        overflow: 'hidden',
    },
    container: {
        overflow: 'hidden',
        backgroundColor: 'white',
        borderStyle: 'solid',
        borderColor: Theme.colors.overlayDarker,
    },
    titleContainer: {
        paddingBottom: em(1),
    },
})

interface DialogProps {
    isOpen: boolean
    onClose: () => void
    onYes: () => void
    title?: string
    subtitle?: string
    yesLabel?: string
    noLabel?: string
    loading?: boolean
    error?: any
}

export default function Dialog({
    isOpen,
    onClose,
    onYes,
    title = 'Are you sure?',
    subtitle = '',
    yesLabel = 'Yes',
    noLabel = 'No',
    loading = false,
    error = null,
}: DialogProps) {
    const containerRef = useRef(null)
    useClickAway(containerRef, onClose)
    const animatedStyle = useSpring({ transform: isOpen ? 'translateY(0)' : 'translateY(100%)' })
    if (!isOpen) return null
    return (
        <div className={classes('is-overlay', styles.overlay)}>
            <animated.div ref={containerRef} className={styles.container} style={animatedStyle}>
                <div style={{ padding: em(1) }}>
                    <div className={styles.titleContainer}>
                        <p className="is-size-6 has-text-centered">{title}</p>
                        {subtitle && <p className="is-size-7 has-text-centered has-text-grey">{subtitle}</p>}
                    </div>
                    <div className="buttons is-centered">
                        <button onClick={() => onYes()} className={classes('button is-danger', loading && 'is-loading')}>
                            {yesLabel}
                        </button>
                        <button onClick={() => onClose()} className="button">
                            {noLabel}
                        </button>
                    </div>
                    {error && <Error isCompact errorTrace={error} />}
                </div>
            </animated.div>
        </div>
    )
}

const modalStyles = stylesheet({
    modal: {
        width: viewWidth(50),
    },
    container: {
        backgroundColor: Theme.colors.white,
        height: percent(100),
        padding: `${em(1)} ${em(1)}`,
    },
    headerContainer: {
        marginBottom: em(3),
    },
})

export function DialogModal({
    isOpen,
    onClose,
    onYes,
    title = 'Are you sure?',
    subtitle = '',
    yesLabel = 'Yes',
    noLabel = 'No',
    loading = false,
    error = null,
}: DialogProps) {
    useKey('Enter', () => isOpen && onYes(), {}, [isOpen, onYes])
    useKey('Esc', () => isOpen && onClose(), {}, [isOpen, onClose])
    return (
        <Modal isOpen={isOpen} onClose={onClose} className={modalStyles.modal}>
            <div className={modalStyles.container}>
                <div className={modalStyles.headerContainer}>
                    <p className="title is-size-3 has-text-centered">{title}</p>
                    <p className="subtitle has-text-centered">{subtitle}</p>
                </div>
                <div className="buttons is-centered">
                    <button onClick={() => onYes()} className={classes('button is-danger', loading && 'is-loading')}>
                        {yesLabel}
                    </button>
                    <button onClick={() => onClose()} className="button">
                        {noLabel}
                    </button>
                </div>
                {error && <Error isCompact errorTrace={error} />}
            </div>
        </Modal>
    )
}
