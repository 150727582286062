import React from 'react'
import { Modal, Collection } from 'vo-components'
import { classes, stylesheet } from 'typestyle'
import { em, viewWidth, percent } from 'csx'
import { BasicModalProps } from '../../models/ModalModel'
import { LottieCoffee, LottieBeeLike } from '../../components/Lottie'
import sample from 'lodash.sample'

const styles = stylesheet({
    modal: {
        width: viewWidth(50),
    },
    container: {
        height: percent(100),
        padding: em(2),
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
})

interface ModalCustomerReviewProps extends BasicModalProps {
    collection: Collection
}

const reviewQuotes = new Map<string, string[]>([
    [
        'BAD',
        [
            'There is always room for improvement in our game.',
            'Everything is perfect and there is always room for improvement.',
            'Make improvements, not excuses. Seek respect, not attention.',
            "Act the way you'd like to be and soon you'll be the way you'd like to act",
            'Do it again. Play it again. Sing it again. Read it again. Write it again. Sketch it again. Rehearse it again. Run it again. Try it again. Because again is practice, and practice is improvement, and improvement only leads to perfection.',
            "No matter how good you get you can always get better, and that's the exciting part.",
        ],
    ],
    [
        'GOOD',
        [
            'SUCCESS IS DELIVERED ONLY TO THE DESERVING. CONGRATULATIONS ON YOUR NEWLY FOUND ACHIEVEMENT!',
            'I AM PROUD OF WHAT YOU HAVE ACHIEVED. CONGRATULATIONS!!!',
            'YOU DID A GREAT JOB. INDEED A HUGE ACHIEVEMENT. CONGRATULATIONS!!!',
            'CONGRATULATIONS ON YOUR ACHIEVEMENTS! YOU HAVE MADE US ALL PROUD.',
            'THE BEST WAY TO PREDICT YOU FUTURE IS TO CREATE IT. YOU HAVE CREATED A GREAT ONE. CONGRATULATIONS ON YOUR ACHIEVEMENT!!!',
            'YOUR TODAY’S SUCCESS IS THE BEGINNING OF TOMORROW’S ACHIEVEMENT. CONGRATULATIONS!!!',
        ],
    ],
])

export default function ModalCustomerReview({ isOpen, onClose, collection }: ModalCustomerReviewProps) {
    const { customer_review } = collection
    if (!customer_review) return null
    return (
        <Modal isOpen={isOpen} onClose={() => onClose()} className={styles.modal}>
            <div className={classes('columns', 'is-marginless', styles.container)}>
                <div className={classes('column', styles.innerContainer)}>
                    {customer_review.rating === 'GOOD' ? <LottieBeeLike height={250} /> : <LottieCoffee height={250} />}
                    <div className="content">
                        <blockquote>{sample(reviewQuotes.get(customer_review.rating || '') || [])}</blockquote>
                    </div>
                    <hr />
                    <p className="is-size-6 title">Testimonial/Feedback:</p>
                    <div className="has-newline">{customer_review.comment || 'no details provided'}</div>
                </div>
            </div>
        </Modal>
    )
}
