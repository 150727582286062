import React, { useMemo, useEffect, useContext } from 'react'
import { Modal, Banner } from 'vo-components'
import { classes, stylesheet } from 'typestyle'
import { em, viewWidth, viewHeight, url, percent } from 'csx'
import { useForm, FormContext, useFormContext } from 'react-hook-form'
import { useBannersUpdateMutation, useBannersCreateMutation } from '../../graphql/useBanners'
import { FileUpload } from '../../models/FileModel'
import { useUploader } from '../../components/useUploader'
import { BasicModalProps, ModalFormProps, ModalFormMode, ModalInnerFormProps } from '../../models/ModalModel'

interface BannerModalContext extends BasicModalProps {
    file: FileUpload
    uploadProgress?: number
    onCompleted: (data: { createBanner?: Banner | null }) => void
}

const BannerModalContext = React.createContext<BannerModalContext>({} as BannerModalContext)

interface BannerFormModel {
    link: string
}

const styles = stylesheet({
    modal: {
        position: 'absolute',
        top: viewHeight(10),
        left: viewWidth(15),
        right: viewWidth(15),
        bottom: viewHeight(10),
    },
    container: {
        height: percent(100),
        padding: em(2),
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    formContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    imageContainer: {
        backgroundPosition: 'top center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        minHeight: percent(50),
    },
    formControls: {
        marginTop: em(0.5),
    },
    submitInnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    progressBar: {
        minWidth: 200,
        width: viewWidth(20),
    },
})

function BannerForm({ write, loading, error }: ModalInnerFormProps) {
    const {
        isOpen,
        onClose,
        file: { previewUrl },
        uploadProgress,
    } = useContext(BannerModalContext)
    const { register } = useFormContext()
    return (
        <Modal isOpen={isOpen} onClose={() => onClose()} className={styles.modal}>
            <div className={classes('columns', 'is-marginless', styles.container)}>
                <div className={classes('column', styles.innerContainer)}>
                    <figure
                        style={{
                            backgroundImage: previewUrl && url(encodeURI(previewUrl)),
                        }}
                        className={classes('image', styles.imageContainer)}
                    />
                    <form
                        onSubmit={async e => {
                            e.preventDefault()
                            await write().catch(console.error)
                        }}
                        className={classes(styles.formContainer)}
                    >
                        <div>
                            <div className="field">
                                <label className="label">URL Link (optional)</label>
                                <div className="control">
                                    <input name="link" ref={register} className="input" type="text" placeholder="Provide a link for this banner" />
                                </div>
                            </div>
                        </div>

                        <div className={styles.formControls}>
                            <div className="columns">
                                <div className={classes('column is-narrow', styles.submitInnerContainer)}>
                                    {error && <p className="has-text-danger">Whoops! Something went wrong</p>}
                                    {uploadProgress && (
                                        <>
                                            <p>
                                                uploading ({uploadProgress}
                                                %)...
                                            </p>
                                            <progress className={classes('progress is-primary', styles.progressBar)} value={uploadProgress} max="100">
                                                {uploadProgress}%
                                            </progress>
                                        </>
                                    )}
                                </div>
                                <div className="column">
                                    <div className="field is-grouped is-grouped-right">
                                        <p className="control">
                                            <button type="button" onClick={() => onClose()} className="button is-light">
                                                Cancel
                                            </button>
                                        </p>
                                        <p className="control">
                                            <button
                                                type="submit"
                                                className={classes('button is-primary', (loading || !!uploadProgress) && 'is-loading')}
                                            >
                                                Submit
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

interface BannerUpdateProps {
    banner: Banner
}

function ModalBannerUpdate({ banner }: BannerUpdateProps) {
    const { onCompleted } = useContext(BannerModalContext)
    const { watch } = useFormContext<BannerFormModel>()
    const { link } = watch({ nest: true })
    const [update, { loading, error }] = useBannersUpdateMutation({
        banner,
        link,
        onCompleted,
    })
    return <BannerForm write={update} loading={loading} error={error} />
}

interface BannerCreateProps {
    latestSortKey?: string
}

function ModalBannerCreate({ latestSortKey }: BannerCreateProps) {
    const { onCompleted, file } = useContext(BannerModalContext)
    const { watch } = useFormContext<BannerFormModel>()
    const { link } = watch({ nest: true })
    const [create, { loading, error }] = useBannersCreateMutation({
        link,
        file,
        onCompleted,
        latestSortKey,
    })
    return <BannerForm write={create} loading={loading} error={error} />
}

interface ModalBannerProps extends BasicModalProps, ModalFormProps {
    banner?: Banner
    fileToBeUploaded?: File
    latestSortKey?: string
}

export default function ModalBanner({ isOpen, onClose, banner, fileToBeUploaded, latestSortKey, mode }: ModalBannerProps) {
    const previewUrl = useMemo<string | undefined>(
        () => (fileToBeUploaded && URL.createObjectURL(fileToBeUploaded)) || banner?.file?.public_url || undefined,
        [fileToBeUploaded, banner]
    )
    const formMethods = useForm<BannerFormModel>({
        submitFocusError: true,
        validateCriteriaMode: 'all',
        defaultValues: {
            link: banner?.link || '',
        },
    })
    const finish = () => {
        onClose()
        const form = formMethods.getValues()
        formMethods.reset(form)
    }

    const { upload, uploadProgress, resetProgress } = useUploader({
        onCompleted: () => {
            finish()
        },
    })
    useEffect(() => {
        if (isOpen) {
            resetProgress()
        }
    }, [isOpen, resetProgress])

    const onCompleted = async (data: { createBanner?: Banner | null }) => {
        if (fileToBeUploaded && data.createBanner) {
            upload({ id: data.createBanner.banner_id, handler: 'Banner', file: fileToBeUploaded, access_level: 'public' })
        } else {
            finish()
        }
    }
    const file: FileUpload = {
        file: fileToBeUploaded,
        previewUrl,
    }
    return (
        <BannerModalContext.Provider
            value={{
                isOpen,
                onClose,
                file,
                uploadProgress,
                onCompleted,
            }}
        >
            <FormContext {...formMethods}>
                {mode === ModalFormMode.CREATE && <ModalBannerCreate latestSortKey={latestSortKey} />}
                {mode === ModalFormMode.UPDATE && banner && <ModalBannerUpdate banner={banner} />}
            </FormContext>
        </BannerModalContext.Provider>
    )
}
