import { useQuery, useMutation } from '@apollo/client'
import {
    CREATE_CUSTOMER,
    UPDATE_CUSTOMER,
    DELETE_CUSTOMER,
    LIST_CUSTOMERS,
    SHOW_CUSTOMER,
    Maybe,
    CustomerConnection,
    QueryListCustomersArgs,
    CustomerInput,
    Customer,
    MutationCreateCustomerArgs,
    MutationUpdateCustomerArgs,
    Mutation,
    QueryShowCustomerArgs,
    MutationDeleteCustomerArgs,
} from 'vo-components'
import { MutationArgs } from './customTypes'

interface CustomersCreateMutationArgs extends CustomerInput, MutationArgs {}

export function useCustomersCreateMutation({
    name,
    email,
    access_key,
    instagram_username,
    description,
    onCompleted,
    onError,
}: CustomersCreateMutationArgs) {
    return useMutation<Partial<Mutation>, MutationCreateCustomerArgs>(CREATE_CUSTOMER, {
        onCompleted,
        onError,
        variables: {
            input: {
                name,
                email,
                access_key,
                description,
                instagram_username,
            },
        },
        update: (cache, { data: newData }) => {
            if (!newData?.createCustomer) return
            const newCustomer: Customer = {
                ...newData.createCustomer,
            }
            try {
                const data = cache.readQuery<{ listCustomers: Maybe<CustomerConnection> }, QueryListCustomersArgs>({
                    query: LIST_CUSTOMERS,
                    variables: { listing_status: 'DEFAULT' },
                })
                if (!data?.listCustomers) return
                cache.writeQuery<{ listCustomers: Maybe<CustomerConnection> }, QueryListCustomersArgs>({
                    query: LIST_CUSTOMERS,
                    variables: { listing_status: 'DEFAULT' },
                    data: {
                        ...data,
                        listCustomers: {
                            ...data.listCustomers,
                            items: [newCustomer, ...data.listCustomers.items],
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useCustomersCreateMutation`')
            }
        },
    })
}

interface CustomersUpdateMutationArgs extends CustomerInput, MutationArgs {
    customer: Customer
}

export function useCustomersUpdateMutation({
    customer,
    name,
    email,
    access_key,
    instagram_username,
    description,
    onCompleted,
    onError,
}: CustomersUpdateMutationArgs) {
    return useMutation<Partial<Mutation>, MutationUpdateCustomerArgs>(UPDATE_CUSTOMER, {
        onCompleted,
        onError,
        variables: {
            customer_id: customer.customer_id,
            input: {
                name,
                email,
                access_key,
                description,
                instagram_username,
            },
        },
        update: (cache, { data: newData }) => {
            if (!newData?.updateCustomer || !customer) return
            const updatedCustomer: Customer = {
                ...newData.updateCustomer,
            }
            try {
                const data = cache.readQuery<{ listCustomers: Maybe<CustomerConnection> }, QueryListCustomersArgs>({
                    query: LIST_CUSTOMERS,
                    variables: { listing_status: 'DEFAULT' },
                })
                if (!data?.listCustomers) return
                cache.writeQuery<{ listCustomers: Maybe<CustomerConnection> }, QueryListCustomersArgs>({
                    query: LIST_CUSTOMERS,
                    variables: { listing_status: 'DEFAULT' },
                    data: {
                        ...data,
                        listCustomers: {
                            ...data.listCustomers,
                            items: data.listCustomers.items.map(customerItem =>
                                customerItem.customer_id === customer.customer_id ? updatedCustomer : customerItem
                            ),
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useCustomersUpdateMutation`')
            }
        },
    })
}

interface CustomersDeleteMutationArgs extends MutationArgs {
    customer: Customer
}

export function useCustomersDeleteMutation({ customer, onCompleted, onError }: CustomersDeleteMutationArgs) {
    return useMutation<{ deleteCustomer: Customer }, MutationDeleteCustomerArgs>(DELETE_CUSTOMER, {
        onCompleted,
        onError,
        variables: {
            customer_id: customer.customer_id,
        },
        update: (cache, { data: deletedBanner }) => {
            if (!deletedBanner) return
            try {
                const data = cache.readQuery<{ listCustomers: Maybe<CustomerConnection> }, QueryListCustomersArgs>({
                    query: LIST_CUSTOMERS,
                    variables: { listing_status: 'DEFAULT' },
                })
                if (!data?.listCustomers) return
                cache.writeQuery<{ listCustomers: Maybe<CustomerConnection> }, QueryListCustomersArgs>({
                    query: LIST_CUSTOMERS,
                    variables: { listing_status: 'DEFAULT' },
                    data: {
                        ...data,
                        listCustomers: {
                            ...data.listCustomers,
                            items: data.listCustomers.items.filter(customerItem => customerItem.customer_id !== customer.customer_id),
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useCustomersDeleteMutation`')
            }
        },
    })
}

interface CustomersQueryArgs {
    listingStatus: string
}

export function useCustomersQuery({ listingStatus }: CustomersQueryArgs) {
    return useQuery<{ listCustomers: Maybe<CustomerConnection> }, QueryListCustomersArgs>(LIST_CUSTOMERS, {
        variables: { listing_status: listingStatus },
    })
}

interface ShowCustomerQueryArgs {
    customerId: string
}

export function useShowCustomerQuery({ customerId }: ShowCustomerQueryArgs) {
    return useQuery<{ showCustomer: Maybe<Customer> }, QueryShowCustomerArgs>(SHOW_CUSTOMER, {
        variables: { customer_id: customerId },
        skip: !customerId,
    })
}
