import React from 'react'
import Collections from './Collections'
import { useParams, Link } from 'react-router-dom'
import { usePortfolioSectionsSlugQuery } from '../../graphql/usePortfolioSections'
import Error from '../../components/Error'
import { LottieMoon } from '../../components/Lottie'
import { SlugPortfolioSection } from 'vo-components'

export default function PagePortfolioSectionCollections() {
    const { portfolioSlug } = useParams()
    const { loading, error, data, refetch } = usePortfolioSectionsSlugQuery({ slug: portfolioSlug || '' })
    const { portfolio_section } = (data?.showSlug?.value || {}) as SlugPortfolioSection
    if (error) {
        return (
            <div className="section">
                <div className="container">
                    <Error errorTrace={error} retry={refetch} />
                </div>
            </div>
        )
    }
    if (loading) {
        return null
    }
    if (!portfolio_section) {
        return (
            <div className="section">
                <div className="container has-text-centered">
                    <LottieMoon height={320} />
                    <h1 className="is-size-3 title">Portfolio not found</h1>
                    <div className="buttons is-centered">
                        <button onClick={() => refetch()} className="button is-info">
                            Refresh
                        </button>
                        <Link to="/portfolio" className="button">
                            Go Back
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <div className="section">
                <div className="container">
                    <nav className="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <Link to="/portfolio">Portfolio</Link>
                            </li>
                            <li className="is-active">
                                <Link to={`/portfolio/${portfolio_section.slug}`}>{portfolio_section.name}</Link>
                            </li>
                        </ul>
                    </nav>
                    <Collections
                        cacheHandlerKey="PORTFOLIO_SECTION"
                        listingStatus="PORTFOLIO"
                        portfolioSection={portfolio_section}
                        collections={portfolio_section.collections?.items || []}
                        title={portfolio_section.name || ''}
                        baseUrl={`/portfolio/${portfolio_section.slug}`}
                    />
                </div>
            </div>
        </>
    )
}
