import React from 'react'
import { Lottie } from 'vo-components'
import moon from '../assets/lottiefiles/moon.json'
import whale from '../assets/lottiefiles/whale.json'
import banana from '../assets/lottiefiles/banana.json'
import bouncingFruits from '../assets/lottiefiles/bouncing-fruits.json'
import coffee from '../assets/lottiefiles/coffee.json'
import beeLike from '../assets/lottiefiles/bee-like.json'

interface LottieProps {
    height?: string | number
}

export function LottieMoon({ height }: LottieProps) {
    return <Lottie height={height} loop autoplay animationData={moon} />
}

export function LottieWhale({ height }: LottieProps) {
    return <Lottie height={height} loop autoplay animationData={whale} />
}

export function LottieBanana({ height }: LottieProps) {
    return <Lottie height={height} loop autoplay animationData={banana} />
}

export function LottieBouncingFruits({ height }: LottieProps) {
    return <Lottie height={height} loop autoplay animationData={bouncingFruits} />
}

export function LottieCoffee({ height }: LottieProps) {
    return <Lottie height={height} loop autoplay animationData={coffee} />
}

export function LottieBeeLike({ height }: LottieProps) {
    return <Lottie height={height} loop autoplay animationData={beeLike} />
}
