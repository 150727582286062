import React from 'react'
import { AboutMeForm, AboutMeCoverImage, AboutMe } from '../allsite/AboutMe'

const PAGE_KEY = 'ABOUT_ME_HOME'

export default function AboutMeSection() {
    return (
        <AboutMe pageKey={PAGE_KEY}>
            <div className="columns is-vcentered">
                <div className="column is-narrow">
                    <h1 className="is-size-3 title">About me</h1>
                    <h2 className="is-size-6 subtitle">Sweet and short introduction for your home page</h2>
                </div>
            </div>
            <div className="columns">
                <div className="column is-3">
                    <AboutMeCoverImage />
                </div>
                <div className="column">
                    <AboutMeForm />
                </div>
            </div>
        </AboutMe>
    )
}
