import { Mutation, File } from 'vo-components'

export interface MutationArgs {
    onCompleted?: (data: Partial<Mutation>) => void
    onError?: () => void
    shouldRefetch?: boolean
}

export const baseFile: File = {
    __typename: 'File',
    is_ready: false,
    public_url: null,
    contentType: null,
    poster: null,
    customisation: null,
    original_s3_key: null,
}
