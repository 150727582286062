import React, { useState, useMemo } from 'react'
import { stylesheet } from 'typestyle'
import { em } from 'csx'
import { useProfileQuery } from '../../graphql/useProfile'
import Error from '../../components/Error'
import { LottieWhale } from '../../components/Lottie'
import ModalLatestWork from './ModalLatestWork'
import { CollectionThumbnailPreview } from '../allsite/CollectionThumbnail'
import { DropdownMenu, ProfileLatestWorks, LatestWorkCollection } from 'vo-components'
import orderBy from 'lodash.orderby'
import LatestWorksSortingPanel from './LatestWorksSortingPanel'

const styles = stylesheet({
    emptyButtonContainer: {
        marginTop: em(1),
    },
})

const PAGE_KEY = 'LATEST_WORKS'

export default function LatestWorks() {
    const [isSortOpen, setIsSortOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { loading, error, data, refetch } = useProfileQuery({ pageKey: PAGE_KEY })
    const profile = (data?.showProfile?.value || {}) as ProfileLatestWorks
    const latestWorks = useMemo(() => orderBy(profile.items || [], ['sort_key'], ['asc']), [profile]) as LatestWorkCollection[]
    return (
        <>
            <ModalLatestWork latestWorks={latestWorks} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            <LatestWorksSortingPanel isOpen={isSortOpen} onClose={() => setIsSortOpen(false)} items={latestWorks} />
            <div className="columns is-vcentered">
                <div className="column is-narrow">
                    <h1 className="is-size-3 title">Latest works</h1>
                    <h2 className="is-size-6 subtitle">Hand-picked collections to be shown off</h2>
                </div>
                <div className="column">
                    <div className="buttons is-right">
                        <button onClick={() => setIsModalOpen(true)} className="button is-primary">
                            Pick collections
                        </button>
                        <DropdownMenu className="has-background-info" align="right">
                            <button onClick={() => setIsSortOpen(true)} className="button is-white dropdown-item">
                                Re-order latest works
                            </button>
                        </DropdownMenu>
                    </div>
                </div>
            </div>
            {error && <Error errorTrace={error} retry={refetch} />}
            {!loading && !error && latestWorks.length === 0 && (
                <>
                    <LottieWhale height={320} />
                    <p className="has-text-centered">You haven&apos;t picked any latest works!</p>
                    <div className={styles.emptyButtonContainer}>
                        <div className="buttons is-centered">
                            <button onClick={() => setIsModalOpen(true)} className="button is-primary">
                                Pick collections
                            </button>
                        </div>
                    </div>
                </>
            )}
            <div className="columns is-multiline">
                {latestWorks.map(
                    latestWork =>
                        latestWork?.collection && (
                            <div key={latestWork.collection.collection_id} className="column is-4">
                                <CollectionThumbnailPreview collection={latestWork.collection} />
                            </div>
                        )
                )}
            </div>
        </>
    )
}
