import React, { useState, useMemo, useContext } from 'react'
import { classes, stylesheet } from 'typestyle'
import { em, url } from 'csx'
import { LottieWhale } from '../../components/Lottie'
import ModalPortfolioSection from '../allsite/ModalPortfolioSection'
import CollectionThumbnail from '../allsite/CollectionThumbnail'
import { DropdownMenu, getDynamicImageURL, PortfolioSection, Collection, SlugType, Customer } from 'vo-components'
import ModalSlug from '../allsite/ModalSlug'
import ModalCollection from '../allsite/ModalCollection'
import orderBy from 'lodash.orderby'
import CollectionsSortingPanel from './CollectionsSortingPanel'
import { CollectionCacheHandlerKey } from '../../graphql/useCollections'
import { ModalFormMode } from '../../models/ModalModel'
import first from 'lodash.first'
import { PageContext, PageKey } from '../../components/PageContext'
import ModalCustomerSession from '../allsite/ModalCustomerSession'
import ModalCustomer from '../allsite/ModalCustomer'

const styles = stylesheet({
    emptyButtonContainer: {
        marginTop: em(1),
    },
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: em(1),
    },
    titleImage: {
        marginRight: em(1),
    },
})

interface CollectionsProps {
    cacheHandlerKey: CollectionCacheHandlerKey
    portfolioSection?: PortfolioSection
    customer?: Customer
    title: string
    listingStatus: string
    collections: Collection[]
    baseUrl: string
}

const termMap = new Map<PageKey, string>([
    [PageKey.CUSTOMER_SESSION_LIST, 'session'],
    [PageKey.PROJECT_LIST, 'project'],
    [PageKey.PORTFOLIO_COLLECTION_LIST, 'collection'],
])

const shouldEnableSorting = (pageKey: PageKey) => [PageKey.PROJECT_LIST, PageKey.PORTFOLIO_COLLECTION_LIST].includes(pageKey)

export default function Collections({ cacheHandlerKey, portfolioSection, customer, title, listingStatus, collections, baseUrl }: CollectionsProps) {
    const { pageKey } = useContext(PageContext)
    const [isSortOpen, setIsSortOpen] = useState<boolean>(false)
    const [isModalCollectionOpen, setIsModalCollectionOpen] = useState<boolean>(false)
    const [isModalCustomerOpen, setIsModalCustomerOpen] = useState(false)
    const [isModalPortfolioSectionOpen, setIsModalPortfolioSectionOpen] = useState<boolean>(false)
    const [isModalSlugOpen, setIsModalSlugOpen] = useState<boolean>(false)
    const sortedCollections = useMemo(() => orderBy(collections || [], ['sort_key'], ['desc']), [collections])
    const latestSortKey = first(sortedCollections)?.sort_key
    return (
        <>
            {shouldEnableSorting(pageKey) && (
                <CollectionsSortingPanel
                    isOpen={isSortOpen}
                    onClose={() => setIsSortOpen(false)}
                    portfolioSection={portfolioSection}
                    items={sortedCollections}
                    baseUrl={baseUrl}
                />
            )}

            {pageKey === PageKey.PORTFOLIO_COLLECTION_LIST && (
                <>
                    <ModalSlug
                        type={SlugType.PortfolioSection}
                        isOpen={isModalSlugOpen}
                        onClose={() => setIsModalSlugOpen(false)}
                        portfolioSection={portfolioSection}
                        shouldRedirect
                        baseUrl="/portfolio"
                    />
                    <ModalPortfolioSection
                        mode={ModalFormMode.UPDATE}
                        isOpen={isModalPortfolioSectionOpen}
                        portfolioSection={portfolioSection}
                        onClose={() => setIsModalPortfolioSectionOpen(false)}
                    />
                </>
            )}

            {pageKey === PageKey.CUSTOMER_SESSION_LIST && customer && (
                <>
                    <ModalCustomer
                        mode={ModalFormMode.UPDATE}
                        isOpen={isModalCustomerOpen}
                        onClose={() => setIsModalCustomerOpen(false)}
                        customer={customer}
                    />
                    <ModalCustomerSession
                        mode={ModalFormMode.CREATE}
                        isOpen={isModalCollectionOpen}
                        onClose={() => setIsModalCollectionOpen(false)}
                        customer={customer}
                        latestSortKey={latestSortKey || undefined}
                    />
                </>
            )}
            {pageKey !== PageKey.CUSTOMER_SESSION_LIST && (
                <ModalCollection
                    cacheHandlerKey={cacheHandlerKey}
                    mode={ModalFormMode.CREATE}
                    portfolioSection={portfolioSection}
                    isOpen={isModalCollectionOpen}
                    onClose={() => setIsModalCollectionOpen(false)}
                    latestSortKey={latestSortKey || undefined}
                    listingStatus={listingStatus}
                />
            )}

            <div className="columns is-vcentered">
                <div className="column is-narrow">
                    <div className={classes('is-flex', styles.titleContainer)}>
                        {portfolioSection && (
                            <div
                                className={classes('image is-96x96 ', styles.titleImage)}
                                style={{
                                    backgroundPosition: portfolioSection.banner?.customisation?.backgroundPosition ?? 'center center',
                                    backgroundImage: url(
                                        getDynamicImageURL({
                                            src: portfolioSection.banner?.public_url || '',
                                            width: 320,
                                        })
                                    ),
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                }}
                            />
                        )}
                        <div>
                            <h1 className="is-size-1 title">{title}</h1>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="buttons is-right">
                        <button onClick={() => setIsModalCollectionOpen(true)} className="button is-primary">
                            Add a {termMap.get(pageKey)}
                        </button>
                        <DropdownMenu className="has-background-info" align="right">
                            {pageKey === PageKey.PORTFOLIO_COLLECTION_LIST && portfolioSection && (
                                <>
                                    <button onClick={() => setIsModalPortfolioSectionOpen(true)} className="button is-white dropdown-item">
                                        Edit Portfolio Section
                                    </button>
                                    <button onClick={() => setIsModalSlugOpen(true)} className="button is-white dropdown-item">
                                        Change Portfolio Section&quot;s URL
                                    </button>
                                    <hr className="dropdown-divider" />
                                </>
                            )}
                            {pageKey === PageKey.CUSTOMER_SESSION_LIST && customer && (
                                <>
                                    <button onClick={() => setIsModalCustomerOpen(true)} className="button is-white dropdown-item">
                                        Edit Customer Information
                                    </button>
                                </>
                            )}
                            {shouldEnableSorting(pageKey) && (
                                <button onClick={() => setIsSortOpen(true)} className="button is-white dropdown-item">
                                    Re-order {termMap.get(pageKey)}s
                                </button>
                            )}
                        </DropdownMenu>
                    </div>
                </div>
            </div>
            {sortedCollections.length === 0 && (
                <>
                    <LottieWhale height={320} />
                    <p className="has-text-centered">
                        {pageKey === PageKey.PORTFOLIO_COLLECTION_LIST && portfolioSection && (
                            <span>
                                You don&apos;t have any collections for <strong>&ldquo;{portfolioSection?.name}&rdquo;</strong>!
                            </span>
                        )}
                        {pageKey === PageKey.PROJECT_LIST && <span>You don&apos;t have any {title}</span>}
                        {pageKey === PageKey.CUSTOMER_SESSION_ASSET_LIST && customer && (
                            <span>You don&apos;t have any sessions for {customer.name}</span>
                        )}
                    </p>
                    <div className={styles.emptyButtonContainer}>
                        <div className="buttons is-centered">
                            <button onClick={() => setIsModalCollectionOpen(true)} className="button is-primary">
                                Add a {termMap.get(pageKey)}
                            </button>
                        </div>
                    </div>
                </>
            )}
            <div className="columns is-multiline">
                {sortedCollections.map(collection => (
                    <div key={collection.collection_id} className="column is-4">
                        <CollectionThumbnail
                            cacheHandlerKey={cacheHandlerKey}
                            collection={collection}
                            portfolioSection={portfolioSection}
                            customer={customer}
                            baseUrl={baseUrl}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}
