export interface BasicModalProps {
    isOpen: boolean
    onClose: () => void
}

export enum ModalFormMode {
    CREATE,
    UPDATE,
}

export interface ModalFormProps {
    mode: ModalFormMode
}

export interface ModalInnerFormProps {
    write: () => Promise<any>
    loading?: boolean
    error?: any
}
