import React, { useState } from 'react'
import CustomerItem from './CustomerItem'
import ModalCustomer from '../allsite/ModalCustomer'
import { Customer } from 'vo-components'
import { ModalFormMode } from '../../models/ModalModel'
import { LottieWhale } from '../../components/Lottie'
import { stylesheet } from 'typestyle'
import { em } from 'csx'

const styles = stylesheet({
    emptyButtonContainer: {
        marginTop: em(1),
    },
})

interface CustomersProps {
    customers: Customer[]
}

export default function Customers({ customers }: CustomersProps) {
    const [isModalCustomerOpen, setIsModalCustomerOpen] = useState(false)
    return (
        <>
            <ModalCustomer mode={ModalFormMode.CREATE} isOpen={isModalCustomerOpen} onClose={() => setIsModalCustomerOpen(false)} />
            <div className="columns is-vcentered">
                <div className="column is-narrow">
                    <h1 className="is-size-3 title">Clients</h1>
                    <h2 className="is-size-6 subtitle">
                        Registered clients who have been photographed by you.
                        <br />
                        They can login at <a href="https://veronicaonggoro.com/client-portal">https://veronicaonggoro.com/client-portal</a>
                    </h2>
                </div>
                <div className="column">
                    <div className="buttons is-right">
                        <button onClick={() => setIsModalCustomerOpen(true)} className="button is-primary">
                            Create a new client
                        </button>
                    </div>
                </div>
            </div>
            {customers.length === 0 && (
                <>
                    <LottieWhale height={320} />
                    <p className="has-text-centered">You don&apos;t have any registered clients!</p>
                    <div className={styles.emptyButtonContainer}>
                        <div className="buttons is-centered">
                            <button onClick={() => setIsModalCustomerOpen(true)} className="button is-primary">
                                Add a new client
                            </button>
                        </div>
                    </div>
                </>
            )}
            {customers.map(customer => (
                <CustomerItem key={customer.customer_id} customer={customer} />
            ))}
        </>
    )
}
