import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Modal, Theme, SlugType, PortfolioSection, Collection, Mutation, Customer } from 'vo-components'
import { classes, stylesheet } from 'typestyle'
import { em, viewWidth, percent } from 'csx'
import { useDebounce } from 'react-use'
import slugify from 'slugify'
import { useCollectionsUpdateSlugMutation, CollectionCacheHandlerKey } from '../../graphql/useCollections'
import { usePortfolioSectionsUpdateSlugMutation } from '../../graphql/usePortfolioSections'
import { useSlugExistenceCheck } from '../../graphql/useSlugExistenceCheck'
import { useHistory } from 'react-router-dom'
import { BasicModalProps, ModalInnerFormProps } from '../../models/ModalModel'
import { useForm, useFormContext, FormContext } from 'react-hook-form'

interface SlugModalContext extends BasicModalProps {
    onCompleted: (data: Partial<Mutation>) => void
    baseUrl: string
    slugifiedSlug: string
    slugAvailabilityLoading: boolean
    slugAvailability: string
}

const SlugModalContext = React.createContext<SlugModalContext>({} as SlugModalContext)

interface SlugFormModel {
    slug: string
}

const styles = stylesheet({
    modal: {
        width: viewWidth(50),
    },
    container: {
        backgroundColor: Theme.colors.white,
        height: percent(100),
        padding: `${em(1)} ${em(1)}`,
    },
    formContainer: {
        flex: 1,
    },
    submitContainer: {
        marginTop: em(2),
    },
})

function SlugForm({ write, loading, error }: ModalInnerFormProps) {
    const { isOpen, onClose, baseUrl, slugifiedSlug, slugAvailability, slugAvailabilityLoading } = useContext(SlugModalContext)
    const { register, errors, handleSubmit, watch } = useFormContext<SlugFormModel>()
    const { slug } = watch()
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
                <div className={classes(styles.container)}>
                    <form
                        onSubmit={handleSubmit(() => {
                            write().catch(console.error)
                        })}
                    >
                        <div className={styles.formContainer}>
                            <h3 className="is-size-3 title">URL Path</h3>
                            <div className="notification">
                                https://veronicaonggoro.com{baseUrl}/<strong>{slugifiedSlug}</strong>
                            </div>
                            <div className="field">
                                <div className={classes('control', 'has-icons-right', slugAvailabilityLoading && 'is-loading')}>
                                    <input
                                        name="slug"
                                        ref={register({ required: 'Please fill your URL.' })}
                                        className={classes('input', errors.slug && 'is-danger', slugAvailability === 'AVAILABLE' && 'is-success')}
                                        type="text"
                                        placeholder="Your display slug..."
                                    />
                                    {errors.slug && (
                                        <span className="icon is-small is-right">
                                            <i className="fas fa-exclamation-triangle" />
                                        </span>
                                    )}
                                    {slugAvailability === 'AVAILABLE' && (
                                        <span className="icon is-small is-right">
                                            <i className="fas fa-check" />
                                        </span>
                                    )}
                                </div>
                                {errors.slug && <p className="help is-danger">{errors.slug.message}</p>}
                            </div>
                            {slugifiedSlug !== slug && (
                                <div className="notification is-warning">
                                    <span className="icon">
                                        <i className="fas fa-exclamation-triangle" />
                                    </span>
                                    <span>
                                        Warning! you&apos;ve inserted a non URL friendly slug. If you continue, we&apos;ll change your slug into:{' '}
                                        <strong>{slugifiedSlug}</strong>
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className={styles.submitContainer}>
                            <div className="columns">
                                <div className="column is-narrow">{error && <p className="has-text-danger">Whoops! Something went wrong</p>}</div>
                                <div className="column">
                                    <div className="field is-grouped is-grouped-right">
                                        <p className="control">
                                            <button type="button" onClick={onClose} className="button is-light">
                                                Cancel
                                            </button>
                                        </p>
                                        <p className="control">
                                            <button type="submit" className={classes('button is-primary', loading && 'is-loading')}>
                                                Submit
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    )
}

interface CollectionSlugUpdateProps {
    cacheHandlerKey: CollectionCacheHandlerKey
    portfolioSection?: PortfolioSection
    customer?: Customer
    collection: Collection
}

function ModalSlugCollection({ cacheHandlerKey, portfolioSection, customer, collection }: CollectionSlugUpdateProps) {
    const { onCompleted } = useContext(SlugModalContext)
    const { watch } = useFormContext<SlugFormModel>()
    const { slug } = watch({ nest: true })
    const [update, { error, loading }] = useCollectionsUpdateSlugMutation({
        cacheHandlerKey,
        portfolioSection,
        customer,
        collection,
        slug,
        onCompleted,
    })
    return <SlugForm write={update} loading={loading} error={error} />
}

interface PortfolioSectionSlugUpdateProps {
    portfolioSection: PortfolioSection
}

function ModalSlugPortfolioSection({ portfolioSection }: PortfolioSectionSlugUpdateProps) {
    const { onCompleted } = useContext(SlugModalContext)
    const { watch } = useFormContext<SlugFormModel>()
    const { slug } = watch({ nest: true })
    const [update, { error, loading }] = usePortfolioSectionsUpdateSlugMutation({
        portfolioSection,
        slug,
        onCompleted,
    })
    return <SlugForm write={update} loading={loading} error={error} />
}

interface ModalSlugProps extends BasicModalProps {
    type: SlugType
    cacheHandlerKey?: CollectionCacheHandlerKey
    portfolioSection?: PortfolioSection
    customer?: Customer
    collection?: Collection
    baseUrl: string
    shouldRedirect?: boolean
}

export default function ModalSlug({
    type,
    isOpen,
    onClose,
    cacheHandlerKey,
    portfolioSection,
    customer,
    collection,
    baseUrl,
    shouldRedirect,
}: ModalSlugProps) {
    const history = useHistory()
    const oldSlug = useMemo<string | undefined>(() => {
        if (type === SlugType.Collection) return collection?.slug || undefined
        if (type === SlugType.PortfolioSection) return portfolioSection?.slug || undefined
        return
    }, [type, collection, portfolioSection])

    const formMethods = useForm<SlugFormModel>({
        submitFocusError: true,
        validateCriteriaMode: 'all',
        defaultValues: {
            slug: oldSlug || '',
        },
    })
    const { setError, clearError, getValues, reset, watch } = formMethods

    const onCompleted = async (data: Partial<Mutation>) => {
        const slugData = data.updateSlug
        onClose()
        const form = getValues()
        reset(form)
        if (shouldRedirect && slugData?.slug) {
            history.push(`${baseUrl}/${slugData.slug}`)
        }
    }

    const { slug } = watch()

    const [debouncedSlug, setDebouncedSlug] = useState(slug)
    useDebounce(
        () => {
            setDebouncedSlug(slug)
        },
        500,
        [slug]
    )
    const slugifiedSlug = useMemo(
        () =>
            slugify(slug, {
                lower: true,
            }),
        [slug]
    )
    const { loading, data, error } = useSlugExistenceCheck({
        type,
        oldSlug,
        slug: debouncedSlug,
    })
    const slugAvailability = useMemo(() => {
        if (debouncedSlug !== oldSlug && data) {
            return !data.showSlug ? 'AVAILABLE' : 'NOT_AVAILABLE'
        }
        return 'UNSET'
    }, [debouncedSlug, oldSlug, data])

    useEffect(() => {
        if (error) {
            setError('slug', 'INTERNAL_ERROR', "Whoops! We cannot check this URL's availability right now.")
        } else if (slugAvailability === 'NOT_AVAILABLE') {
            setError('slug', 'NOT_AVAILABLE', 'This URL is not available any more.')
        } else {
            clearError(['slug'])
        }
    }, [slugAvailability, error, setError, clearError])
    return (
        <SlugModalContext.Provider
            value={{
                isOpen,
                onClose,
                onCompleted,
                baseUrl,
                slugifiedSlug,
                slugAvailabilityLoading: loading,
                slugAvailability,
            }}
        >
            <FormContext {...formMethods}>
                {type === SlugType.Collection && cacheHandlerKey && collection && (
                    <ModalSlugCollection
                        cacheHandlerKey={cacheHandlerKey}
                        collection={collection}
                        portfolioSection={portfolioSection}
                        customer={customer}
                    />
                )}
                {type === SlugType.PortfolioSection && portfolioSection && <ModalSlugPortfolioSection portfolioSection={portfolioSection} />}
            </FormContext>
        </SlugModalContext.Provider>
    )
}
