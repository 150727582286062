import React, { useMemo } from 'react'
import { IndividualReportProps } from './Reports'
import { parseReportResponse } from '../../util/googleAnalyticsReport'

export default function ReportBrowser({ report }: IndividualReportProps) {
    const parsedReport = useMemo(() => parseReportResponse(report), [report])
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-header-title is-size-5">What browser are your users using?</h3>
            </div>
            <table className="card-content table is-fullwidth is-striped is-hoverable">
                <thead>
                    <tr>
                        <th className="has-text-left">Browser</th>
                        <th className="has-text-centered">Users</th>
                    </tr>
                </thead>
                <tbody>
                    {parsedReport.map(row => (
                        <tr key={row.dimensions['ga:browser']}>
                            <td className="has-text-left">{row.dimensions['ga:browser']}</td>
                            <td className="has-text-centered">{row.values['ga:users']}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
