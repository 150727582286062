import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Authenticator from './Authenticator'
import AppRouter from './AppRouter'
import { Modal } from 'vo-components'
import AppSyncProvider from './AppSyncProvider'

Modal.setAppElement()

export default function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <AppSyncProvider>
                    <Authenticator>
                        <AppRouter />
                    </Authenticator>
                </AppSyncProvider>
            </BrowserRouter>
        </div>
    )
}
