import { useQuery } from '@apollo/client'
import { CHECK_SLUG_EXISTENCE, Maybe, Slug, QueryShowSlugArgs, SlugType } from 'vo-components'

interface SlugExistenceQueryArgs {
    type: SlugType
    oldSlug?: string
    slug: string
}

export function useSlugExistenceCheck({ type, oldSlug, slug }: SlugExistenceQueryArgs) {
    return useQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs>(CHECK_SLUG_EXISTENCE, {
        variables: {
            type,
            slug,
        },
        skip: slug.trim().length <= 0 || oldSlug === slug,
    })
}
