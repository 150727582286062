import React, { useState, useMemo } from 'react'
import { stylesheet } from 'typestyle'
import { em } from 'csx'
import { LottieWhale } from '../../components/Lottie'
import Error from '../../components/Error'
import ModalPortfolioSection from '../allsite/ModalPortfolioSection'
import PortfolioSectionItem from './PortfolioSectionItem'
import last from 'lodash.last'
import orderBy from 'lodash.orderby'
import { Theme, DropdownMenu } from 'vo-components'
import { usePortfolioSectionsQuery } from '../../graphql/usePortfolioSections'
import PortfolioSectionsSortingPanel from './PortfolioSectionsSortingPanel'
import { ModalFormMode } from '../../models/ModalModel'

const styles = stylesheet({
    emptyButtonContainer: {
        marginTop: em(1),
    },
    dragPlaceholder: {
        position: 'relative',
        borderColor: Theme.colors.info,
        borderRadius: 5,
        borderWidth: 4,
        borderStyle: 'solid',
        backgroundColor: Theme.colors.infoFade,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        minHeight: 200,
    },
})

export default function PortfolioSections() {
    const [isSortOpen, setIsSortOpen] = useState(false)
    const [isModalPortfolioSectionOpen, setIsModalPortfolioSectionOpen] = useState(false)
    const { loading, error, data, refetch } = usePortfolioSectionsQuery({})
    const portfolioSections = useMemo(() => orderBy(data?.listPortfolioSections?.items || [], ['sort_key'], ['asc']), [data])
    const latestSortKey = last(portfolioSections)?.sort_key
    return (
        <>
            <PortfolioSectionsSortingPanel isOpen={isSortOpen} onClose={() => setIsSortOpen(false)} items={portfolioSections} />
            <ModalPortfolioSection
                mode={ModalFormMode.CREATE}
                isOpen={isModalPortfolioSectionOpen}
                onClose={() => setIsModalPortfolioSectionOpen(false)}
                latestSortKey={latestSortKey || undefined}
            />

            <div className="columns is-vcentered">
                <div className="column is-narrow">
                    <h1 className="is-size-3 title">Portfolio Sections</h1>
                    <h2 className="is-size-6 subtitle">
                        These sections will group your collections into common themes (e.g. Wedding, Nature, Documentary, etc)
                    </h2>
                </div>
                <div className="column">
                    <div className="buttons is-right">
                        <button onClick={() => setIsModalPortfolioSectionOpen(true)} className="button is-primary">
                            Create a section
                        </button>
                        <DropdownMenu className="has-background-info" align="right">
                            <button onClick={() => setIsSortOpen(true)} className="button is-white dropdown-item">
                                Re-order sections
                            </button>
                        </DropdownMenu>
                    </div>
                </div>
            </div>
            {error && <Error errorTrace={error} retry={refetch} />}
            {portfolioSections.length === 0 && !loading && (
                <>
                    <LottieWhale height={320} />
                    <p className="has-text-centered">You don&apos;t have any portfolio sections!</p>
                    <div className={styles.emptyButtonContainer}>
                        <div className="buttons is-centered">
                            <button onClick={() => setIsModalPortfolioSectionOpen(true)} className="button is-primary">
                                Create a section
                            </button>
                        </div>
                    </div>
                </>
            )}
            <div className="columns is-multiline">
                {portfolioSections.map(portfolioSection => (
                    <div key={portfolioSection.portfolio_section_key} className="column is-4">
                        <PortfolioSectionItem portfolioSection={portfolioSection} />
                    </div>
                ))}
            </div>
        </>
    )
}
