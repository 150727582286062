import React, { useState, useCallback, useMemo, useContext, useEffect } from 'react'
import { Modal, VideoPlayer, getMedia, MediaType, Theme, Mutation, Collection, PortfolioSection } from 'vo-components'
import { classes, stylesheet } from 'typestyle'
import { em, viewWidth, viewHeight, percent, url } from 'csx'
import { useDropzone } from 'react-dropzone'
import head from 'lodash.head'
import InputTag from '../../components/InputTag'
import { useCollectionsUpdateMutation, useCollectionsCreateMutation, CollectionCacheHandlerKey } from '../../graphql/useCollections'
import { FileUpload } from '../../models/FileModel'
import { BasicModalProps, ModalInnerFormProps, ModalFormProps, ModalFormMode } from '../../models/ModalModel'
import { useFormContext, Controller, FormContext, useForm } from 'react-hook-form'
import { useUploader } from '../../components/useUploader'
import DraftEditor from '../../components/DraftEditor'

interface CollectionModalContext extends BasicModalProps {
    file: FileUpload
    setFile: (file?: File) => void
    uploadProgress?: number
    onCompleted: (data: Partial<Mutation>) => void
    cacheHandlerKey: CollectionCacheHandlerKey
    portfolioSection?: PortfolioSection
    collection?: Collection
    listingStatus: string
}

const CollectionModalContext = React.createContext<CollectionModalContext>({} as CollectionModalContext)

interface CollectionFormModel {
    title: string
    description: string
    tags: string[]
}

const coverImageStyles = stylesheet({
    imageEmptyContainer: {
        borderWidth: 1,
        borderRadius: 5,
        borderStyle: 'dashed',
        borderColor: Theme.colors.greyLight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: em(2),
        paddingBottom: em(2),
        height: percent(100),
    },
    selectFileButton: {
        marginTop: em(2),
    },
    imageContainer: {
        alignSelf: 'center',
        width: percent(100),
        height: percent(100),
    },
    image: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: percent(100),
        height: percent(100),
        overflow: 'hidden',
    },
    imageOverlay: {
        backgroundColor: Theme.colors.transparent,
        opacity: 0,
        transition: 'opacity 300ms ease-out',
        $nest: {
            '&:hover': {
                opacity: 1,
            },
        },
    },
    actionButtons: {
        position: 'absolute',
        top: 0,
        right: 0,
        paddingTop: em(0.5),
        paddingRight: em(0.5),
    },
})

function CoverImage() {
    const {
        file: { previewUrl, file },
        setFile,
        collection,
    } = useContext(CollectionModalContext)
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            // Do something with the files
            const acceptedFile = head(acceptedFiles)
            if (acceptedFile) {
                setFile(acceptedFile)
            }
        },
        [setFile]
    )
    const fileType = useMemo(() => getMedia(file?.type || collection?.banner?.contentType || ''), [file, collection])
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        accept: ['image/*', 'video/*'],
        noClick: fileType === MediaType.VIDEO || (!file && !previewUrl),
    })

    return (
        <>
            <div className={coverImageStyles.imageContainer} {...getRootProps()}>
                <input {...getInputProps()} />
                {!file && !previewUrl && (
                    <div
                        className={coverImageStyles.imageEmptyContainer}
                        style={{
                            backgroundColor: isDragActive ? Theme.colors.whiteBis : 'transparent',
                        }}
                    >
                        <p className="is-size-5 has-text-centered title">Drop an image for this section&apos;s cover picture</p>
                        <button onClick={() => open()} className={classes('button is-info', coverImageStyles.selectFileButton)}>
                            Select a file
                        </button>
                    </div>
                )}
                {(file || previewUrl) && (
                    <figure className="image is-16by9">
                        {previewUrl && (
                            <div className="has-ratio is-clipped">
                                {fileType === MediaType.IMAGE && (
                                    <div
                                        style={{
                                            backgroundImage: url(previewUrl),
                                        }}
                                        className={coverImageStyles.image}
                                    />
                                )}
                                {fileType === MediaType.VIDEO && (
                                    <VideoPlayer url={previewUrl} poster={(!file && collection?.banner?.poster) || undefined} />
                                )}
                            </div>
                        )}
                        {file && (
                            <div
                                className="is-overlay"
                                style={{
                                    backgroundColor: isDragActive ? Theme.colors.overlayDark : 'transparent',
                                }}
                            >
                                <div className={classes('buttons', coverImageStyles.actionButtons)}>
                                    <button
                                        onClick={e => {
                                            e.stopPropagation()
                                            setFile()
                                        }}
                                        className="button is-small is-danger"
                                    >
                                        <span className="icon is-small">
                                            <i className="fas fa-trash" />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        )}
                    </figure>
                )}
            </div>
            {(file || previewUrl) && (
                <div className="has-text-centered">
                    <button onClick={() => open()} className={classes('button is-info', coverImageStyles.selectFileButton)}>
                        Select another file
                    </button>
                </div>
            )}
        </>
    )
}

const styles = stylesheet({
    modal: {
        minWidth: viewWidth(80),
    },
    container: {
        minHeight: viewHeight(60),
        padding: em(2),
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    formContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    formControls: {
        marginTop: em(0.5),
    },
    progressBar: {
        minWidth: 200,
        width: viewWidth(20),
    },
    submitInnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
})

function CollectionForm({ write, loading, error }: ModalInnerFormProps) {
    const { isOpen, onClose, uploadProgress } = useContext(CollectionModalContext)
    const { register, control, handleSubmit, errors } = useFormContext<CollectionFormModel>()
    return (
        <Modal isOpen={isOpen} onClose={() => onClose()} className={styles.modal}>
            <div className={classes('columns', 'is-marginless', styles.container)}>
                <div className={classes('column is-4', styles.innerContainer)}>
                    <CoverImage />
                </div>
                <div className={classes('column', styles.innerContainer)}>
                    <form
                        onSubmit={handleSubmit(() => {
                            write().catch(console.error)
                        })}
                        className={classes(styles.formContainer)}
                    >
                        <div>
                            <div className="field">
                                <label className="label">Title</label>
                                <div className="control">
                                    <input
                                        name="title"
                                        ref={register({ required: 'Please provide a title for your collection' })}
                                        className={classes('input', errors.title && 'is-danger')}
                                        type="text"
                                        placeholder="Provide a title for this collection."
                                    />
                                </div>
                                {errors.title && <p className="help is-danger">{errors.title.message}</p>}
                            </div>
                            <div className="field">
                                <label className="label">Description (optional)</label>
                                <div className="control">
                                    <Controller as={DraftEditor} control={control} name="description" />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Tags (optional)</label>
                                <div className="control">
                                    <Controller as={InputTag} control={control} name="tags" valueName="tags" />
                                </div>
                            </div>
                        </div>

                        <div className={styles.formControls}>
                            <div className="columns">
                                <div className={classes('column is-narrow', styles.submitInnerContainer)}>
                                    {error && <p className="has-text-danger">Whoops! Something went wrong</p>}
                                    {uploadProgress && (
                                        <>
                                            <p>
                                                uploading ({uploadProgress}
                                                %)...
                                            </p>
                                            <progress className={classes('progress is-primary', styles.progressBar)} value={uploadProgress} max="100">
                                                {uploadProgress}%
                                            </progress>
                                        </>
                                    )}
                                </div>
                                <div className="column">
                                    <div className="field is-grouped is-grouped-right">
                                        <p className="control">
                                            <button type="button" onClick={() => onClose()} className="button is-light">
                                                Cancel
                                            </button>
                                        </p>
                                        <p className="control">
                                            <button
                                                type="submit"
                                                className={classes('button is-primary', (loading || !!uploadProgress) && 'is-loading')}
                                            >
                                                Submit
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

interface CollectionUpdateProps {
    collection: Collection
}

function ModalCollectionUpdate({ collection }: CollectionUpdateProps) {
    const { onCompleted, file, cacheHandlerKey, portfolioSection, listingStatus } = useContext(CollectionModalContext)
    const { watch } = useFormContext<CollectionFormModel>()
    const { title, description, tags } = watch({ nest: true })
    const [update, { loading, error }] = useCollectionsUpdateMutation({
        cacheHandlerKey,
        listing_status: listingStatus,
        portfolioSection,
        collection,
        title,
        description,
        tags,
        file,
        onCompleted,
    })
    return <CollectionForm write={update} loading={loading} error={error} />
}

interface CollectionCreateProps {
    latestSortKey?: string
}

function ModalCollectionCreate({ latestSortKey }: CollectionCreateProps) {
    const { onCompleted, file, cacheHandlerKey, portfolioSection, listingStatus } = useContext(CollectionModalContext)
    const { watch } = useFormContext<CollectionFormModel>()
    const { title, description, tags } = watch({ nest: true })
    const [create, { loading, error }] = useCollectionsCreateMutation({
        cacheHandlerKey,
        portfolioSection,
        title,
        description,
        tags,
        file,
        onCompleted,
        latestSortKey,
        listing_status: listingStatus,
    })
    return <CollectionForm write={create} loading={loading} error={error} />
}

interface ModalCollectionProps extends BasicModalProps, ModalFormProps {
    cacheHandlerKey: CollectionCacheHandlerKey
    portfolioSection?: PortfolioSection
    collection?: Collection
    latestSortKey?: string
    listingStatus: string
}

export default function ModalCollection({
    isOpen,
    onClose,
    mode,
    cacheHandlerKey,
    portfolioSection,
    collection,
    latestSortKey,
    listingStatus,
}: ModalCollectionProps) {
    const [fileToBeUploaded, setFileToBeUploaded] = useState<File | undefined>(undefined)
    const previewUrl = useMemo<string | undefined>(
        () => (fileToBeUploaded && URL.createObjectURL(fileToBeUploaded)) || collection?.banner?.public_url || undefined,
        [fileToBeUploaded, collection]
    )
    const formMethods = useForm<CollectionFormModel>({
        submitFocusError: true,
        validateCriteriaMode: 'all',
        defaultValues: {
            title: collection?.title || '',
            description: collection?.description || '',
            tags: (collection?.tags || []) as string[],
        },
    })
    const finish = () => {
        const form = formMethods.getValues({ nest: true })
        formMethods.reset(mode === ModalFormMode.UPDATE ? form : undefined)
        onClose()
    }
    const { upload, uploadProgress, resetProgress } = useUploader({
        onCompleted: () => {
            finish()
        },
    })
    useEffect(() => {
        if (isOpen) {
            resetProgress()
            setFileToBeUploaded(undefined)
        }
    }, [isOpen, resetProgress])

    const onCompleted = async (data: Partial<Mutation>) => {
        const collection = data.createCollection || data.updateCollection
        if (fileToBeUploaded && collection) {
            upload({ id: collection.collection_id, handler: 'Collection', file: fileToBeUploaded, access_level: 'public' })
        } else {
            finish()
        }
    }
    const file: FileUpload = {
        file: fileToBeUploaded,
        previewUrl,
    }
    return (
        <CollectionModalContext.Provider
            value={{
                isOpen,
                onClose,
                file,
                setFile: setFileToBeUploaded,
                uploadProgress,
                onCompleted,
                cacheHandlerKey,
                portfolioSection,
                collection,
                listingStatus,
            }}
        >
            <FormContext {...formMethods}>
                {mode === ModalFormMode.CREATE && <ModalCollectionCreate latestSortKey={latestSortKey} />}
                {mode === ModalFormMode.UPDATE && collection && <ModalCollectionUpdate collection={collection} />}
            </FormContext>
        </CollectionModalContext.Provider>
    )
}
