import React from 'react'
import Banner from './Banner'
import LoginForm from './LoginForm'

export default function Login() {
    return (
        <div className="columns is-marginless login-page">
            <div className="column is-paddingless is-7">
                <LoginForm />
            </div>
            <div className="column is-paddingless is-hidden-touch">
                <Banner />
            </div>
        </div>
    )
}
