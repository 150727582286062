import React, { useMemo } from 'react'
import { useLatestGoogleAnalyticsReport } from '../../graphql/useGoogleAnalyticsReport'
import { GaReportResponse } from 'vo-components'
import ReportUsers from './ReportUsers'
import ReportPageVisit from './ReportPageVisit'
import ReportSiteReferrer from './ReportSiteReferrer'
import ReportBrowser from './ReportBrowser'
import { ReportKey, findReportResponseByKey } from '../../util/googleAnalyticsReport'
import { stylesheet } from 'typestyle'
import { em } from 'csx'
import Error from '../../components/Error'
import { LottieWhale } from '../../components/Lottie'

const styles = stylesheet({
    headerContainer: {
        marginBottom: em(2),
    },
})

const REPORT_SITE_KEY = 'veronicaonggoro.com'

export interface IndividualReportProps {
    report: GaReportResponse
}

export default function Reports() {
    const { data, loading, error, refetch } = useLatestGoogleAnalyticsReport({ site: REPORT_SITE_KEY })
    const reports = data?.showLatestGoogleAnalyticsReport?.reports || []
    const userReport = useMemo(() => findReportResponseByKey(reports, ReportKey.USERS_REPORT), [reports])
    const pageVisitReport = useMemo(() => findReportResponseByKey(reports, ReportKey.PAGE_VISITS_REPORT), [reports])
    const siteReferrerReport = useMemo(() => findReportResponseByKey(reports, ReportKey.SITE_REFERRER_REPORT), [reports])
    const browserReport = useMemo(() => findReportResponseByKey(reports, ReportKey.BROWSER_REPORT), [reports])

    return (
        <>
            <div className={styles.headerContainer}>
                <h1 className="is-size-3 title">Website Analytics (last 7 days)</h1>
                <h2 className="is-size-6 subtitle">
                    To view a complete suite of analytics, visit&nbsp;
                    <a href="https://analytics.google.com/analytics/web/#/report-home/a142872440w228750447p215685037">https://analytics.google.com</a>
                </h2>
            </div>
            {error && <Error errorTrace={error} retry={refetch} />}
            {reports.length === 0 && !loading && (
                <>
                    <LottieWhale height={320} />
                    <p className="has-text-centered">You don&apos;t have any reports yet!</p>
                    <p className="has-text-centered">Please wait 24 hours for the report to be generated.</p>
                </>
            )}
            <div className="columns is-multiline">
                {userReport && (
                    <div className=" column is-8">
                        <ReportUsers report={userReport} />
                    </div>
                )}
                {browserReport && (
                    <div className="column is-4">
                        <ReportBrowser report={browserReport} />
                    </div>
                )}
                {siteReferrerReport && (
                    <div className="column is-8">
                        <ReportSiteReferrer report={siteReferrerReport} />
                    </div>
                )}
                {pageVisitReport && (
                    <div className="column is-4">
                        <ReportPageVisit report={pageVisitReport} />
                    </div>
                )}
            </div>
        </>
    )
}
