import React, { useContext } from 'react'
import { Modal, Mutation, Customer } from 'vo-components'
import { classes, stylesheet } from 'typestyle'
import { em, viewWidth, percent } from 'csx'
import { useCustomersUpdateMutation, useCustomersCreateMutation } from '../../graphql/useCustomers'
import { BasicModalProps, ModalInnerFormProps, ModalFormProps, ModalFormMode } from '../../models/ModalModel'
import { useFormContext, useForm, FormContext } from 'react-hook-form'

interface CustomerModalContext extends BasicModalProps {
    mode: ModalFormMode
    onCompleted: (data: Partial<Mutation>) => void
}

const CustomerModalContext = React.createContext<CustomerModalContext>({} as CustomerModalContext)

interface CustomerFormModel {
    name: string
    instagramUsername: string
    email: string
    accessKey: string
    description: string
}

const styles = stylesheet({
    modal: {
        minWidth: viewWidth(70),
    },
    container: {
        height: percent(100),
        padding: em(2),
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    formContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    formControls: {
        marginTop: em(0.5),
    },
    submitInnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
})

function CustomerForm({ write, loading, error }: ModalInnerFormProps) {
    const { isOpen, onClose, mode } = useContext(CustomerModalContext)
    const { register, errors, handleSubmit } = useFormContext<CustomerFormModel>()
    return (
        <Modal isOpen={isOpen} onClose={() => onClose()} className={styles.modal}>
            <div className={classes('columns', 'is-marginless', styles.container)}>
                <div className={classes('column', styles.innerContainer)}>
                    <form
                        onSubmit={handleSubmit(() => {
                            write().catch(console.error)
                        })}
                        className={classes(styles.formContainer)}
                    >
                        <div>
                            <h4 className="is-size-4 title">Client information</h4>
                            <p className="is-size-6 subtitle">Your client&apos;s information</p>
                            <div className="columns is-multiline">
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Name</label>
                                        <div className="control">
                                            <input
                                                name="name"
                                                ref={register({ required: "Please enter your client's name" })}
                                                className={classes('input', errors.name && 'is-danger')}
                                                type="text"
                                                placeholder="Your client's name"
                                            />
                                        </div>
                                        {errors.name && <p className="help is-danger">{errors.name.message}</p>}
                                    </div>
                                </div>
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Instagram Username</label>
                                        <div className="control">
                                            <input
                                                name="instagramUsername"
                                                ref={register}
                                                className="input"
                                                type="text"
                                                placeholder="Your client's instagram username (without @). This is optional."
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Email (login credentials)</label>
                                        <div className="control">
                                            <input
                                                name="email"
                                                ref={register({
                                                    required: {
                                                        value: mode !== ModalFormMode.UPDATE,
                                                        message: "Please enter your client's email address",
                                                    },
                                                })}
                                                className={classes('input', errors.email && 'is-danger')}
                                                type="email"
                                                placeholder="Your client's email (used for their login credentials)"
                                                disabled={mode === ModalFormMode.UPDATE}
                                            />
                                        </div>
                                        {errors.email && <p className="help is-danger">{errors.email.message}</p>}
                                    </div>
                                </div>
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Access Key (login credentials)</label>
                                        <div className="control">
                                            <input
                                                name="accessKey"
                                                ref={register({
                                                    required: "Please enter your client's accessk key",
                                                    minLength: { value: 6, message: 'Access key must be 6 characters or more' },
                                                })}
                                                className={classes('input', errors.accessKey && 'is-danger')}
                                                type="text"
                                                placeholder="Your client's access key (used for their login credentials)"
                                            />
                                        </div>
                                        {errors.accessKey && <p className="help is-danger">{errors.accessKey.message}</p>}
                                    </div>
                                </div>
                                <div className="column is-full">
                                    <div className="field">
                                        <label className="label">Description</label>
                                        <div className="control">
                                            <textarea
                                                name="description"
                                                ref={register}
                                                className="textarea"
                                                placeholder="Optional description about your client"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.formControls}>
                            <div className="columns">
                                <div className={classes('column is-narrow', styles.submitInnerContainer)}>
                                    {error && <p className="has-text-danger">Whoops! Something went wrong</p>}
                                </div>
                                <div className="column">
                                    <div className="field is-grouped is-grouped-right">
                                        <p className="control">
                                            <button type="button" onClick={() => onClose()} className="button is-light">
                                                Cancel
                                            </button>
                                        </p>
                                        <p className="control">
                                            <button type="submit" className={classes('button is-primary', loading && 'is-loading')}>
                                                Submit
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

interface CustomerUpdateProps {
    customer: Customer
}

function ModalCustomerUpdate({ customer }: CustomerUpdateProps) {
    const { onCompleted } = useContext(CustomerModalContext)
    const { watch } = useFormContext<CustomerFormModel>()
    const { name, instagramUsername, email, accessKey, description } = watch({ nest: true })
    const [update, { loading, error }] = useCustomersUpdateMutation({
        customer,
        name,
        instagram_username: instagramUsername,
        email,
        access_key: accessKey,
        description,
        onCompleted,
    })
    return <CustomerForm write={update} loading={loading} error={error} />
}

function ModalCustomerCreate() {
    const { onCompleted } = useContext(CustomerModalContext)
    const { watch } = useFormContext<CustomerFormModel>()
    const { name, instagramUsername, email, accessKey, description } = watch({ nest: true })
    const [create, { loading, error }] = useCustomersCreateMutation({
        name,
        instagram_username: instagramUsername,
        email,
        access_key: accessKey,
        description,
        onCompleted,
    })
    return <CustomerForm write={create} loading={loading} error={error} />
}

interface ModalCustomerProps extends BasicModalProps, ModalFormProps {
    customer?: Customer
}

export default function ModalCustomer({ mode, isOpen, onClose, customer }: ModalCustomerProps) {
    const formMethods = useForm<CustomerFormModel>({
        submitFocusError: true,
        validateCriteriaMode: 'all',
        defaultValues: {
            name: customer?.name || '',
            instagramUsername: customer?.instagram_username || '',
            email: customer?.email || '',
            accessKey: customer?.access_key || '',
            description: customer?.description || '',
        },
    })
    const onCompleted = async () => {
        const form = formMethods.getValues()
        formMethods.reset(mode === ModalFormMode.UPDATE ? form : undefined)
        onClose()
    }

    return (
        <CustomerModalContext.Provider
            value={{
                mode,
                isOpen,
                onClose,
                onCompleted,
            }}
        >
            <FormContext {...formMethods}>
                {mode === ModalFormMode.CREATE && <ModalCustomerCreate />}
                {mode === ModalFormMode.UPDATE && customer && <ModalCustomerUpdate customer={customer} />}
            </FormContext>
        </CustomerModalContext.Provider>
    )
}
