import { useQuery } from '@apollo/client'
import { MessageConnection, Maybe, QueryListCollectionMessagesArgs, LIST_COLLECTION_MESSAGES } from 'vo-components'

interface CollectionMessagesQueryArgs {
    collection_id: string
}

export function useCollectionMessagesQuery({ collection_id }: CollectionMessagesQueryArgs) {
    return useQuery<{ listCollectionMessages: Maybe<MessageConnection> }, QueryListCollectionMessagesArgs>(LIST_COLLECTION_MESSAGES, {
        variables: {
            collection_id,
        },
    })
}
