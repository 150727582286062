import React, { useState, useCallback, useMemo } from 'react'
import { stylesheet } from 'typestyle'
import { viewHeight, em } from 'csx'
import { useDropzone } from 'react-dropzone'
import head from 'lodash.head'
import { LottieWhale } from '../../components/Lottie'
import Error from '../../components/Error'
import DropUploadContainer from '../../components/DropUploadContainer'
import ModalBanner from '../allsite/ModalBanner'
import BannerItem from './BannerItem'
import { useBannersQuery } from '../../graphql/useBanners'
import last from 'lodash.last'
import orderBy from 'lodash.orderby'
import { Theme, DropdownMenu } from 'vo-components'
import BannersSortingPanel from './BannersSortingPanel'
import { ModalFormMode } from '../../models/ModalModel'

const styles = stylesheet({
    uploadContainer: {
        minHeight: viewHeight(25),
    },
    emptyButtonContainer: {
        marginTop: em(1),
    },
    dragPlaceholder: {
        position: 'relative',
        borderColor: Theme.colors.info,
        borderRadius: 5,
        borderWidth: 4,
        borderStyle: 'solid',
        backgroundColor: Theme.colors.infoFade,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        minHeight: 200,
    },
})

export default function Banners() {
    const [isSortOpen, setIsSortOpen] = useState(false)
    const [isModalBannerOpen, setIsModalBannerOpen] = useState(false)
    const { loading, error, data, refetch } = useBannersQuery({})
    const [fileToBeUploaded, setFileToBeUploaded] = useState<File | undefined>(undefined)
    const onDrop = useCallback((acceptedFiles: File[]) => {
        // Do something with the files
        const file = head(acceptedFiles)
        if (file) {
            setFileToBeUploaded(file)
            setIsModalBannerOpen(true)
        }
    }, [])
    const onUploadCancel = () => {
        setFileToBeUploaded(undefined)
        setIsModalBannerOpen(false)
    }
    const dropzone = useDropzone({
        onDrop,
        accept: ['image/*'],
        noClick: true,
    })
    const banners = useMemo(() => orderBy(data?.listBanners?.items || [], ['sort_key'], ['asc']), [data])
    const latestSortKey = last(banners)?.sort_key || undefined
    return (
        <>
            <BannersSortingPanel isOpen={isSortOpen} onClose={() => setIsSortOpen(false)} items={banners} />
            <ModalBanner
                isOpen={isModalBannerOpen}
                onClose={onUploadCancel}
                mode={ModalFormMode.CREATE}
                fileToBeUploaded={fileToBeUploaded}
                latestSortKey={latestSortKey}
            />
            <div className="columns is-vcentered">
                <div className="column is-narrow">
                    <h1 className="is-size-3 title">Banners</h1>
                    <h2 className="is-size-6 subtitle">These banners exist on top of your home page (suggested ratio is 16:9)</h2>
                </div>
                <div className="column">
                    <div className="buttons is-right">
                        <button onClick={() => dropzone.open()} className="button is-primary">
                            Upload a banner
                        </button>
                        <DropdownMenu className="has-background-info" align="right">
                            <button onClick={() => setIsSortOpen(true)} className="button is-white dropdown-item">
                                Re-order banners
                            </button>
                        </DropdownMenu>
                    </div>
                </div>
            </div>
            {loading && (
                <span className="icon is-large">
                    <i className="fas fa-2x fa-yin-yang fa-pulse" />
                </span>
            )}
            <DropUploadContainer className={styles.uploadContainer} dropzone={dropzone}>
                {error && <Error errorTrace={error} retry={refetch} />}
                {banners.length === 0 && !loading && (
                    <>
                        <LottieWhale height={320} />
                        <p className="has-text-centered">You don&apos;t have any banners!</p>
                        <div className={styles.emptyButtonContainer}>
                            <div className="buttons is-centered">
                                <button onClick={() => dropzone.open()} className="button is-primary">
                                    Upload a banner
                                </button>
                            </div>
                        </div>
                    </>
                )}
                <div className="columns is-multiline">
                    {banners.map(banner => (
                        <div key={banner.banner_id} className="column is-4">
                            <BannerItem banner={banner} />
                        </div>
                    ))}
                </div>
            </DropUploadContainer>
        </>
    )
}
