import React from 'react'
import { BasicModalProps } from '../../models/ModalModel'
import { Collection, PortfolioSection } from 'vo-components'
import CollectionThumbnail, { CollectionThumbnailPreview } from '../allsite/CollectionThumbnail'
import SortingPanel from '../../components/SortingPanel'

interface CollectionsSortingPanelProps extends BasicModalProps {
    items: Collection[]
    portfolioSection?: PortfolioSection
    baseUrl: string
}

export default function CollectionsSortingPanel({ isOpen, onClose, items, portfolioSection, baseUrl }: CollectionsSortingPanelProps) {
    return (
        <SortingPanel<Collection>
            isOpen={isOpen}
            onClose={onClose}
            title="Sort your collections"
            type="Collection"
            items={items}
            getIdFromItem={collection => collection.collection_id}
            renderPlaceholder={(item, index, sortedItems) => (
                <CollectionThumbnail
                    cacheHandlerKey="COLLECTION"
                    collection={item}
                    portfolioSection={portfolioSection}
                    placeholder
                    nextSortKey={(index !== 0 && sortedItems[index - 1].sort_key) || undefined}
                    previousSortKey={(index !== sortedItems.length - 1 && sortedItems[index + 1].sort_key) || undefined}
                    baseUrl={baseUrl}
                />
            )}
            renderItem={item => <CollectionThumbnailPreview collection={item} />}
        />
    )
}
