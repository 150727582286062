import React from 'react'
import Collections from './Collections'
import { useCollectionsQuery } from '../../graphql/useCollections'
import Error from '../../components/Error'

const LISTING_STATUS = 'PROJECTS'

export default function PageProjects() {
    const { loading, error, data, refetch } = useCollectionsQuery({ listingStatus: LISTING_STATUS })
    const { items } = data?.listCollectionsByListingStatus || {}
    if (error) {
        return (
            <div className="section">
                <div className="container">
                    <Error errorTrace={error} retry={refetch} />
                </div>
            </div>
        )
    }
    if (loading) {
        return null
    }
    return (
        <>
            <div className="section">
                <div className="container">
                    <Collections
                        cacheHandlerKey="COLLECTION"
                        collections={items || []}
                        listingStatus={LISTING_STATUS}
                        title="Projects"
                        baseUrl="/projects"
                    />
                </div>
            </div>
        </>
    )
}
