import React, { useMemo } from 'react'
import { IndividualReportProps } from './Reports'
import { parseReportResponse } from '../../util/googleAnalyticsReport'

export default function ReportPageVisit({ report }: IndividualReportProps) {
    const parsedReport = useMemo(() => parseReportResponse(report), [report])
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-header-title is-size-5">What pages do your users visit?</h3>
            </div>
            <table className="card-content table is-fullwidth is-striped is-hoverable">
                <thead>
                    <tr>
                        <th className="has-text-left">Page</th>
                        <th className="has-text-centered">Views</th>
                        <th className="has-text-centered">Entrances</th>
                    </tr>
                </thead>
                <tbody>
                    {parsedReport.map(row => (
                        <tr key={row.dimensions['ga:pagePath']}>
                            <td className="has-text-left">
                                <a href={`https://veronicaonggoro.com${row.dimensions['ga:pagePath']}`} target="_blank" rel="noopener noreferrer">
                                    {row.dimensions['ga:pagePath']}
                                </a>
                            </td>
                            <td className="has-text-centered">{row.values['ga:pageviews']}</td>
                            <td className="has-text-centered">{row.values['ga:entrances']}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
