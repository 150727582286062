import React, { useState, useContext, useMemo, useEffect } from 'react'
import { useCollectionAssetsLazyQuery } from '../../graphql/useCollectionAssets'
import { CollectionDetailContext } from './CollectionDetail'
import orderBy from 'lodash.orderby'
import { CollectionAssetType, CollectionAssetConnection, CollectionAsset, Collection } from 'vo-components'
import { classes, stylesheet } from 'typestyle'
import Tooltip from '@atlaskit/tooltip'
import CollectionAssetsSortingPanel from './CollectionAssetsSortingPanel'
import { DropzoneState } from 'react-dropzone'
import CollectionAssetsLayout, { CollectionAssetsLayoutType } from './CollectionAssetsLayout'
import { MultiSelectFeatureAction, MultiSelectFeatureWrapper, LightBoxFeatureWrapper } from './CollectionAssetsFeatureWrapper'
import ModalAssetSegmentList from './ModalAssetSegmentList'

const styles = stylesheet({
    assetActionButtonsContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
})

interface CollectionAssetsSegmentContext {
    assets: CollectionAsset[]
}
export const CollectionAssetsSegmentContext = React.createContext<CollectionAssetsSegmentContext>({} as CollectionAssetsSegmentContext)

export interface SegmentLayoutConfig {
    type: CollectionAssetType
    title?: string
    hasLightBox?: boolean
    layoutType?: CollectionAssetsLayoutType
    multiSelectActions?: MultiSelectFeatureAction[]
    hasMultiSelect?: boolean
    disableSort?: boolean
    hasFileList?: boolean
}

interface CollectionAssetsSegmentProps extends SegmentLayoutConfig {
    dropzone: DropzoneState
}

export const defaultPhotoSegmentLayoutConfig: SegmentLayoutConfig = {
    type: CollectionAssetType.Image,
    title: 'Photos',
    hasLightBox: true,
    layoutType: CollectionAssetsLayoutType.MASONRY,
    multiSelectActions: [MultiSelectFeatureAction.DELETE],
    hasMultiSelect: true,
    disableSort: false,
}

export const defaultVideoSegmentLayoutConfig: SegmentLayoutConfig = {
    type: CollectionAssetType.Video,
    title: 'Videos',
}

export const getCollectionAssetsBySegment = (collection: Collection, type: CollectionAssetType): CollectionAssetConnection => {
    const segment = collection.segments?.find(segment => segment?.type === type)
    return segment?.assets || { items: [] }
}

export default function CollectionAssetsSegment({
    title,
    type,
    dropzone,
    hasLightBox,
    hasMultiSelect,
    hasFileList,
    multiSelectActions,
    layoutType,
    disableSort,
}: CollectionAssetsSegmentProps) {
    const [isModalAssetSegmentListOpen, setIsModalAssetSegmentListOpen] = useState(false)
    const { collection } = useContext(CollectionDetailContext)
    const { items, nextToken: initialNextToken } = useMemo<CollectionAssetConnection>(() => getCollectionAssetsBySegment(collection, type), [
        collection,
        type,
    ])
    const [loadMore, { called, loading: loadMoreLoading, data }] = useCollectionAssetsLazyQuery()
    const [nextToken, setNextToken] = useState<string | undefined>(initialNextToken || undefined)
    useEffect(() => {
        if (called) {
            setNextToken(data?.listCollectionAssets?.nextToken || undefined)
        }
    }, [called, data])
    const sortedAssets = useMemo(
        () =>
            orderBy(
                [...(items || []), ...(data?.listCollectionAssets?.items || [])].filter(photo => !!photo.file),
                ['sort_key'],
                ['desc']
            ),
        [items, data]
    )
    const [isSortOpen, setIsSortOpen] = useState(false)
    if (sortedAssets.length === 0) return null
    return (
        <CollectionAssetsSegmentContext.Provider value={{ assets: sortedAssets }}>
            <>
                <ModalAssetSegmentList
                    assets={sortedAssets}
                    isOpen={isModalAssetSegmentListOpen}
                    onClose={() => setIsModalAssetSegmentListOpen(false)}
                />
                <CollectionAssetsSortingPanel
                    isOpen={isSortOpen}
                    onClose={() => setIsSortOpen(false)}
                    collection={collection}
                    items={sortedAssets}
                    type={type}
                />
                <div className="section">
                    <h3 className="is-size-3 has-text-centered title">
                        {title}
                        <div className={styles.assetActionButtonsContainer}>
                            {!disableSort && (
                                <Tooltip position="top" content="re-order photos">
                                    <button className="button is-white is-rounded" onClick={() => setIsSortOpen(true)}>
                                        <span className="icon has-text-info">
                                            <i className="fas fa-arrows-alt" />
                                        </span>
                                    </button>
                                </Tooltip>
                            )}
                            {dropzone.open && (
                                <Tooltip position="top" content="upload photos">
                                    <button className="button is-white is-rounded" onClick={() => dropzone.open()}>
                                        <span className="icon has-text-success">
                                            <i className="fas fa-upload" />
                                        </span>
                                    </button>
                                </Tooltip>
                            )}
                            {hasFileList && (
                                <Tooltip position="top" content="upload photos">
                                    <button className="button is-info is-rounded" onClick={() => setIsModalAssetSegmentListOpen(true)}>
                                        Open file list
                                    </button>
                                </Tooltip>
                            )}
                        </div>
                    </h3>

                    <MultiSelectFeatureWrapper enabled={hasMultiSelect || false} actions={multiSelectActions || []}>
                        <LightBoxFeatureWrapper enabled={hasLightBox || false}>
                            <CollectionAssetsLayout layoutType={layoutType} />
                        </LightBoxFeatureWrapper>
                    </MultiSelectFeatureWrapper>
                    {nextToken && (
                        <div className="buttons is-centered">
                            <button
                                onClick={() =>
                                    loadMore({
                                        variables: {
                                            collection_id: collection.collection_id,
                                            type,
                                            next_token: nextToken,
                                        },
                                    })
                                }
                                className={classes('button is-outline', loadMoreLoading && 'is-loading')}
                            >
                                <span>Load more</span>
                            </button>
                        </div>
                    )}
                </div>
            </>
        </CollectionAssetsSegmentContext.Provider>
    )
}
