import React, { useState, useMemo, useEffect } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

interface DraftEditorProps {
    value?: string
    onChange: (value: string) => void
}

export default function DraftEditor({ value, onChange }: DraftEditorProps) {
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(value || '').contentBlocks))
    )
    const htmlValue = useMemo(() => draftToHtml(convertToRaw(editorState.getCurrentContent())), [editorState])
    useEffect(() => {
        onChange(htmlValue)
    }, [htmlValue])
    return (
        <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            editorClassName="textarea"
            toolbar={{
                options: ['inline', 'link', 'emoji'],
                inline: {
                    options: ['bold', 'italic', 'underline'],
                },
            }}
        />
    )
}
