import React from 'react'
import CollectionAssetItem from './CollectionAssetItem'
import { BasicModalProps } from '../../models/ModalModel'
import { Collection, CollectionAsset, CollectionAssetType } from 'vo-components'
import SortingPanel from '../../components/SortingPanel'

interface CollectionAssetsSortingPanelProps extends BasicModalProps {
    items: CollectionAsset[]
    collection: Collection
    type: CollectionAssetType
}

export default function CollectionAssetsSortingPanel({ isOpen, onClose, items, collection, type }: CollectionAssetsSortingPanelProps) {
    return (
        <SortingPanel<CollectionAsset>
            title="Sort your photos"
            type={type}
            items={items}
            isOpen={isOpen}
            onClose={onClose}
            getIdFromItem={asset => asset.asset_id}
            renderPlaceholder={(asset, index, sortedItems) => (
                <CollectionAssetItem
                    collection={collection}
                    asset={asset}
                    placeholder
                    nextSortKey={(index !== 0 && sortedItems[index - 1].sort_key) || undefined}
                    previousSortKey={(index !== sortedItems.length - 1 && sortedItems[index + 1].sort_key) || undefined}
                    index={index}
                />
            )}
            renderItem={(asset, index) => <CollectionAssetItem isPreviewOnly collection={collection} asset={asset} index={index} />}
        />
    )
}
