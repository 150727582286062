import React, { useContext } from 'react'
import CollectionAssets from './CollectionAssets'
import CollectionDetail, { CollectionDetailContext } from './CollectionDetail'
import { Switch, Route, Redirect, useRouteMatch, matchPath, useLocation, Link } from 'react-router-dom'
import { classes } from 'typestyle'
import { CollectionAssetType, Message, CollectionCustomerPhase } from 'vo-components'
import orderBy from 'lodash.orderby'
import { SegmentLayoutConfig, defaultPhotoSegmentLayoutConfig } from './CollectionAssetsSegment'
import { MultiSelectFeatureAction } from './CollectionAssetsFeatureWrapper'
import { useCollectionMessagesQuery } from '../../graphql/useCollectionMessages'
import { format } from 'date-fns'
import Error from '../../components/Error'
import CustomerFinalAssetsHeader from './CustomerFinalAssetsHeader'
import { useCollectionsUpdateActiveCustomerPhase } from '../../graphql/useCollections'

const rawPhotoSegmentConfig: SegmentLayoutConfig = {
    ...defaultPhotoSegmentLayoutConfig,
    type: CollectionAssetType.ImageRaw,
    title: 'Raw Photos',
    multiSelectActions: [MultiSelectFeatureAction.DELETE, MultiSelectFeatureAction.MOVE_STAGE],
}

const selectedRawPhotoSegmentConfig: SegmentLayoutConfig = {
    ...defaultPhotoSegmentLayoutConfig,
    type: CollectionAssetType.ImageRawSelected,
    title: 'Selected Raw Photos',
    hasMultiSelect: false,
    disableSort: true,
    multiSelectActions: [],
    hasFileList: true,
}

const finalPhotoSegmentConfig: SegmentLayoutConfig = {
    ...defaultPhotoSegmentLayoutConfig,
    title: 'Final Photos',
}

function ImageRawSelectedHeader() {
    const { collection } = useContext(CollectionDetailContext)
    const { data, error } = useCollectionMessagesQuery({ collection_id: collection.collection_id })
    const messages: Message[] = orderBy(data?.listCollectionMessages?.items || [], ['created_at'], ['desc'])
    return (
        <div>
            <div>
                {messages.length > 0 && <p>Messages from customer:</p>}
                {error && <Error isCompact errorTrace={error} />}
                {messages.map(message => (
                    <article key={message.message_id} className="message">
                        <div className="message-body">
                            {message.created_at && (
                                <p className="is-size-7 has-text-weight-semibold">
                                    Sent at: {format(new Date(message.created_at), 'dd MMM yyyy, HH:mm')}
                                </p>
                            )}
                            <p className="has-newline">{message.message}</p>
                        </div>
                    </article>
                ))}
            </div>
        </div>
    )
}

interface ActiveCustomerPhaseButtonProps {
    activeCustomerPhase: CollectionCustomerPhase
}

function ActiveCustomerPhaseButton({ activeCustomerPhase }: ActiveCustomerPhaseButtonProps) {
    const { collection } = useContext(CollectionDetailContext)
    const [activate, { error, loading }] = useCollectionsUpdateActiveCustomerPhase({
        collection,
        activeCustomerPhase,
    })
    return (
        <>
            {collection.active_customer_phase !== activeCustomerPhase && (
                <nav className="navbar is-light is-fixed-bottom">
                    <div className="navbar-menu is-active">
                        <div className="navbar-end">
                            <div className="navbar-item">
                                <div className="buttons is-right">
                                    <button onClick={() => activate()} className={classes('button is-info', loading && 'is-loading')}>
                                        <strong>Activate Phase</strong>
                                    </button>
                                    {error && <Error isCompact errorTrace={error} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            )}
        </>
    )
}

const ROUTE_MATCH = '/clients/:customerId/:collectionSlug'

function CollectionPhaseTabs() {
    const { collection } = useContext(CollectionDetailContext)
    const match = useRouteMatch(ROUTE_MATCH)
    const location = useLocation()
    return (
        <div className="tabs">
            <ul>
                <li className={classes(matchPath(location.pathname, `${match?.url}/raw-assets`) && 'is-active')}>
                    <Link to={`${match?.url}/raw-assets`}>
                        {collection.active_customer_phase === CollectionCustomerPhase.PickRawAssets && (
                            <span className="icon is-small has-text-success">
                                <i className="far fa-lightbulb" aria-hidden="true" />
                            </span>
                        )}
                        <span>Raw Assets</span>
                    </Link>
                </li>
                <li className={classes(matchPath(location.pathname, `${match?.url}/selected-raw-assets`) && 'is-active')}>
                    <Link to={`${match?.url}/selected-raw-assets`}>
                        {collection.active_customer_phase === CollectionCustomerPhase.EditingInProgress && (
                            <span className="icon is-small has-text-success">
                                <i className="far fa-lightbulb" aria-hidden="true" />
                            </span>
                        )}
                        <span>Selected Raw Assets</span>
                    </Link>
                </li>
                <li className={classes(matchPath(location.pathname, `${match?.url}/final-assets`) && 'is-active')}>
                    <Link to={`${match?.url}/final-assets`}>
                        {collection.active_customer_phase === CollectionCustomerPhase.EditedAssetsReady && (
                            <span className="icon is-small has-text-success">
                                <i className="far fa-lightbulb" aria-hidden="true" />
                            </span>
                        )}
                        <span>Final Assets</span>
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default function PageCustomerAssetList() {
    const match = useRouteMatch(ROUTE_MATCH)
    return (
        <>
            <CollectionDetail assetTypes={[CollectionAssetType.Image, CollectionAssetType.ImageRawSelected, CollectionAssetType.ImageRaw]}>
                <CollectionPhaseTabs />
                <Switch>
                    <Route exact path="/clients/:customerId/:collectionSlug/raw-assets">
                        <CollectionAssets photoConfig={rawPhotoSegmentConfig} />
                        <ActiveCustomerPhaseButton activeCustomerPhase={CollectionCustomerPhase.PickRawAssets} />
                    </Route>
                    <Route exact path="/clients/:customerId/:collectionSlug/selected-raw-assets">
                        <ImageRawSelectedHeader />
                        <CollectionAssets photoConfig={selectedRawPhotoSegmentConfig} disableUpload />
                        <ActiveCustomerPhaseButton activeCustomerPhase={CollectionCustomerPhase.EditingInProgress} />
                    </Route>
                    <Route exact path="/clients/:customerId/:collectionSlug/final-assets">
                        <CustomerFinalAssetsHeader />
                        <CollectionAssets photoConfig={finalPhotoSegmentConfig} />
                        <ActiveCustomerPhaseButton activeCustomerPhase={CollectionCustomerPhase.EditedAssetsReady} />
                    </Route>
                    <Route path="*">
                        <Redirect to={`${match?.url}/raw-assets`} />
                    </Route>
                </Switch>
            </CollectionDetail>
        </>
    )
}
