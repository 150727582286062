import React, { useState } from 'react'
import { classes, stylesheet } from 'typestyle'
import { em } from 'csx'
import Tooltip from '@atlaskit/tooltip'
import { Theme, DynamicImage, Banner } from 'vo-components'
import Dialog from '../../components/Dialog'
import ModalBanner from '../allsite/ModalBanner'
import { useBannersDeleteMutation, useBannersSortMutation } from '../../graphql/useBanners'
import { DragPlaceholder, useSortKey, Sortable } from '../../components/Sortable'
import { ModalFormMode } from '../../models/ModalModel'
import { Thumbnail } from '../../models/ThumbnailModel'

const styles = stylesheet({
    banner: {
        $nest: {
            '&::before': {
                content: "''",
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                backgroundColor: Theme.colors.greyLight,
                color: 'currentColor',
                textAlign: 'center',
                borderRadius: 2,
                display: 'block',
                overflow: 'hidden',
            },
        },
    },
    overlay: {
        backgroundColor: Theme.colors.transparent,
        opacity: 0,
        transition: 'opacity 300ms ease-out',
        $nest: {
            '&:hover': {
                opacity: 1,
            },
        },
    },
    actionButtons: {
        position: 'absolute',
        top: 0,
        right: 0,
        paddingTop: em(0.5),
        paddingRight: em(0.5),
    },
    deleteButton: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        paddingLeft: em(0.5),
        paddingBottom: em(0.5),
    },
})

interface BannerItemProps extends Sortable, Thumbnail {
    banner: Banner
}

export default function BannerItem({ banner, placeholder, previousSortKey, nextSortKey, isPreviewOnly }: BannerItemProps) {
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [isModalBannerOpen, setisModalBannerOpen] = useState(false)
    const [deleteBanner, { loading: deleteLoading, error: deleteError }] = useBannersDeleteMutation({ banner })
    const candidateSortKey = useSortKey({ originalSortKey: banner.sort_key || '', previousSortKey, nextSortKey, skip: !placeholder })
    const [sort] = useBannersSortMutation({ banner, sortKey: candidateSortKey })
    if (!banner) return null
    if (placeholder) {
        return <DragPlaceholder mutate={sort}>{ref => <div ref={ref} className="is-overlay" />}</DragPlaceholder>
    }
    return (
        <>
            <ModalBanner mode={ModalFormMode.UPDATE} isOpen={isModalBannerOpen} banner={banner} onClose={() => setisModalBannerOpen(false)} />
            <figure className={classes('image is-16by9', styles.banner)}>
                <DynamicImage alt={banner.banner_id} src={banner.file?.public_url || ''} width={480} />
                {!isPreviewOnly && (
                    <div className={classes('is-overlay', styles.overlay)}>
                        <div className={classes('buttons', styles.actionButtons)}>
                            <Tooltip position="left" content="edit banner">
                                <button onClick={() => setisModalBannerOpen(true)} className="button is-small is-info">
                                    <span className="icon is-small">
                                        <i className="fas fa-edit" />
                                    </span>
                                </button>
                            </Tooltip>
                        </div>
                        <div className={styles.deleteButton}>
                            <Tooltip position="right" content="delete banner">
                                <button onClick={() => setIsDeleteDialogOpen(true)} className="button is-small is-danger">
                                    <span className="icon is-small">
                                        <i className="fas fa-trash" />
                                    </span>
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                )}
                <Dialog
                    isOpen={isDeleteDialogOpen}
                    onClose={() => setIsDeleteDialogOpen(false)}
                    onYes={() => deleteBanner().catch(console.error)}
                    title="Are you sure you want to delete this banner?"
                    yesLabel="Yes, delete this banner"
                    noLabel="Cancel"
                    loading={deleteLoading}
                    error={deleteError}
                />
            </figure>
        </>
    )
}
