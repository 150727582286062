import React from 'react'
import { useShowCustomerQuery } from '../../graphql/useCustomers'
import Error from '../../components/Error'
import { useParams, Link } from 'react-router-dom'
import Collections from './Collections'

export default function PageCustomerSessions() {
    const { customerId } = useParams()
    const { loading, error, data, refetch } = useShowCustomerQuery({ customerId: customerId || '' })
    const customer = data?.showCustomer
    if (error) {
        return (
            <div className="section">
                <div className="container">
                    <Error errorTrace={error} retry={refetch} />
                </div>
            </div>
        )
    }
    if (loading || !customer) {
        return null
    }
    return (
        <div className="section">
            <div className="container">
                <nav className="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <Link to="/clients">Clients</Link>
                        </li>
                        <li className="is-active">
                            <Link to={`/clients/${customer.customer_id}`}>{customer.name}</Link>
                        </li>
                    </ul>
                </nav>
                <Collections
                    cacheHandlerKey="CUSTOMER_SESSION"
                    collections={customer.sessions?.items || []}
                    listingStatus="CUSTOMER"
                    title={`Sessions for ${customer.name}`}
                    baseUrl={`/clients/${customer.customer_id}`}
                    customer={customer}
                />
            </div>
        </div>
    )
}
