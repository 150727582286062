import { useQuery } from '@apollo/client'
import { SHOW_LATEST_GOOGLE_ANALYTICS_REPORT, Maybe, QueryShowLatestGoogleAnalyticsReportArgs, GaReportSnapshot } from 'vo-components'

interface LatestGoogleAnalyticsReportQueryArgs {
    site: string
}

export function useLatestGoogleAnalyticsReport({ site }: LatestGoogleAnalyticsReportQueryArgs) {
    return useQuery<{ showLatestGoogleAnalyticsReport: Maybe<GaReportSnapshot> }, QueryShowLatestGoogleAnalyticsReportArgs>(
        SHOW_LATEST_GOOGLE_ANALYTICS_REPORT,
        {
            variables: { site },
        }
    )
}
