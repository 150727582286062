import React, { useContext, useEffect, useMemo, useCallback, useState } from 'react'
import { CollectionDetailContext } from './CollectionDetail'
import { classes, stylesheet } from 'typestyle'
import { saveAs } from 'file-saver'
import {
    CollectionAssetType,
    CustomerFileRequestType,
    CustomerFile,
    CustomerFileType,
    CustomerFileTypeSystemIdentifier,
    flattenCustomerFiles,
    getFileExtension,
    FlatCustomerFile,
    DropdownMenu,
    getFileS3PathForAmplify,
} from 'vo-components'
import orderBy from 'lodash.orderby'
import { getCollectionAssetsBySegment } from './CollectionAssetsSegment'
import { format } from 'date-fns'
import Error from '../../components/Error'
import { useRequestCustomerFileMutation, useCustomerFileQuery, useDeleteCustomerFileMutation } from '../../graphql/useCustomerFiles'
import { Storage } from 'aws-amplify'
import { useError } from 'react-use'
import iconZip from '../../assets/file-extensions-icon/zip.png'
import iconImage from '../../assets/file-extensions-icon/image.png'
import iconPdf from '../../assets/file-extensions-icon/pdf.png'
import iconFile from '../../assets/file-extensions-icon/file.png'
import { useDropzone } from 'react-dropzone'
import head from 'lodash.head'
import { useUploader } from '../../components/useUploader'
import Circle from 'react-circle'
import Dialog from '../../components/Dialog'

const styles = stylesheet({
    fileListColumns: {
        justifyContent: 'flex-end',
    },
})

const fileIconMap = new Map<string, string>([
    ['DEFAULT', iconFile],
    ['ZIP', iconZip],
    ['PDF', iconPdf],
    ['JPG', iconImage],
    ['JPEG', iconImage],
    ['PNG', iconImage],
])

interface FinalAssetsItemProps {
    customerFile: FlatCustomerFile
}

function FinalAssetsItem({ customerFile }: FinalAssetsItemProps) {
    const dispatchError = useError()
    const { collection } = useContext(CollectionDetailContext)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [deleteFile, { error: deleteError, loading: deleteLoading }] = useDeleteCustomerFileMutation({ collection_id: collection.collection_id })
    const isMainFile = useMemo(
        () => customerFile.type === CustomerFileType.SystemGenerated && customerFile.system_identifier === CustomerFileTypeSystemIdentifier.ZipAll,
        [customerFile]
    )
    return (
        <div key={customerFile.file_id} className="column is-3">
            <div className="card is-relative">
                <div className="card-content">
                    <div className="media">
                        <div className="media-left">
                            <figure className="image is-48x48">
                                <img
                                    alt="File Type"
                                    src={fileIconMap.get(getFileExtension(customerFile.file_path) || '') || fileIconMap.get('DEFAULT')}
                                />
                            </figure>
                        </div>
                        <div className="media-content">
                            {customerFile.is_ready ? (
                                <>
                                    <p className="title is-6">{customerFile.file_name}</p>
                                    <p className="subtitle is-7">
                                        {format(new Date(customerFile.request_timestamp), 'dd MMM yyyy HH:mm')}
                                        {isMainFile && (
                                            <>
                                                &nbsp;&nbsp;&middot;&nbsp;&nbsp;Downloaded <strong>{collection.download_history?.length || 0}</strong>{' '}
                                                time(s)
                                            </>
                                        )}
                                    </p>
                                </>
                            ) : (
                                <p className="title is-6">Processing file...</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <button
                        onClick={() => {
                            Storage.get(getFileS3PathForAmplify(customerFile.file_path), {
                                level: 'protected',
                                identityId: collection.customer_id,
                                bucket: process.env.REACT_APP_CUSTOMER_BUCKET,
                            })
                                .then(result => {
                                    saveAs(result as string)
                                })
                                .catch(dispatchError)
                        }}
                        className={classes('button is-info is-fullwidth is-radiusless', !customerFile.is_ready && 'is-loading')}
                    >
                        Download
                    </button>
                </div>
                <div className="is-overlay is-top-right">
                    <DropdownMenu>
                        <button onClick={() => setIsDeleteDialogOpen(true)} className="button is-white dropdown-item has-text-danger">
                            Delete
                        </button>
                    </DropdownMenu>
                </div>
                <Dialog
                    isOpen={isDeleteDialogOpen}
                    onClose={() => setIsDeleteDialogOpen(false)}
                    onYes={() => deleteFile({ variables: { file_id: customerFile.file_id } }).catch(console.error)}
                    title="Are you sure you want to delete this file?"
                    yesLabel="Yes, delete this  file"
                    noLabel="Cancel"
                    loading={deleteLoading}
                    error={deleteError}
                />
            </div>
        </div>
    )
}

function FinalAssetsUploadButton() {
    const dispatchError = useError()
    const { collection } = useContext(CollectionDetailContext)
    const [fileToBeUploaded, setFileToBeUploaded] = useState<File | undefined>(undefined)
    const { upload, uploadProgress, resetProgress } = useUploader({
        onCompleted: () => {
            resetProgress()
            setFileToBeUploaded(undefined)
        },
    })
    const [requestFile, { loading }] = useRequestCustomerFileMutation({
        onCompleted: (data: { requestCustomerFile?: CustomerFile }) => {
            if (fileToBeUploaded && data.requestCustomerFile) {
                upload({
                    handler: 'CustomerFile',
                    id: data.requestCustomerFile.file_id || '',
                    file: fileToBeUploaded,
                    access_level: 'private',
                    disablePrefix: true,
                    folderPrefix: collection.customer_id || undefined,
                })
            }
        },
    })
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            const file = head(acceptedFiles)
            if (file) {
                setFileToBeUploaded(file)
                requestFile({
                    variables: {
                        customer_id: collection.customer_id || '',
                        input: {
                            request_type: CustomerFileRequestType.Upload,
                            collection_id: collection.collection_id,
                            collection_segment: CollectionAssetType.Image,
                            input_option: {
                                // THIS MUST BE AN EMPTY ARRAY
                                original_paths: [],
                            },
                            output_option: {
                                additional_path: `${collection.collection_id}/${CollectionAssetType.Image}`,
                            },
                            type: CustomerFileType.UserDefined,
                        },
                    },
                }).catch(dispatchError)
            }
        },
        [collection, requestFile, dispatchError]
    )
    const dropzone = useDropzone({
        onDrop,
        noClick: true,
    })
    return (
        <span>
            <input {...dropzone.getInputProps()} />
            <button onClick={() => dropzone.open()} className={classes('button', loading && 'is-loading')}>
                <span>Upload custom file</span>
                {uploadProgress && (
                    <span className="icon">
                        <Circle animate={false} progress={uploadProgress || 0} size="20" showPercentage={false} lineWidth="35" />
                    </span>
                )}
            </button>
        </span>
    )
}

export default function CustomerFinalAssetsHeader() {
    const dispatchError = useError()
    const { collection } = useContext(CollectionDetailContext)
    const { data, error: queryError, startPolling, stopPolling } = useCustomerFileQuery({ collection_id: collection.collection_id })
    const customerFiles: CustomerFile[] = useMemo(() => orderBy(data?.listCustomerFile?.items || [], ['request_timestamp'], ['desc']), [data])
    const flattenedFiles = useMemo(() => flattenCustomerFiles(customerFiles), [customerFiles])
    useEffect(() => {
        if (customerFiles.filter(file => !file.is_ready).length > 0) {
            startPolling(5000)
        } else {
            stopPolling()
        }
        return () => {
            stopPolling()
        }
    }, [customerFiles, startPolling, stopPolling])
    const assetFileKeys: string[] = getCollectionAssetsBySegment(collection, CollectionAssetType.Image)
        .items.map(asset => asset.file?.original_s3_key || '')
        .filter(s3Key => !!s3Key)
    const [requestFile, { error: requestFileError, loading: requestFileLoading }] = useRequestCustomerFileMutation({})
    const [deleteFile, { error: deleteError, loading: deleteLoading }] = useDeleteCustomerFileMutation({ collection_id: collection.collection_id })
    const mainZipFile = useMemo(
        () =>
            customerFiles.find(
                file => file.type === CustomerFileType.SystemGenerated && file.system_identifier === CustomerFileTypeSystemIdentifier.ZipAll
            ),
        [customerFiles]
    )
    return (
        <>
            {queryError && <Error isCompact errorTrace={queryError} />}
            <p className="buttons is-right">
                {assetFileKeys.length > 0 && (
                    <button
                        onClick={async () => {
                            if (mainZipFile) {
                                await deleteFile({
                                    variables: {
                                        file_id: mainZipFile.file_id,
                                    },
                                }).catch(dispatchError)
                            }
                            requestFile({
                                variables: {
                                    customer_id: collection.customer_id || '',
                                    input: {
                                        request_type: CustomerFileRequestType.Zip,
                                        collection_id: collection.collection_id,
                                        collection_segment: CollectionAssetType.Image,
                                        input_option: {
                                            original_paths: assetFileKeys,
                                        },
                                        output_option: {
                                            additional_path: `${collection.collection_id}/${CollectionAssetType.Image}`,
                                            zip_file_name: `${collection.slug}-final-output`,
                                        },
                                        type: CustomerFileType.SystemGenerated,
                                        system_identifier: CustomerFileTypeSystemIdentifier.ZipAll,
                                    },
                                },
                            }).catch(dispatchError)
                        }}
                        className={classes('button is-success', (requestFileLoading || deleteLoading) && 'is-loading')}
                    >
                        {mainZipFile ? 'Re-' : ''}
                        ZIP all assets
                    </button>
                )}
                <FinalAssetsUploadButton />
            </p>
            <div className={classes('columns is-multiline', styles.fileListColumns)}>
                {flattenedFiles.map(customerFile => (
                    <FinalAssetsItem key={`${customerFile.file_id}.${customerFile.file_path}`} customerFile={customerFile} />
                ))}
            </div>
            {(requestFileError || deleteError) && <Error isCompact errorTrace={requestFileError || deleteError} />}
        </>
    )
}
