import React, { useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import useThirdPartyLoginScript from './components/useThirdPartyAuthScript'
import { User } from './models/AuthModel'
import { HubCallback } from '@aws-amplify/core/lib-esm/Hub'
import { Cache, Auth, Hub, Storage } from 'aws-amplify'

Auth.configure({
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
    mandatorySignIn: true,
})
Storage.configure({
    AWSS3: {
        bucket: process.env.REACT_APP_UPLOAD_BUCKET,
        region: process.env.REACT_APP_AWS_REGION,
    },
})

interface AuthContext {
    isLoggedIn: boolean
    isMaybeLoggedIn: boolean
    user?: User
    logout: () => void
}

export const AuthContext = React.createContext<AuthContext>({} as AuthContext)

interface AuthenticatorProps {
    children: React.ReactNode
}

export default function Authenticator({ children }: AuthenticatorProps) {
    const client = useApolloClient()
    const history = useHistory()
    useThirdPartyLoginScript()
    const [providerUser, setProviderUser] = useState<User | undefined>(undefined)
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)

    useEffect(() => {
        // listen to authentication provider
        const providerListener: HubCallback = async ({ payload: { data } }) => {
            Hub.remove('authProvider', providerListener)
            await Auth.federatedSignIn(data.provider, data.credentials, data.user)
            setIsLoggedIn(true)
            setProviderUser(data.user)
        }
        Hub.listen('authProvider', providerListener)

        // get current credentials if exist on visit / on refresh
        Auth.currentCredentials().then(credential => {
            if (credential.authenticated) {
                setIsLoggedIn(true)
                Hub.remove('authProvider', providerListener)
            }
        })
        Auth.currentAuthenticatedUser().then(user => {
            setProviderUser({
                name: user.name,
                profile_picture_url: user.profile_picture_url,
                email: user.email,
            })
        })

        // listen for signup to clear graphql client
        Hub.listen('auth', async ({ payload: { event } }) => {
            if (event !== 'signOut') {
                return
            }
            setIsLoggedIn(false)
            if (window.gapi && window.gapi.auth2) {
                window.gapi.auth2.getAuthInstance().signOut()
            }
            client.clearStore()
            history.push('/')
        })
        return () => {
            Hub.remove('authProvider', providerListener)
        }
    }, [history, client])
    const logout = async () => {
        await Auth.signOut()
    }
    const isMaybeLoggedIn = !!Cache.getItem('federatedInfo')
    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                isMaybeLoggedIn,
                user: providerUser,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
