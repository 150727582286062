import React, { useState, useCallback, useContext, useRef, useEffect, useMemo } from 'react'
import { useCollectionAssetsDeleteMutation } from '../../graphql/useCollectionAssets'
import Dialog, { DialogModal } from '../../components/Dialog'
import { useKey } from 'react-use'
import { CollectionDetailContext } from './CollectionDetail'
import MultiSelectContainer, { MultiSelectContext } from '../../components/MultiSelectContainer'
import { Lightbox, LightboxProvider, getMedia, MediaType } from 'vo-components'
import { CollectionAssetsSegmentContext } from './CollectionAssetsSegment'
import { classes, stylesheet } from 'typestyle'
import { em } from 'csx'

const styles = stylesheet({
    lightboxActionsContainer: {
        marginTop: em(2),
    },
})

interface FeatureWrapperProps {
    enabled: boolean
    children: React.ReactNode
}

function LightboxDeleteAction() {
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const { assets } = useContext(CollectionAssetsSegmentContext)
    const { collection } = useContext(CollectionDetailContext)
    const { isOpen, activeIndex } = useContext(LightboxProvider)
    const assetType = getMedia(assets[activeIndex]?.file?.contentType || '')
    const [deleteCollectionAsset, { loading, error }] = useCollectionAssetsDeleteMutation({
        collection,
        collectionAssets: [assets[activeIndex]],
        onCompleted: () => {
            setIsDeleteDialogOpen(false)
        },
    })
    const confirmDelete = useCallback(() => isOpen && setIsDeleteDialogOpen(true), [isOpen])
    useKey(
        e => ['Backspace', 'Delete'].includes(e.key),
        () => isOpen && confirmDelete(),
        {},
        [confirmDelete, isOpen]
    )
    return (
        <>
            <button onClick={() => setIsDeleteDialogOpen(true)} className="button is-text">
                <span className="icon has-text-danger">
                    <i className="fa fa-trash" />
                </span>
            </button>
            <Dialog
                isOpen={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                onYes={() => deleteCollectionAsset().catch(console.error)}
                title={`Are you sure you want to delete this ${assetType === MediaType.IMAGE ? 'photo' : 'video'}?`}
                yesLabel={`Yes, delete this ${assetType === MediaType.IMAGE ? 'photo' : 'video'}`}
                noLabel="Cancel"
                loading={loading}
                error={error}
            />
        </>
    )
}

function LightboxActions() {
    const lightboxContext = useContext(LightboxProvider)
    if (!lightboxContext) return null
    return (
        <div className={classes('buttons is-centered', styles.lightboxActionsContainer)}>
            <LightboxDeleteAction />
        </div>
    )
}

/**
 * LightBox feature wrapper
 * any children wrapped with this feature has the ability to open lightbox
 */

export function LightBoxFeatureWrapper({ enabled, children }: FeatureWrapperProps) {
    const { assets } = useContext(CollectionAssetsSegmentContext)
    if (!enabled) return <>{children}</>
    return (
        <Lightbox actionComponent={<LightboxActions />} images={assets.map(asset => asset.file?.public_url || '').filter(url => !!url) || []}>
            {children}
        </Lightbox>
    )
}

/**
 * MultiSelect feature wrapper
 * any children wrapped with this feature has the ability to select multiple items and make a batch action from it
 */

export enum MultiSelectFeatureAction {
    DELETE,
    MOVE_STAGE,
    ZIP,
}

interface MultiSelectFeatureWrapperProps extends FeatureWrapperProps {
    actions: MultiSelectFeatureAction[]
}

export function MultiSelectFeatureWrapper({ enabled, children, actions }: MultiSelectFeatureWrapperProps) {
    if (!enabled) return <>{children}</>
    return (
        <MultiSelectContainer>
            <MultiSelectContent actions={actions}>{children}</MultiSelectContent>
        </MultiSelectContainer>
    )
}

interface MultiSelectContentProps {
    actions: MultiSelectFeatureAction[]
    children: React.ReactNode
}

function MultiSelectContent({ actions, children }: MultiSelectContentProps) {
    const { assets } = useContext(CollectionAssetsSegmentContext)
    const { selectedItems, setSelectedItems } = useContext(MultiSelectContext)
    return (
        <>
            {selectedItems.length > 0 && (
                <p className="buttons">
                    {actions.includes(MultiSelectFeatureAction.DELETE) && <MultiSelectDeleteAction />}
                    {selectedItems.length < assets.length ? (
                        <button
                            onClick={() => {
                                setSelectedItems(assets.map(asset => asset.asset_id))
                            }}
                            className="button is-info"
                        >
                            <span className="icon is-small">
                                <i className="fas fa-object-group" />
                            </span>
                            <span>Select all</span>
                        </button>
                    ) : (
                        <button
                            onClick={() => {
                                setSelectedItems([])
                            }}
                            className="button"
                        >
                            <span className="icon is-small">
                                <i className="fas fa-object-ungroup" />
                            </span>
                            <span>De-select all</span>
                        </button>
                    )}
                </p>
            )}
            {children}
        </>
    )
}

function MultiSelectDeleteAction() {
    const componentIsMounted = useRef(true)
    useEffect(() => {
        return () => {
            componentIsMounted.current = false
        }
    }, [])
    const { assets } = useContext(CollectionAssetsSegmentContext)
    const { collection } = useContext(CollectionDetailContext)
    const { selectedItems, setSelectedItems } = useContext(MultiSelectContext)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const selectedAssets = useMemo(() => assets.filter(asset => selectedItems.includes(asset.asset_id)), [assets, selectedItems])
    const [deleteCollectionAssets, { loading, error }] = useCollectionAssetsDeleteMutation({
        collection,
        collectionAssets: selectedAssets,
        onCompleted: () => {
            if (componentIsMounted.current) {
                setIsDeleteDialogOpen(false)
                setSelectedItems([])
            }
        },
    })
    const confirmDelete = useCallback(() => selectedItems.length > 0 && setIsDeleteDialogOpen(true), [selectedItems.length])
    useKey(e => ['Backspace', 'Delete'].includes(e.key), confirmDelete, {}, [confirmDelete])
    return (
        <>
            <DialogModal
                isOpen={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                onYes={() => deleteCollectionAssets().catch(console.error)}
                title={`Are you sure you want to delete these (${selectedItems.length}) photos?`}
                subtitle="You won't be able to recover them"
                yesLabel="Yes, delete these photos"
                noLabel="Cancel"
                loading={loading}
                error={error}
            />
            <button onClick={confirmDelete} className="button is-danger">
                <span className="icon is-small">
                    <i className="fas fa-trash" />
                </span>
                <span>Delete Photos</span>
            </button>
        </>
    )
}
