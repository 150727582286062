import { useMutation, useLazyQuery } from '@apollo/client'
import {
    CREATE_COLLECTION_ASSET,
    DELETE_COLLECTION_ASSETS,
    SORT_COLLECTION_ASSET,
    SHOW_SLUG,
    LIST_COLLECTION_ASSETS,
    Mutation,
    MutationCreateCollectionAssetArgs,
    Maybe,
    Slug,
    Collection,
    QueryShowSlugArgs,
    SlugCollection,
    CollectionAssetConnection,
    CollectionAsset,
    MutationDeleteCollectionAssetsArgs,
    MutationSortCollectionAssetArgs,
    SlugType,
    QueryListCollectionAssetsArgs,
    CollectionAssetType,
    CollectionSegmentsArgs,
} from 'vo-components'
import { FileUpload } from '../models/FileModel'
import { generateNextSortKey } from '../util/sortKey'
import { MutationArgs, baseFile } from './customTypes'
import groupBy from 'lodash.groupby'
import { COLLECTION_ASSETS_LIMIT } from './useCollections'

interface CollectionAssetsCreateMutationArgs extends MutationArgs {
    file: FileUpload
    collection: Collection
    latestSortKey?: string
    type: CollectionAssetType
}

export function useCollectionAssetsCreateMutation({
    file,
    type,
    collection,
    latestSortKey,
    onError,
    onCompleted,
}: CollectionAssetsCreateMutationArgs) {
    const sortKey = generateNextSortKey(latestSortKey)
    return useMutation<Partial<Mutation>, MutationCreateCollectionAssetArgs>(CREATE_COLLECTION_ASSET, {
        onError,
        onCompleted,
        variables: {
            input: {
                collection_id: collection.collection_id,
                customer_id: collection.customer_id,
                type,
                sort_key: sortKey,
            },
        },
        update: (cache, { data: newData }) => {
            if (!newData?.createCollectionAsset || !collection.slug) return
            try {
                const cacheQuery = {
                    query: SHOW_SLUG,
                    variables: {
                        type: SlugType.Collection,
                        slug: collection.slug,
                        types: collection.segments?.map(segment => segment.type),
                        limit: COLLECTION_ASSETS_LIMIT,
                    },
                }
                const data = cache.readQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs & CollectionSegmentsArgs>({
                    ...cacheQuery,
                })
                if (!data?.showSlug) return

                const slugValue = data.showSlug.value as SlugCollection
                cache.writeQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs & CollectionSegmentsArgs>({
                    ...cacheQuery,
                    data: {
                        ...data,
                        showSlug: {
                            ...data?.showSlug,
                            value: {
                                ...slugValue,
                                collection: {
                                    ...(slugValue.collection as Collection),
                                    segments: slugValue.collection?.segments?.map(segment =>
                                        segment?.type === type
                                            ? {
                                                  ...segment,
                                                  assets: {
                                                      ...segment.assets,
                                                      items: [
                                                          {
                                                              ...(newData.createCollectionAsset as CollectionAsset),
                                                              file: {
                                                                  ...baseFile,
                                                                  public_url: file.file && URL.createObjectURL(file.file),
                                                                  contentType: file.file?.type,
                                                                  is_ready: true,
                                                              },
                                                          },
                                                          ...(segment.assets?.items || []),
                                                      ],
                                                  },
                                              }
                                            : segment
                                    ),
                                },
                            },
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useCollectionAssetsCreateMutation`')
            }
        },
    })
}

interface CollectionAssetsDeleteMutationArgs extends MutationArgs {
    collection: Collection
    collectionAssets: CollectionAsset[]
}

export function useCollectionAssetsDeleteMutation({ collection, collectionAssets, onCompleted }: CollectionAssetsDeleteMutationArgs) {
    const assetIds = collectionAssets.map(asset => asset.asset_id)
    return useMutation<Partial<Mutation>, MutationDeleteCollectionAssetsArgs>(DELETE_COLLECTION_ASSETS, {
        onCompleted,
        variables: {
            asset_ids: assetIds,
        },
        update: (cache, { data: deletedCollectionAsset }) => {
            /**
             * TODO: fix this
             * Known Bug:
             * The cache only updates the first pagination.
             * When user clicks "load more" (which fires the function from `useCollectionAssetsLazyQuery`),
             * The cache won't update properly.
             * This is because essentially the assets are in a different cache.
             *
             * For the moment, I increased the limit on collection assets to 500 (which is not ideal. I know.)
             */
            if (!deletedCollectionAsset || !collection.slug) return
            const deletedAssetIds = (deletedCollectionAsset.deleteCollectionAssets || []).map(asset => asset?.asset_id)
            const groupedAssetTypes = Object.keys(groupBy(collectionAssets, 'type'))
            try {
                const cacheQuery = {
                    query: SHOW_SLUG,
                    variables: {
                        type: SlugType.Collection,
                        slug: collection.slug,
                        types: collection.segments?.map(segment => segment.type),
                        limit: COLLECTION_ASSETS_LIMIT,
                    },
                }
                const data = cache.readQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs & CollectionSegmentsArgs>({
                    ...cacheQuery,
                })
                if (!data?.showSlug) return
                const slugValue = data.showSlug.value as SlugCollection
                cache.writeQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs & CollectionSegmentsArgs>({
                    ...cacheQuery,
                    data: {
                        ...data,
                        showSlug: {
                            ...data.showSlug,
                            value: {
                                ...slugValue,
                                collection: {
                                    ...(slugValue.collection as Collection),
                                    segments: slugValue.collection?.segments?.map(segment =>
                                        groupedAssetTypes.includes(segment.type)
                                            ? {
                                                  ...segment,
                                                  assets: {
                                                      ...segment.assets,
                                                      items: (segment.assets?.items || []).filter(
                                                          collectionAssetItem => !deletedAssetIds.includes(collectionAssetItem.asset_id)
                                                      ),
                                                  },
                                              }
                                            : segment
                                    ),
                                },
                            },
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useCollectionAssetsDeleteMutation`')
            }
        },
    })
}

interface CollectionAssetsSortMutationArgs extends MutationArgs {
    collection: Collection
    collectionAsset: CollectionAsset
    sortKey: string
}

export function useCollectionAssetsSortMutation({ collection, collectionAsset, sortKey }: CollectionAssetsSortMutationArgs) {
    return useMutation<Partial<Mutation>, MutationSortCollectionAssetArgs>(SORT_COLLECTION_ASSET, {
        variables: {
            asset_id: collectionAsset.asset_id,
            sort_key: sortKey,
        },
        update: (cache, { data: newData }) => {
            if (!newData?.sortCollectionAsset || !collection.slug) return
            try {
                const cacheQuery = {
                    query: SHOW_SLUG,
                    variables: {
                        type: SlugType.Collection,
                        slug: collection.slug,
                        types: collection.segments?.map(segment => segment.type),
                        limit: COLLECTION_ASSETS_LIMIT,
                    },
                }
                const data = cache.readQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs & CollectionSegmentsArgs>({
                    ...cacheQuery,
                })
                if (!data?.showSlug) return
                const slugValue = data.showSlug.value as SlugCollection
                cache.writeQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs & CollectionSegmentsArgs>({
                    ...cacheQuery,
                    data: {
                        ...data,
                        showSlug: {
                            ...data.showSlug,
                            value: {
                                ...slugValue,
                                collection: {
                                    ...(slugValue.collection as Collection),
                                    segments: slugValue.collection?.segments?.map(segment =>
                                        segment?.type === collectionAsset.type
                                            ? {
                                                  ...segment,
                                                  assets: {
                                                      ...segment.assets,
                                                      items: (segment.assets?.items || []).map(collectionAssetItem =>
                                                          collectionAssetItem.asset_id === collectionAsset.asset_id
                                                              ? {
                                                                    ...collectionAsset,
                                                                    ...newData.sortCollectionAsset,
                                                                }
                                                              : collectionAssetItem
                                                      ),
                                                  },
                                              }
                                            : segment
                                    ),
                                },
                            },
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useCollectionAssetsSortMutation`')
            }
        },
        optimisticResponse: {
            __typename: 'Mutation',
            sortCollectionAsset: {
                __typename: 'CollectionAsset',
                ...collectionAsset,
                sort_key: sortKey,
            },
        },
    })
}

export function useCollectionAssetsLazyQuery() {
    return useLazyQuery<{ listCollectionAssets: Maybe<CollectionAssetConnection> }, QueryListCollectionAssetsArgs>(LIST_COLLECTION_ASSETS)
}
