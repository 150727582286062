import React, { useRef, useState, useMemo, useCallback, useEffect, useContext } from 'react'
import { useDrop, useDrag } from 'react-dnd'
import { LexoRank } from 'lexorank'

export interface Sortable {
    placeholder?: boolean
    previousSortKey?: string
    nextSortKey?: string
}

interface OnDragArgs<T extends Sortable> {
    placeholderIndex: number
    item?: T
}

export function useSortable<T extends Sortable>(items: T[] = []) {
    const [sortedItems, setSortedItems] = useState(items)
    const onDrag = useCallback(
        ({ placeholderIndex, item }: OnDragArgs<T>) => {
            if (placeholderIndex === -1) {
                setSortedItems(items)
            } else if (item) {
                const tempItems = [...items]
                tempItems.splice(placeholderIndex, 0, { placeholder: true, ...item })
                setSortedItems(sortedItems => {
                    if (sortedItems[placeholderIndex]?.placeholder) return sortedItems
                    return tempItems
                })
            }
        },
        [items]
    )
    useEffect(() => {
        setSortedItems(items)
    }, [items])
    return { onDrag, sortedItems }
}

interface SortKeyArgs {
    originalSortKey: string
    previousSortKey?: string
    nextSortKey?: string
    skip?: boolean
}

export function useSortKey({ originalSortKey, previousSortKey, nextSortKey, skip }: SortKeyArgs): string {
    const sortKey = useMemo(() => {
        if (skip) return originalSortKey
        try {
            if (previousSortKey && nextSortKey) {
                return LexoRank.parse(previousSortKey)
                    .between(LexoRank.parse(nextSortKey))
                    .toString()
            }
            if (!previousSortKey && nextSortKey) {
                return LexoRank.parse(nextSortKey)
                    .genPrev()
                    .toString()
            }
            if (!nextSortKey && previousSortKey) {
                return LexoRank.parse(previousSortKey)
                    .genNext()
                    .toString()
            }
            return LexoRank.middle().toString()
        } catch (e) {
            console.error(e)
        }
        return originalSortKey
    }, [originalSortKey, nextSortKey, previousSortKey, skip])
    return sortKey
}

interface SortableContext<T extends Sortable> {
    type: string
    onDrag: (args: OnDragArgs<T>) => void
}

const SortableContext = React.createContext<SortableContext<any>>({} as SortableContext<any>)

interface SortableProviderProps<T> extends SortableContext<T> {
    children: React.ReactNode
}

export function SortableProvider<T extends Sortable>({ type, onDrag, children }: SortableProviderProps<T>) {
    return (
        <SortableContext.Provider
            value={{
                type,
                onDrag,
            }}
        >
            {children}
        </SortableContext.Provider>
    )
}

interface SortItem<T extends Sortable> {
    id: string
    index: number
    originalItem: T
}

interface SortItemWithType<T> extends SortItem<T> {
    type: string
}

interface SortableWrapper {
    children: (ref: React.RefObject<any>) => React.ReactNode
}

interface SortableItemProps<T extends Sortable> extends SortItem<T>, SortableWrapper {}

export function SortableItem<T extends Sortable>({ id, index, originalItem, children }: SortableItemProps<T>) {
    const { onDrag, type } = useContext<SortableContext<T>>(SortableContext)
    const ref = useRef<HTMLElement>(null)
    const [, drop] = useDrop<SortItemWithType<T>, any, any>({
        accept: type,
        hover: (item, monitor) => {
            if (!ref.current || item.id === id) {
                return
            }
            const clientOffset = monitor.getClientOffset()
            const hoverBoundingRect = ref.current.getBoundingClientRect()
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
            const hoverClientX = (clientOffset?.x || 0) - hoverBoundingRect.left
            if (hoverMiddleX >= hoverClientX) {
                // set replace current index
                onDrag({ placeholderIndex: index, item: item.originalItem })
            } else {
                // set to replace next index
                onDrag({ placeholderIndex: index + 1, item: item.originalItem })
            }
        },
    })
    const [{ isDragging }, drag] = useDrag<SortItemWithType<T>, any, any>({
        item: {
            type,
            id,
            index,
            originalItem,
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        end: () => {
            onDrag({ placeholderIndex: -1 })
        },
    })
    drag(drop(ref))
    return <React.Fragment>{!isDragging && children(ref)}</React.Fragment>
}

interface DragPlaceholderProps extends SortableWrapper {
    mutate: () => void
}

export function DragPlaceholder({ mutate, children }: DragPlaceholderProps) {
    const { onDrag, type } = useContext(SortableContext)
    const ref = useRef(null)
    const [, drop] = useDrop({
        accept: type,
        drop() {
            onDrag({ placeholderIndex: -1 })
            mutate()
        },
    })
    drop(ref)
    return <React.Fragment>{children(ref)}</React.Fragment>
}
