import React, { useContext } from 'react'
import { Modal, Mutation, Customer, Collection } from 'vo-components'
import { classes, stylesheet } from 'typestyle'
import { em, viewWidth, percent } from 'csx'
import { useCollectionsUpdateMutation, useCollectionsCreateMutation } from '../../graphql/useCollections'
import { BasicModalProps, ModalInnerFormProps, ModalFormProps, ModalFormMode } from '../../models/ModalModel'
import { useFormContext, useForm, FormContext } from 'react-hook-form'

interface CustomerSessionModalContext extends BasicModalProps {
    customer: Customer
    onCompleted: (data: Partial<Mutation>) => void
}

const CustomerSessionModalContext = React.createContext<CustomerSessionModalContext>({} as CustomerSessionModalContext)

interface CustomerSessionFormModel {
    title: string
    hourlyPrice?: string
    duration?: string
    description?: string
}

const styles = stylesheet({
    modal: {
        minWidth: viewWidth(70),
    },
    container: {
        height: percent(100),
        padding: em(2),
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    formContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    formControls: {
        marginTop: em(0.5),
    },
    submitInnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
})

function CustomerSessionForm({ write, loading, error }: ModalInnerFormProps) {
    const { isOpen, onClose, customer } = useContext(CustomerSessionModalContext)
    const { register, handleSubmit } = useFormContext()
    return (
        <Modal isOpen={isOpen} onClose={() => onClose()} className={styles.modal}>
            <div className={classes('columns', 'is-marginless', styles.container)}>
                <div className={classes('column', styles.innerContainer)}>
                    <form
                        onSubmit={handleSubmit(() => {
                            write().catch(console.error)
                        })}
                        className={classes(styles.formContainer)}
                    >
                        <div>
                            <h4 className="is-size-4 title">
                                Session information for <u>{customer.name}</u>
                            </h4>
                            <p className="is-size-6 subtitle">Your client&apos;s session information. Each session would have its own gallery.</p>
                            <div className="columns is-multiline">
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Title</label>
                                        <div className="control">
                                            <input
                                                name="title"
                                                ref={register({ required: true })}
                                                className="input"
                                                type="text"
                                                placeholder="Session name (e.g. 2 September 2020)"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Hourly Price</label>
                                        <div className="control has-icons-left">
                                            <input
                                                name="hourlyPrice"
                                                ref={register}
                                                className="input"
                                                type="number"
                                                placeholder="Hourly price which you charged this session for (in AUD)"
                                            />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-dollar-sign fa-xs" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Duration</label>
                                        <div className="control has-icons-right">
                                            <input
                                                style={{ paddingRight: '4em' }}
                                                name="duration"
                                                ref={register}
                                                className="input"
                                                type="number"
                                                placeholder="Duration of the session (in hours)"
                                            />
                                            <span style={{ width: '4em' }} className="icon is-large is-right">
                                                hours
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-full">
                                    <div className="field">
                                        <label className="label">Description</label>
                                        <div className="control">
                                            <textarea
                                                name="description"
                                                ref={register}
                                                className="textarea"
                                                placeholder="Optional note about this session"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.formControls}>
                            <div className="columns">
                                <div className={classes('column is-narrow', styles.submitInnerContainer)}>
                                    {error && <p className="has-text-danger">Whoops! Something went wrong</p>}
                                </div>
                                <div className="column">
                                    <div className="field is-grouped is-grouped-right">
                                        <p className="control">
                                            <button type="button" onClick={() => onClose()} className="button is-light">
                                                Cancel
                                            </button>
                                        </p>
                                        <p className="control">
                                            <button type="submit" className={classes('button is-primary', loading && 'is-loading')}>
                                                Submit
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

interface CustomerSessionUpdateProps {
    customerSession: Collection
}

function ModalCustomerSessionUpdate({ customerSession }: CustomerSessionUpdateProps) {
    const { onCompleted, customer } = useContext(CustomerSessionModalContext)
    const { watch } = useFormContext<CustomerSessionFormModel>()
    const { title, hourlyPrice, duration, description } = watch({ nest: true })
    const [update, { loading, error }] = useCollectionsUpdateMutation({
        cacheHandlerKey: 'CUSTOMER_SESSION',
        customer,
        collection: customerSession,
        title,
        description,
        duration_in_hour: duration,
        hourly_price: hourlyPrice,
        listing_status: 'CUSTOMER',
        onCompleted,
    })
    return <CustomerSessionForm write={update} loading={loading} error={error} />
}

interface CustomerSessionCreateProps {
    latestSortKey?: string
}

function ModalCustomerSessionCreate({ latestSortKey }: CustomerSessionCreateProps) {
    const { onCompleted, customer } = useContext(CustomerSessionModalContext)
    const { watch } = useFormContext<CustomerSessionFormModel>()
    const { title, hourlyPrice, duration, description } = watch({ nest: true })
    const [create, { loading, error }] = useCollectionsCreateMutation({
        cacheHandlerKey: 'CUSTOMER_SESSION',
        customer,
        title,
        description,
        duration_in_hour: duration,
        hourly_price: hourlyPrice,
        latestSortKey,
        listing_status: 'CUSTOMER',
        onCompleted,
    })
    return <CustomerSessionForm write={create} loading={loading} error={error} />
}

interface ModalCustomerSessionProps extends BasicModalProps, ModalFormProps {
    customerSession?: Collection
    customer: Customer
    latestSortKey?: string
}

export default function ModalCustomerSession({ mode, isOpen, onClose, customerSession, customer, latestSortKey }: ModalCustomerSessionProps) {
    const formMethods = useForm<CustomerSessionFormModel>({
        submitFocusError: true,
        validateCriteriaMode: 'all',
        defaultValues: {
            title: customerSession?.title || '',
            duration: customerSession?.duration_in_hour || '',
            hourlyPrice: customerSession?.hourly_price || '',
            description: customerSession?.description || '',
        },
    })
    const onCompleted = async () => {
        const form = formMethods.getValues()
        formMethods.reset(mode === ModalFormMode.UPDATE ? form : undefined)
        onClose()
    }

    return (
        <CustomerSessionModalContext.Provider
            value={{
                isOpen,
                onClose,
                onCompleted,
                customer,
            }}
        >
            <FormContext {...formMethods}>
                {mode === ModalFormMode.CREATE && <ModalCustomerSessionCreate latestSortKey={latestSortKey} />}
                {mode === ModalFormMode.UPDATE && customerSession && <ModalCustomerSessionUpdate customerSession={customerSession} />}
            </FormContext>
        </CustomerSessionModalContext.Provider>
    )
}
