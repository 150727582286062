import React, { useEffect, useState, useCallback, useMemo, useContext } from 'react'
import { Hub } from 'aws-amplify'
import { classes, stylesheet } from 'typestyle'
import { em, percent } from 'csx'
import { zIndex } from '../../util/themes'
import Circle from 'react-circle'
import Dialog from '../../components/Dialog'
import { Link } from 'react-router-dom'
import { useCollectionAssetsCreateMutation } from '../../graphql/useCollectionAssets'
import { useEffectOnce } from 'react-use'
import { useUploader } from '../../components/useUploader'
import { Collection, CollectionAsset, CollectionAssetType, getMedia, MediaType } from 'vo-components'
import first from 'lodash.first'
import { HubCallback } from '@aws-amplify/core/lib-esm/Hub'
import { getCollectionAssetsBySegment } from '../collection-asset-list/CollectionAssetsSegment'

export const CHANNELS = {
    QUEUE_UPLOAD: 'QUEUE_UPLOAD',
}

enum StatusCode {
    PENDING,
    IN_PROGRESS,
    FINISHED,
}

interface UploadItemStatus {
    statusCode: StatusCode
    error?: any
}

interface AssetUploadItem {
    file: File
    collection: Collection
    status: UploadItemStatus
    photoType: CollectionAssetType
    videoType: CollectionAssetType
    disablePrefix?: boolean
}

export interface AssetPayload {
    files: File[]
    collection: Collection
    photoType: CollectionAssetType
    videoType: CollectionAssetType
    disablePrefix?: boolean
}

interface LatestSortKeyMap {
    [key: string]: string | undefined
}

interface FinishUploadArgs {
    finishedItem: AssetUploadItemWithIndex
    sortKey: string
    error?: Error
}
type FinishUploadFnSignature = (args: FinishUploadArgs) => void

interface CollectionAssetsUploaderContext {
    items: AssetUploadItem[]
    activeIndex: number
    latestPhotoSortKeyMap: LatestSortKeyMap
    latestVideoSortKeyMap: LatestSortKeyMap
    finishUpload: FinishUploadFnSignature
    cancelUploads: () => void
}

const CollectionAssetsUploaderContext = React.createContext<CollectionAssetsUploaderContext>({} as CollectionAssetsUploaderContext)

const styles = stylesheet({
    container: {
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        flexDirection: 'row-reverse',
        pointerEvents: 'none',
        width: percent(100),
        overflow: 'auto',
        zIndex: zIndex.UPLOAD_BOX,
    },
    uploadBoxMasterHeader: {
        position: 'sticky',
        top: 0,
    },
    uploadBoxContainer: {
        position: 'relative',
        marginBottom: em(2),
        marginRight: em(2),
        pointerEvents: 'auto',
        width: 362,
        minWidth: 362,
        maxHeight: 323,
        overflow: 'auto',
        $nest: {
            '&::-webkit-scrollbar': {
                width: 10,
                backgroundColor: '#F5F5F5',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#FF9900',
            },
        },
    },
    uploadBoxFileItemContainer: {
        padding: em(1),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    fileIndicator: {
        minWidth: 24,
    },
})

interface CollectionFileItemProps {
    item: AssetUploadItemWithIndex
}
// NOTE: Be wary of infinite loop here..
function CollectionFileItem({ item }: CollectionFileItemProps) {
    const { activeIndex, latestPhotoSortKeyMap, latestVideoSortKeyMap, finishUpload } = useContext(CollectionAssetsUploaderContext)
    const { index, file, status, disablePrefix } = item
    const mediaType = useMemo(() => getMedia(file.type || ''), [file.type])
    const latestSortKey = useMemo<string>(() => {
        if (index !== activeIndex) return ''
        if (mediaType === MediaType.IMAGE) return latestPhotoSortKeyMap[item.collection.collection_id] || ''
        if (mediaType === MediaType.VIDEO) return latestVideoSortKeyMap[item.collection.collection_id] || ''
        return new Date().toISOString()
    }, [latestPhotoSortKeyMap, latestVideoSortKeyMap, item.collection.collection_id, mediaType, activeIndex, index])

    const collectionAssetType = useMemo<CollectionAssetType>(() => {
        if (mediaType === MediaType.IMAGE) return item.photoType
        if (mediaType === MediaType.VIDEO) return item.videoType
        return CollectionAssetType.Image
    }, [mediaType, item.photoType, item.videoType])
    const [uploadedAsset, setUploadedAsset] = useState<CollectionAsset | undefined>()

    const finishUploadCallback = useCallback(
        (e?: Error) => {
            finishUpload({
                finishedItem: item,
                sortKey: uploadedAsset?.sort_key || '',
                error: e,
            })
        },
        [item, uploadedAsset, finishUpload]
    )

    const { upload, uploadProgress, called } = useUploader({
        onCompleted: finishUploadCallback,
        onError: finishUploadCallback,
    })

    const [createCollectionAsset] = useCollectionAssetsCreateMutation({
        file: { file: item.file },
        type: collectionAssetType,
        collection: item.collection,
        latestSortKey,
        onError: () => {
            finishUpload({
                finishedItem: item,
                sortKey: '',
                error: new Error('Failed to create collection asset'),
            })
        },
        onCompleted: async data => {
            setUploadedAsset(data.createCollectionAsset || undefined)
        },
    })
    useEffect(() => {
        if (activeIndex === index && !called) {
            createCollectionAsset()
        }
    }, [createCollectionAsset, activeIndex, index, called])
    useEffect(() => {
        // [IMPORTANT] Need to check for `called`. Otherwise, it will trigger an infinite loop.
        // This is because we are updating items when it finished.
        // When items are updated, it will trigger an update to `finishUploadCallback` above;
        // and it will update the `upload` method which is a dependency for this useEffect.
        if (uploadedAsset && !called) {
            upload({
                handler: 'CollectionAsset',
                id: uploadedAsset.asset_id,
                file,
                access_level: 'public',
                folderPrefix: uploadedAsset.collection_id || undefined,
                disablePrefix,
            })
        }
    }, [uploadedAsset, upload, file, called])

    return (
        <div data-testid={`${item.collection.collection_id}-upload-panel`} className={classes('panel-block', styles.uploadBoxFileItemContainer)}>
            <div className="level is-marginless">
                <span className="panel-icon">
                    <i className={`fas ${file.type.startsWith('image') ? 'fa-image' : 'fa-video'}`} />
                </span>
                {file.name}
            </div>
            <div className={styles.fileIndicator}>
                <CollectionFilesItemIndicator status={status} uploadProgress={uploadProgress} />
            </div>
        </div>
    )
}

interface CollectionFilesItemIndicatorProps {
    status: UploadItemStatus
    uploadProgress?: number
}

function CollectionFilesItemIndicator({ status, uploadProgress }: CollectionFilesItemIndicatorProps) {
    if (!status) return null
    if (status.statusCode === StatusCode.PENDING || status.statusCode === StatusCode.IN_PROGRESS) {
        return (
            <Circle
                animate={false}
                progress={status.statusCode === StatusCode.PENDING || !uploadProgress ? 0 : uploadProgress}
                size="20"
                showPercentage={false}
                lineWidth="35"
            />
        )
    }
    if (status.error) {
        return (
            <span className="icon has-text-danger" title={status.error}>
                <i className="fas fa-times" />
            </span>
        )
    }
    return (
        <span className="icon has-text-success">
            <i className="fas fa-check" />
        </span>
    )
}

interface CollectionFilesProps {
    collectionFiles: CollectionsUploadItem
}

function CollectionFiles({ collectionFiles }: CollectionFilesProps) {
    const [isShown, setIsShown] = useState(true)
    if (!collectionFiles) return null
    const { collection, items } = collectionFiles
    return (
        <>
            <header className="card-header level is-marginless has-background-dark">
                <p className="card-header-title has-text-light">
                    {items.length} files for&nbsp;
                    {collection.portfolio_section ? (
                        <Link className="has-text-white" to={`/portfolio/${collection.portfolio_section.slug}/${collection.slug}`}>
                            <u>{collection.title}</u>
                        </Link>
                    ) : (
                        <span>{collection.title}</span>
                    )}
                </p>
                <button onClick={() => setIsShown(isShown => !isShown)} className="button is-dark is-rounded">
                    <span className="icon">
                        <i className={`fas ${isShown ? 'fa-angle-down' : 'fa-angle-up'}`} />
                    </span>
                </button>
            </header>
            {isShown && (
                <div className="panel is-marginless">
                    {items.map((item, index) => (
                        <CollectionFileItem key={`collection-asset-file-list-${collection.collection_id}-${index}`} item={item} />
                    ))}
                </div>
            )}
        </>
    )
}

function useCollectionAssetsUploader(): CollectionAssetsUploaderContext {
    const [items, setItems] = useState<AssetUploadItem[]>([])
    const [latestVideoSortKeyMap, setLatestVideoSortKeyMap] = useState<LatestSortKeyMap>({})
    const [latestPhotoSortKeyMap, setLatestPhotoSortKeyMap] = useState<LatestSortKeyMap>({})
    const [activeIndex, setActiveIndex] = useState<number>(-1)

    const cancelUploads = useCallback(() => {
        setItems([])
        setLatestPhotoSortKeyMap({})
        setLatestVideoSortKeyMap({})
        setActiveIndex(-1)
    }, [])

    const finishUpload = useCallback<FinishUploadFnSignature>(({ finishedItem, sortKey, error }) => {
        setItems(items =>
            items.map((collectionFile, index) => {
                if (index !== finishedItem.index) return collectionFile
                return {
                    ...collectionFile,
                    status: {
                        statusCode: StatusCode.FINISHED,
                        error: error?.message,
                    },
                }
            })
        )
        if (error) return
        if (getMedia(finishedItem.file.type) === MediaType.IMAGE) {
            setLatestPhotoSortKeyMap(latestPhotoSortKeyMap => ({
                ...latestPhotoSortKeyMap,
                [finishedItem.collection.collection_id]: sortKey,
            }))
        } else if (getMedia(finishedItem.file.type) === MediaType.VIDEO) {
            setLatestVideoSortKeyMap(latestVideoSortKeyMap => ({
                ...latestVideoSortKeyMap,
                [finishedItem.collection.collection_id]: sortKey,
            }))
        }
        setActiveIndex(finishedItem.index + 1)
    }, [])

    // Listen for dispatched files to be uploaded
    useEffectOnce(() => {
        const hubCallback: HubCallback = ({ payload: { data } }) => {
            const assetPayload = data as AssetPayload
            setItems(items => [
                ...items,
                ...assetPayload.files.map<AssetUploadItem>(file => ({
                    file,
                    collection: assetPayload.collection,
                    photoType: assetPayload.photoType,
                    videoType: assetPayload.videoType,
                    disablePrefix: assetPayload.disablePrefix,
                    status: {
                        statusCode: StatusCode.PENDING,
                        error: undefined,
                    },
                })),
            ])
            // if no upload in progress, upload the first item
            if (assetPayload.files.length > 0) {
                setLatestPhotoSortKeyMap(latestPhotoSortKeyMap => {
                    if (latestPhotoSortKeyMap[assetPayload.collection.collection_id]) return latestPhotoSortKeyMap
                    return {
                        ...latestPhotoSortKeyMap,
                        [assetPayload.collection.collection_id]:
                            first(getCollectionAssetsBySegment(assetPayload.collection, assetPayload.photoType).items)?.sort_key || undefined,
                    }
                })
                setLatestVideoSortKeyMap(latestVideoSortKeyMap => {
                    if (latestVideoSortKeyMap[assetPayload.collection.collection_id]) return latestVideoSortKeyMap
                    return {
                        ...latestVideoSortKeyMap,
                        [assetPayload.collection.collection_id]:
                            first(getCollectionAssetsBySegment(assetPayload.collection, assetPayload.videoType).items)?.sort_key || undefined,
                    }
                })
                setActiveIndex(activeIndex => (activeIndex === -1 ? 0 : activeIndex))
            }
        }
        Hub.listen(CHANNELS.QUEUE_UPLOAD, hubCallback)
        return () => {
            Hub.remove(CHANNELS.QUEUE_UPLOAD, hubCallback)
        }
    })

    // Upload file
    useEffect(() => {
        if (activeIndex === -1 || items.length === activeIndex) return
        setItems(items =>
            items.map((collectionFile, index) => {
                if (index !== activeIndex) return collectionFile
                return {
                    ...collectionFile,
                    status: {
                        statusCode: StatusCode.IN_PROGRESS,
                    },
                }
            })
        )
    }, [activeIndex, items.length])
    return { activeIndex, items, latestPhotoSortKeyMap, latestVideoSortKeyMap, finishUpload, cancelUploads }
}

interface TrackedIndex {
    index: number
}
interface AssetUploadItemWithIndex extends AssetUploadItem, TrackedIndex {}

interface CollectionsUploadItem {
    collection: Collection
    items: AssetUploadItemWithIndex[]
}

interface CollectionsUploadRegistry {
    [collection_id: string]: CollectionsUploadItem
}

export default function CollectionAssetsUploader() {
    const contextValue = useCollectionAssetsUploader()
    const { items, cancelUploads } = contextValue
    const collectionFiles = useMemo<CollectionsUploadRegistry>(
        () =>
            items.reduce<CollectionsUploadRegistry>((value, item, index) => {
                if (!value[item.collection.collection_id]) {
                    value[item.collection.collection_id] = {
                        collection: item.collection,
                        items: [],
                    }
                }
                value[item.collection.collection_id].items = [
                    ...value[item.collection.collection_id].items,
                    {
                        ...item,
                        index,
                    },
                ]
                return value
            }, {}),
        [items]
    )
    const completedUploads = useMemo(
        () =>
            items.reduce((value, item) => {
                if (item.status.statusCode === StatusCode.FINISHED) {
                    return value + 1
                }
                return value
            }, 0),
        [items]
    )
    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
    if (items.length === 0) return null
    return (
        <CollectionAssetsUploaderContext.Provider value={contextValue}>
            <div className={styles.container}>
                <div className={classes('card', styles.uploadBoxContainer)}>
                    <header className={classes('card-header level is-marginless has-background-black-bis', styles.uploadBoxMasterHeader)}>
                        <p className="card-header-title has-text-light">
                            Uploading {items.length} items... ({completedUploads} completed)
                        </p>
                        <button
                            onClick={() => {
                                if (completedUploads === items.length) {
                                    cancelUploads()
                                } else {
                                    setIsCancelDialogOpen(true)
                                }
                            }}
                            className="button is-rounded is-dark has-background-black-bis"
                        >
                            <span className="icon">
                                <i className="fas fa-times" />
                            </span>
                        </button>
                    </header>
                    {Object.keys(collectionFiles).map(key => (
                        <CollectionFiles key={key} collectionFiles={collectionFiles[key]} />
                    ))}
                    <Dialog
                        isOpen={isCancelDialogOpen}
                        onClose={() => setIsCancelDialogOpen(false)}
                        onYes={() => cancelUploads()}
                        title="Are you sure you want to cancel the upload process?"
                        subtitle="Queued items will not be uploaded.."
                        yesLabel="Yes, cancel the upload"
                        noLabel="No"
                    />
                </div>
            </div>
        </CollectionAssetsUploaderContext.Provider>
    )
}
