import React, { useState, useContext } from 'react'
import { classes, stylesheet } from 'typestyle'
import Dialog from '../../components/Dialog'
import { DropdownMenu, getMedia, MediaType, Theme, PortfolioSection, Collection, SlugType, Customer } from 'vo-components'
import ModalCollection from './ModalCollection'
import { Link } from 'react-router-dom'
import ModalSlug from './ModalSlug'
import { useCollectionsDeleteMutation, useCollectionsSortMutation, CollectionCacheHandlerKey } from '../../graphql/useCollections'
import { useSortKey, DragPlaceholder, Sortable } from '../../components/Sortable'
import { useImmediateUploader } from '../../components/useUploader'
import { Thumbnail } from '../../models/ThumbnailModel'
import Media from '../../components/Media'
import { ModalFormMode } from '../../models/ModalModel'
import ModalCustomerSession from './ModalCustomerSession'
import { PageContext, PageKey } from '../../components/PageContext'
import ModalCustomerReview from './ModalCustomerReview'

const styles = stylesheet({
    imageOverlay: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Theme.colors.overlayDark,
    },
    imageActionButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
})

interface CollectionThumbnailProps extends Sortable, Thumbnail {
    cacheHandlerKey: CollectionCacheHandlerKey
    portfolioSection?: PortfolioSection
    customer?: Customer
    collection: Collection
    isPreviewOnly?: boolean

    // Because collection can be referred by any pages, we need to know the referrer
    // baseUrl will be used for navigation purposes
    baseUrl: string
}

interface CollectionThumbnailContext extends CollectionThumbnailProps {
    uploadProgress?: number
    outputUrl?: string
}

const CollectionThumbnailContext = React.createContext<CollectionThumbnailContext>({} as CollectionThumbnailContext)

function CustomerSessionContent() {
    const { collection, baseUrl = '' } = useContext(CollectionThumbnailContext)
    return (
        <>
            <div className="card">
                <div className="card-content">
                    <Link to={`${baseUrl}/${collection.slug}`}>
                        <p className="is-size-3 title">
                            <u>{collection.title}</u>
                        </p>
                    </Link>

                    {(collection.duration_in_hour || collection.hourly_price) && (
                        <p className="is-size-5 subtitle">
                            {collection.duration_in_hour && <span>${collection.hourly_price} / hr</span>}
                            {collection.duration_in_hour && collection.hourly_price && <span>&nbsp;&nbsp;&middot;&nbsp;&nbsp;</span>}
                            {collection.hourly_price && <span>{collection.duration_in_hour} hr(s)</span>}
                        </p>
                    )}
                    <p>
                        Downloaded: <strong>{collection.download_history?.length || 0}</strong> time(s)
                    </p>
                    <div className="content">{collection.description}</div>
                </div>
            </div>
        </>
    )
}

function DefaultCollectionContent() {
    const { collection, outputUrl, uploadProgress, isPreviewOnly, baseUrl = '' } = useContext(CollectionThumbnailContext)
    return (
        <figure className="image is-16by9">
            <Media
                file={collection.banner || undefined}
                title={`Banner for ${collection.title}`}
                videoPosterUrl={outputUrl}
                videoPosterUploadProgress={uploadProgress}
            />
            <div className={classes('is-overlay is-flex', styles.imageOverlay)}>
                {!isPreviewOnly ? (
                    <Link to={`${baseUrl}/${collection.slug}`}>
                        <h2 className="is-size-3 has-text-white title">{collection.title}</h2>
                    </Link>
                ) : (
                    <h5 className="is-size-3 has-text-white title">{collection.title}</h5>
                )}
            </div>
        </figure>
    )
}

export default function CollectionThumbnail(props: CollectionThumbnailProps) {
    const { cacheHandlerKey, portfolioSection, customer, collection, placeholder, previousSortKey, nextSortKey, isPreviewOnly, baseUrl = '' } = props
    const { pageKey } = useContext(PageContext)
    const [isModalSlugOpen, setIsModalSlugOpen] = useState(false)
    const [isModalCollectionOpen, setIsModalCollectionOpen] = useState(false)
    const [isModalReviewOpen, setIsModalReviewOpen] = useState(false)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [deleteCollection, { loading: deleteLoading, error: deleteError }] = useCollectionsDeleteMutation({
        cacheHandlerKey,
        portfolioSection,
        customer,
        collection,
    })
    const candidateSortKey = useSortKey({ originalSortKey: collection.sort_key || '', previousSortKey, nextSortKey, skip: !placeholder })
    const [sort] = useCollectionsSortMutation({ cacheHandlerKey, portfolioSection, collection, sortKey: candidateSortKey })
    const { uploadProgress, open, getInputProps, outputUrl } = useImmediateUploader({
        id: collection.collection_id,
        handler: 'CollectionVideoPoster',
        originalUrl: collection.banner?.poster || undefined,
        noClick: true,
    })
    const bannerMediaType = getMedia(collection.banner?.contentType || '')
    if (placeholder) {
        return <DragPlaceholder mutate={sort}>{ref => <div ref={ref} className="is-overlay" />}</DragPlaceholder>
    }
    return (
        <CollectionThumbnailContext.Provider value={{ ...props, uploadProgress, outputUrl }}>
            <ModalSlug
                type={SlugType.Collection}
                cacheHandlerKey={cacheHandlerKey}
                isOpen={isModalSlugOpen}
                onClose={() => setIsModalSlugOpen(false)}
                collection={collection}
                customer={customer}
                portfolioSection={portfolioSection}
                baseUrl={baseUrl}
            />
            {pageKey === PageKey.CUSTOMER_SESSION_LIST && customer && (
                <>
                    <ModalCustomerSession
                        mode={ModalFormMode.UPDATE}
                        isOpen={isModalCollectionOpen}
                        onClose={() => setIsModalCollectionOpen(false)}
                        customer={customer}
                        customerSession={collection}
                    />
                    <ModalCustomerReview isOpen={isModalReviewOpen} onClose={() => setIsModalReviewOpen(false)} collection={collection} />
                </>
            )}
            {pageKey !== PageKey.CUSTOMER_SESSION_LIST && (
                <ModalCollection
                    mode={ModalFormMode.UPDATE}
                    isOpen={isModalCollectionOpen}
                    onClose={() => setIsModalCollectionOpen(false)}
                    cacheHandlerKey={cacheHandlerKey}
                    portfolioSection={portfolioSection}
                    collection={collection}
                    listingStatus={collection.listing_status || 'PORTFOLIO'}
                />
            )}

            <div className="is-relative">
                {pageKey === PageKey.CUSTOMER_SESSION_LIST ? <CustomerSessionContent /> : <DefaultCollectionContent />}
                {!isPreviewOnly && (
                    <div className="is-overlay is-top-right">
                        <DropdownMenu>
                            <Link to={`${baseUrl}/${collection.slug}`} className="dropdown-item">
                                View Details
                            </Link>
                            {collection.customer_review && pageKey === PageKey.CUSTOMER_SESSION_LIST && (
                                <button onClick={() => setIsModalReviewOpen(true)} className="button is-success dropdown-item is-radiusless">
                                    See Review
                                </button>
                            )}
                            <button onClick={() => setIsModalCollectionOpen(true)} className="button is-white dropdown-item">
                                Edit
                            </button>
                            <button onClick={() => setIsModalSlugOpen(true)} className="button is-white dropdown-item">
                                Change URL
                            </button>
                            {bannerMediaType === MediaType.VIDEO && (
                                <>
                                    <input {...getInputProps()} />
                                    <button onClick={() => open()} className="button is-white dropdown-item">
                                        Upload video poster
                                    </button>
                                </>
                            )}
                            <button onClick={() => setIsDeleteDialogOpen(true)} className="button is-white dropdown-item has-text-danger">
                                Delete
                            </button>
                        </DropdownMenu>
                    </div>
                )}

                <Dialog
                    isOpen={isDeleteDialogOpen}
                    onClose={() => setIsDeleteDialogOpen(false)}
                    onYes={() => deleteCollection().catch(console.error)}
                    title="Are you sure you want to delete this collection?"
                    subtitle="All pictures and videos within this collection will be deleted as well..."
                    yesLabel="Yes, delete this collection"
                    noLabel="Cancel"
                    loading={deleteLoading}
                    error={deleteError}
                />
            </div>
        </CollectionThumbnailContext.Provider>
    )
}

interface CollectionThumbnailPreviewProps {
    collection: Collection
}

export function CollectionThumbnailPreview({ collection }: CollectionThumbnailPreviewProps) {
    return <CollectionThumbnail isPreviewOnly collection={collection} baseUrl="/" cacheHandlerKey="UNKNOWN" />
}
