import groupBy from 'lodash.groupby'
import { GaReportResponse, Maybe } from 'vo-components'
import first from 'lodash.first'

export enum ReportKey {
    USERS_REPORT = 'USERS_REPORT',
    PAGE_VISITS_REPORT = 'PAGE_VISITS_REPORT',
    SITE_REFERRER_REPORT = 'SITE_REFERRER_REPORT',
    BROWSER_REPORT = 'BROWSER_REPORT',
}

export function findReportResponseByKey(reports: Maybe<GaReportResponse>[], key: ReportKey): GaReportResponse | undefined {
    return reports.find(report => report?.id === key) || undefined
}

export type ReportValues = { [key: string]: string }

export interface StandardReport {
    dimensions: { [key: string]: string }
    values: ReportValues
}

export function groupByDimension(reports: StandardReport[], dimension: string) {
    return groupBy(reports, report => report.dimensions[dimension])
}

export function parseReportResponse(report: GaReportResponse): StandardReport[] {
    return (
        report.data?.rows?.map<StandardReport>(row => ({
            dimensions:
                row?.dimensions?.reduce((finalDimension, dimension, index) => {
                    if (!report.columnHeader?.dimensions) return finalDimension
                    const entry = report.columnHeader.dimensions[index] || ''
                    return {
                        ...finalDimension,
                        [entry]: dimension,
                    }
                }, {}) || {},
            values:
                (row?.metrics || [])[0]?.values?.reduce((finalMetric, metric, index) => {
                    if (!report.columnHeader?.metricHeader?.metricHeaderEntries) return finalMetric
                    const entry = report.columnHeader?.metricHeader?.metricHeaderEntries[index]?.name || ''
                    return {
                        ...finalMetric,
                        [entry]: metric,
                    }
                }, {}) || {},
        })) || []
    )
}

export function groupByDate(reports: StandardReport[]) {
    return groupByDimension(reports, 'ga:date')
}

export function groupByCountry(reports: StandardReport[]) {
    return groupByDimension(reports, 'ga:country')
}

export function groupByDeviceCategory(reports: StandardReport[]) {
    return groupByDimension(reports, 'ga:deviceCategory')
}

export type UserReport = Map<string, Map<string, ReportValues>>

export function parseStandardReportToUserReport(reports: StandardReport[]): UserReport {
    const finalReports = new Map<string, Map<string, ReportValues>>()
    const reportsByDate = groupByDate(reports)
    Object.keys(reportsByDate).forEach(date => {
        const reportsByCountry = groupByCountry(reportsByDate[date])
        const finalReportsByCountry = new Map<string, ReportValues>()
        Object.keys(reportsByCountry).forEach(country => {
            finalReportsByCountry.set(country, first(reportsByCountry[country])?.values || {})
        })
        finalReports.set(date, finalReportsByCountry)
    })
    return finalReports
}

export type StandardReportMap = ReportValues | Map<string, StandardReportMap>

export function parseStandardReport(reports: StandardReport[], filters: string[]): StandardReportMap {
    if (filters.length === 0) return first(reports)?.values || {}
    const finalReports = new Map<string, StandardReportMap>()
    const [filter, ...remainingFilters] = filters
    const segmentedReports = groupByDimension(reports, filter)
    Object.keys(segmentedReports).forEach(segment => {
        finalReports.set(segment, parseStandardReport(segmentedReports[segment], remainingFilters))
    })
    return finalReports
}
