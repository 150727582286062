import React, { useCallback, useMemo, useContext } from 'react'
import { stylesheet } from 'typestyle'
import { em } from 'csx'
import { LottieWhale } from '../../components/Lottie'
import { useDropzone } from 'react-dropzone'
import DropUploadContainer from '../../components/DropUploadContainer'
import { Hub } from 'aws-amplify'
import { CHANNELS, AssetPayload } from '../allsite/CollectionAssetsUploader'
import { CollectionDetailContext } from './CollectionDetail'
import CollectionAssetsSegment, { SegmentLayoutConfig, getCollectionAssetsBySegment } from './CollectionAssetsSegment'
import { CollectionAssetType } from 'vo-components'

const styles = stylesheet({
    emptyButtonContainer: {
        marginTop: em(1),
    },
})

interface CollectionAssetsProps {
    photoConfig?: SegmentLayoutConfig
    videoConfig?: SegmentLayoutConfig
    disableUpload?: boolean
}

export default function CollectionAssets({ photoConfig, videoConfig, disableUpload }: CollectionAssetsProps) {
    const { collection } = useContext(CollectionDetailContext)
    const isCustomerCollection = !!collection.customer
    const onDrop = useCallback(
        acceptedFiles => {
            // Do something with the files
            if (acceptedFiles.length === 0) return
            const data: AssetPayload = {
                files: acceptedFiles,
                collection,
                photoType: photoConfig?.type || CollectionAssetType.Unknown,
                videoType: videoConfig?.type || CollectionAssetType.Unknown,
                disablePrefix: isCustomerCollection,
            }
            Hub.dispatch(CHANNELS.QUEUE_UPLOAD, {
                event: 'UPLOAD_ASSETS',
                data,
            })
        },
        [collection, photoConfig, videoConfig]
    )
    const acceptedFileTypes = useMemo<string[]>(() => {
        const initialArr: string[] = []
        return initialArr.concat(photoConfig ? ['image/*'] : []).concat(videoConfig ? ['video/*'] : [])
    }, [photoConfig, videoConfig])

    const dropzone = useDropzone({
        onDrop,
        accept: acceptedFileTypes,
        disabled: disableUpload,
        noClick: true,
    })
    const isEmpty = useMemo<boolean>(
        () =>
            (!photoConfig || getCollectionAssetsBySegment(collection, photoConfig.type).items.length === 0) &&
            (!videoConfig || getCollectionAssetsBySegment(collection, videoConfig.type).items.length === 0),
        [collection, photoConfig, videoConfig]
    )
    const emptyTitle = useMemo<string>(() => {
        const segments = []
        if (photoConfig) {
            segments.push(photoConfig.title?.toLowerCase() || 'photos')
        }
        if (videoConfig) {
            segments.push(videoConfig.title?.toLowerCase() || 'videos')
        }
        return segments.join(' or ')
    }, [photoConfig, videoConfig])
    return (
        <>
            <DropUploadContainer disabled={disableUpload} dropzone={dropzone}>
                {isEmpty && (
                    <>
                        <hr />
                        <LottieWhale height={320} />
                        <p className="has-text-centered">
                            You don&apos;t have any {emptyTitle} for <strong>&ldquo;{collection.title}&rdquo;</strong>!
                        </p>
                        {dropzone.open && (
                            <div className={styles.emptyButtonContainer}>
                                <div className="buttons is-centered">
                                    <button onClick={() => dropzone.open()} className="button is-primary">
                                        Upload {emptyTitle}
                                    </button>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {videoConfig && <CollectionAssetsSegment {...videoConfig} dropzone={dropzone} />}
                {photoConfig && <CollectionAssetsSegment {...photoConfig} dropzone={dropzone} />}
            </DropUploadContainer>
        </>
    )
}
