import React from 'react'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { ApolloProvider } from '@apollo/client'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { Auth } from 'aws-amplify'

const client = new AWSAppSyncClient({
    url: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT || '',
    region: process.env.REACT_APP_AWS_REGION || '',
    auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials: () => Auth.currentCredentials(),
    },
    disableOffline: true,
    cacheOptions: {
        fragmentMatcher: new IntrospectionFragmentMatcher({
            introspectionQueryResultData: {
                __schema: {
                    types: [], // no types provided
                },
            },
        }),
        dataIdFromObject: (o: any): string | undefined => {
            switch (o.__typename) {
                case 'Banner':
                    return o.banner_id
                case 'PortfolioSection':
                    return o.portfolio_section_key
                case 'Collection':
                    return o.collection_id
                case 'CollectionAsset':
                    return o.asset_id
                case 'Slug':
                    return `${o.type}-${o.slug}`
                case 'Profile':
                    return o.page_key
                case 'Customer':
                    return o.customer_id
                default:
                    return o.id
            }
        },
    },
})

interface AppSyncProviderProps {
    children: React.ReactNode
}

export default function AppSyncProvider({ children }: AppSyncProviderProps) {
    return <ApolloProvider client={client as any}>{children}</ApolloProvider>
}
