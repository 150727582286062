import React from 'react'
import Banners from './Banners'
import AboutMe from './AboutMe'
import LatestWorks from './LatestWorks'

export default function PageHome() {
    return (
        <>
            <div className="section">
                <div className="container">
                    <Banners />
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <AboutMe />
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <LatestWorks />
                </div>
            </div>
        </>
    )
}
