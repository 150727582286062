import { useEffect, useState } from 'react'
import { Hub } from 'aws-amplify'
import { useEffectOnce } from 'react-use'

function useGoogleLoginScript() {
    const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false)

    useEffectOnce(() => {
        const initGapi = () => {
            const g = window.gapi
            g.load('auth2', function() {
                g.auth2
                    .init({
                        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                        ux_mode: 'redirect',
                    })
                    .then(() => {
                        setIsScriptLoaded(true)
                    })
            })
        }
        const createScript = () => {
            // load the Google SDK
            const script = document.createElement('script')
            script.src = 'https://apis.google.com/js/platform.js'
            script.async = true
            script.onload = initGapi
            document.body.appendChild(script)
        }
        if (!window.gapi || !window.gapi.auth2) createScript()
        else setIsScriptLoaded(true)
    })

    useEffect(() => {
        if (!isScriptLoaded || !window.gapi || !window.gapi.auth2) {
            return
        }
        const gAuth = window.gapi.auth2.getAuthInstance()
        if (!gAuth.isSignedIn.get()) {
            return
        }
        const response = gAuth.currentUser.get()
        const profile = response.getBasicProfile()
        const googleCreds = response.getAuthResponse()
        const user = {
            email: profile.getEmail(),
            name: profile.getName(),
            profile_picture_url: profile.getImageUrl(),
        }
        const credentials = {
            token: googleCreds.id_token,
            expires_at: googleCreds.expires_at,
        }
        Hub.dispatch('authProvider', {
            event: 'googleAuth',
            data: {
                provider: 'google',
                credentials,
                user,
            },
        })
    }, [isScriptLoaded])
}

export default function useThirdPartyLoginScript() {
    useGoogleLoginScript()
}
