import React from 'react'
import { classes, stylesheet } from 'typestyle'
import { px, em } from 'csx'
import { useSpring, animated } from 'react-spring'
import { Theme } from 'vo-components'
import { DropzoneState } from 'react-dropzone'

const styles = stylesheet({
    container: {
        position: 'relative',
    },
    uploadContainer: {
        backgroundColor: Theme.colors.transparent,
        $nest: {
            '&:focus': {
                outline: 'none',
            },
        },
    },
    uploadActiveContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        borderColor: Theme.colors.info,
        borderRadius: 5,
        borderWidth: px(4),
        borderStyle: 'solid',
        backgroundColor: Theme.colors.infoFade,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    uploadIconContainer: {
        position: 'sticky',
        bottom: em(1),
        textAlign: 'center',
        paddingBottom: em(1),
    },
    uploadLabelContainer: {
        padding: '1em 1em',
        backgroundColor: Theme.colors.darkBlue,
        color: Theme.colors.white,
    },
    uploadIcon: {
        fontSize: em(10),
        willChange: 'transform',
    },
})

interface DropUploadContainerProps {
    disabled?: boolean
    children: React.ReactNode
    dropzone: DropzoneState
    className?: string
}
export default function DropUploadContainer({ disabled, children, dropzone, className }: DropUploadContainerProps) {
    if (disabled) return <>{children}</>
    const { getRootProps, getInputProps, isDragActive } = dropzone
    return (
        <div className={styles.container}>
            <div {...getRootProps()} className={classes(styles.uploadContainer, className)}>
                <input {...getInputProps()} />
                {children}
            </div>

            {isDragActive && (
                <div {...getRootProps()} className={styles.uploadActiveContainer}>
                    <div className={styles.uploadIconContainer}>
                        <UploadIcon />
                        <div className={styles.uploadLabelContainer}>
                            <h4>Drop file to upload photo</h4>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

/**
 * UploadIcon component
 * animated upload icon
 */
const interp = () => (r: number) => `translate3d(0, ${15 * Math.sin(r + Math.PI / 1.6)}px, 0)`

function UploadIcon() {
    const { radians } = useSpring({
        to: async next => {
            await next({ radians: 2 * Math.PI })
        },
        from: { radians: 0 },
        config: { duration: 3500 },
    })
    return (
        <animated.div className={styles.uploadIcon} style={{ transform: radians.interpolate(interp()) }}>
            <span className="icon has-text-white">
                <i className="fas fa-cloud-upload-alt" />
            </span>
        </animated.div>
    )
}
