import React from 'react'
import { classes, stylesheet } from 'typestyle'
import { em, important } from 'csx'

const styles = stylesheet({
    loginButton: {
        marginTop: em(1),
    },
    iconContainer: {
        marginRight: important(em(0.75)),
    },
    googleIconContainer: {
        padding: 2,
    },
})

export default function LoginForm() {
    const googleLoginHandler = () => {
        if (window.gapi && window.gapi.auth2) {
            const ga = window.gapi.auth2.getAuthInstance()
            ga.signIn()
        }
    }
    return (
        <section className="hero is-fullheight">
            <div className="hero-body">
                <div className="container">
                    <h1 className="is-size-1 is-size-4-mobile has-text-weight-bold">Veronica Onggoro Admin Site</h1>
                    <p className="is-size-6 has-text-weight-normal">
                        Searching to my public site?
                        <a href="https://veronicaonggoro.com">Click here.</a>
                    </p>
                    <button className={classes('button is-fullwidth is-medium', styles.loginButton)} onClick={googleLoginHandler}>
                        <span className={classes('icon', styles.iconContainer, styles.googleIconContainer)}>
                            <figure className="image">
                                <img
                                    // eslint-disable-next-line max-len
                                    src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                                    alt="google-logo"
                                />
                            </figure>
                        </span>
                        <span> Login with Google </span>
                    </button>
                    <p className="has-text-grey-dark is-hidden-mobile has-text-weight-light" style={{ marginTop: '5em' }}>
                        *This site is only for Veronica Onggoro.
                    </p>
                </div>
            </div>
        </section>
    )
}
