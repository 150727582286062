import React, { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import { AuthContext } from './Authenticator'
import Login from './pages/login/PageLogin'
import PageHome from './pages/home/PageHome'
import Header from './components/Header'
import CollectionAssetsUploader from './pages/allsite/CollectionAssetsUploader'
import PageDashboard from './pages/dashboard/PageDashboard'
import PageContactMe from './pages/contact-me/PageContactMe'
import PagePortfolioList from './pages/portfolio-list/PagePortfolioList'
import PageProjects from './pages/collection-list/PageProjects'
import PagePortfolioSectionCollections from './pages/collection-list/PagePortfolioSectionCollections'
import PageCollectionAssetList from './pages/collection-asset-list/PageCollectionAssetList'
import PageCustomerList from './pages/customer-list/PageCustomerList'
import PageCustomerSessions from './pages/collection-list/PageCustomerSessions'
import { PageContext, PageKey } from './components/PageContext'
import PageCustomerAssetList from './pages/collection-asset-list/PageCustomerAssetList'

export default function AppRouter() {
    const { isLoggedIn, isMaybeLoggedIn } = useContext(AuthContext)
    if (!isLoggedIn && isMaybeLoggedIn) return null
    if (!isLoggedIn)
        return (
            <PageContext.Provider value={{ pageKey: PageKey.LOGIN }}>
                <Login />
            </PageContext.Provider>
        )
    return (
        <>
            <Header />
            <DndProvider backend={Backend}>
                <Switch>
                    <Route exact path="/">
                        <PageContext.Provider value={{ pageKey: PageKey.DASHBOARD }}>
                            <PageDashboard />
                        </PageContext.Provider>
                    </Route>
                    <Route exact path="/home">
                        <PageContext.Provider value={{ pageKey: PageKey.HOME }}>
                            <PageHome />
                        </PageContext.Provider>
                    </Route>
                    <Route exact path="/portfolio">
                        <PageContext.Provider value={{ pageKey: PageKey.PORTFOLIO_LIST }}>
                            <PagePortfolioList />
                        </PageContext.Provider>
                    </Route>
                    <Route exact path="/portfolio/:portfolioSlug">
                        <PageContext.Provider value={{ pageKey: PageKey.PORTFOLIO_COLLECTION_LIST }}>
                            <PagePortfolioSectionCollections />
                        </PageContext.Provider>
                    </Route>
                    <Route exact path="/portfolio/:portfolioSlug/:collectionSlug">
                        <PageContext.Provider value={{ pageKey: PageKey.PORTFOLIO_COLLECTION_ASSET_LIST }}>
                            <PageCollectionAssetList />
                        </PageContext.Provider>
                    </Route>
                    <Route exact path="/projects">
                        <PageContext.Provider value={{ pageKey: PageKey.PROJECT_LIST }}>
                            <PageProjects />
                        </PageContext.Provider>
                    </Route>
                    <Route exact path="/:listingSlug(projects)/:collectionSlug">
                        <PageContext.Provider value={{ pageKey: PageKey.PROJECT_ASSET_LIST }}>
                            <PageCollectionAssetList />
                        </PageContext.Provider>
                    </Route>
                    <Route exact path="/contact-me">
                        <PageContext.Provider value={{ pageKey: PageKey.CONTACT_ME }}>
                            <PageContactMe />
                        </PageContext.Provider>
                    </Route>
                    <Route exact path="/clients">
                        <PageContext.Provider value={{ pageKey: PageKey.CUSTOMER_LIST }}>
                            <PageCustomerList />
                        </PageContext.Provider>
                    </Route>
                    <Route exact path="/clients/:customerId">
                        <PageContext.Provider value={{ pageKey: PageKey.CUSTOMER_SESSION_LIST }}>
                            <PageCustomerSessions />
                        </PageContext.Provider>
                    </Route>
                    <Route path="/clients/:customerId/:collectionSlug">
                        <PageContext.Provider value={{ pageKey: PageKey.CUSTOMER_SESSION_ASSET_LIST }}>
                            <PageCustomerAssetList />
                        </PageContext.Provider>
                    </Route>
                    <Route path="*">
                        <Redirect to="/home" />
                    </Route>
                </Switch>
            </DndProvider>
            <CollectionAssetsUploader />
        </>
    )
}
