import React, { useState, useMemo, useContext } from 'react'
import { media, classes, stylesheet } from 'typestyle'
import { em, px } from 'csx'
import Dialog from '../../components/Dialog'
import { Theme, LightboxProvider, DropdownMenu, getMedia, MediaType, CollectionAsset, Collection } from 'vo-components'
import { useCollectionAssetsDeleteMutation, useCollectionAssetsSortMutation } from '../../graphql/useCollectionAssets'
import { useSortKey, DragPlaceholder, Sortable } from '../../components/Sortable'
import { Thumbnail } from '../../models/ThumbnailModel'
import { useImmediateUploader } from '../../components/useUploader'
import Media from '../../components/Media'
import { MultiSelectContext } from '../../components/MultiSelectContainer'

const styles = stylesheet({
    assetContainer: {
        position: 'relative',
    },
    photoContainer: {
        minHeight: 200,
        marginBottom: em(1.5),
        ...media(Theme.screen.phone, {
            marginBottom: em(0.5),
        }),
    },
    posterUploadIndicatorContainer: {
        right: 'unset',
        bottom: 'unset',
        left: 5,
        top: 5,
    },
    actionButtonContainer: {
        bottom: 'unset',
        left: 'unset',
    },
    selectedItemOverlay: {
        borderColor: Theme.colors.info,
        borderRadius: 5,
        borderWidth: px(4),
        borderStyle: 'solid',
        backgroundColor: Theme.colors.infoFade,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
})

interface CollectionAssetItemProps extends Sortable, Thumbnail {
    collection: Collection
    asset: CollectionAsset
    index: number
}

export default function CollectionAssetItem({
    collection,
    asset,
    placeholder,
    previousSortKey,
    nextSortKey,
    isPreviewOnly,
    index,
}: CollectionAssetItemProps) {
    const { isCtrlPressed, registerItem, removeItem, selectedItems } = useContext(MultiSelectContext)
    const isSelected = useMemo(() => (selectedItems || []).find(item => item === asset.asset_id), [selectedItems, asset])
    const lightboxContext = useContext(LightboxProvider)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const { uploadProgress, open, getInputProps, outputUrl } = useImmediateUploader({
        id: asset.asset_id,
        handler: 'CollectionAssetVideoPoster',
        originalUrl: asset.file?.poster || undefined,
        noClick: true,
    })
    const assetType = getMedia(asset.file?.contentType || '')
    const [deleteCollectionAsset, { loading: deleteLoading, error: deleteError }] = useCollectionAssetsDeleteMutation({
        collection,
        collectionAssets: [asset],
    })
    const candidateSortKey = useSortKey({ originalSortKey: asset.sort_key || '', previousSortKey, nextSortKey, skip: !placeholder })
    const [sort] = useCollectionAssetsSortMutation({
        collection,
        collectionAsset: asset,
        sortKey: candidateSortKey,
    })
    if (!asset || !collection) return null
    if (placeholder) {
        return <DragPlaceholder mutate={sort}>{ref => <div ref={ref} className="is-overlay" />}</DragPlaceholder>
    }
    return (
        <div className={classes(styles.assetContainer, assetType === MediaType.IMAGE && styles.photoContainer)}>
            <Media
                file={asset.file || undefined}
                title={`One of assets for ${collection.title}`}
                videoPosterUrl={outputUrl}
                videoPosterUploadProgress={uploadProgress}
            />
            {assetType === MediaType.IMAGE && (
                <div
                    className="is-overlay"
                    onClick={() => {
                        lightboxContext.open(index)
                    }}
                />
            )}
            {!isPreviewOnly && (
                <div className={classes('is-overlay', styles.actionButtonContainer)}>
                    <DropdownMenu>
                        {assetType === MediaType.VIDEO && (
                            <>
                                <input {...getInputProps()} />
                                <button onClick={() => open()} className="button is-white dropdown-item">
                                    Upload poster
                                </button>
                            </>
                        )}
                        <button onClick={() => setIsDeleteDialogOpen(true)} className="button is-white dropdown-item has-text-danger">
                            Delete
                        </button>
                    </DropdownMenu>
                </div>
            )}
            {isSelected && <div className={classes('is-overlay', styles.selectedItemOverlay)} />}
            {isCtrlPressed && (
                <div
                    onClick={(e: React.SyntheticEvent) => {
                        e.stopPropagation()
                        if (isSelected) {
                            removeItem(asset.asset_id)
                        } else {
                            registerItem(asset.asset_id)
                        }
                    }}
                    className="is-overlay"
                />
            )}
            <Dialog
                isOpen={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                onYes={() => deleteCollectionAsset().catch(console.error)}
                title={`Are you sure you want to delete this ${assetType === MediaType.IMAGE ? 'photo' : 'video'}?`}
                yesLabel={`Yes, delete this ${assetType === MediaType.IMAGE ? 'photo' : 'video'}`}
                noLabel="Cancel"
                loading={deleteLoading}
                error={deleteError}
            />
        </div>
    )
}
