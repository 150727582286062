import React, { useState, useMemo, useContext } from 'react'
import {
    Modal,
    Theme,
    LatestWorkCollection,
    PortfolioSection,
    Collection,
    ProfileLatestWorks,
    ProfileType,
    SlugPortfolioSection,
} from 'vo-components'
import { classes, stylesheet } from 'typestyle'
import { viewWidth, viewHeight, em } from 'csx'
import { usePortfolioSectionsQuery, usePortfolioSectionsSlugQuery } from '../../graphql/usePortfolioSections'
import orderBy from 'lodash.orderby'
import { useCollectionsQuery } from '../../graphql/useCollections'
import Error from '../../components/Error'
import { CollectionThumbnailPreview } from '../allsite/CollectionThumbnail'
import { useProfileMutation } from '../../graphql/useProfile'
import last from 'lodash.last'
import { LexoRank } from 'lexorank'
import { BasicModalProps } from '../../models/ModalModel'

const styles = stylesheet({
    modal: {
        minWidth: viewWidth(80),
        minHeight: viewHeight(80),
    },
    sectionButton: {
        width: '100%',
        justifyContent: 'start',
    },
    emptyCollectionText: {
        marginTop: em(2),
    },
    collectionOverlay: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderColor: Theme.colors.overlayLight,
        borderWidth: 1,
        borderStyle: 'solid',
    },
    actionButtonContainer: {
        backgroundColor: 'white',
        padding: `${em(1)} ${em(2)}`,
    },
    badgeContainer: {
        padding: `${em(0.25)} ${em(0.5)}`,
    },
})

const PAGE_KEY = 'LATEST_WORKS'

interface Section {
    listingStatus: 'PROJECTS' | 'PORTFOLIO_SECTION'
    portfolioSection?: PortfolioSection
}

interface LatestWorkContext {
    latestWorks: LatestWorkCollection[]
    activeSection: Section
    setActiveSection: (section: Section) => void
}

const LatestWorkContext = React.createContext<LatestWorkContext>({} as LatestWorkContext)

interface CollectionItemForLatestWorkProps {
    collection: Collection
}

function CollectionItemForLatestWork({ collection }: CollectionItemForLatestWorkProps) {
    const { latestWorks } = useContext(LatestWorkContext)
    const isLatestWork = useMemo(() => !!latestWorks.find(latestWork => latestWork.collection_id === collection.collection_id), [
        latestWorks,
        collection,
    ])
    const updateValue = useMemo<ProfileLatestWorks>(() => {
        let updatedLatestWorks: LatestWorkCollection[] = []
        if (isLatestWork) {
            updatedLatestWorks = latestWorks.filter(latestWork => latestWork.collection_id !== collection.collection_id)
        } else {
            const latestSortKey = last(latestWorks)?.sort_key
            let sortKey = LexoRank.middle().toString()
            if (latestSortKey) {
                try {
                    sortKey = LexoRank.parse(latestSortKey)
                        .genNext()
                        .toString()
                } catch (e) {
                    // ignore. not a lexorank
                }
            }
            updatedLatestWorks = [
                ...latestWorks.map(latestWork => ({ collection_id: latestWork.collection_id, sort_key: latestWork.sort_key })),
                { collection_id: collection.collection_id, sort_key: sortKey },
            ]
        }
        return {
            items: updatedLatestWorks.map(latestWork => ({ collection_id: latestWork.collection_id, sort_key: latestWork.sort_key })),
        }
    }, [isLatestWork, latestWorks, collection])
    const [save, { loading, error }] = useProfileMutation({
        pageKey: PAGE_KEY,
        type: ProfileType.LatestWorks,
        value: updateValue,
        shouldRefetch: latestWorks.length === 0,
    })
    return (
        <div className="is-relative">
            <CollectionThumbnailPreview collection={collection} />
            <div className="is-overlay">
                <div className={styles.collectionOverlay}>
                    <div className={classes('has-text-right', styles.badgeContainer)}>
                        {isLatestWork && <span className="tag is-success">Latest Works</span>}
                    </div>
                    <div className={styles.actionButtonContainer}>
                        <button
                            onClick={() => save()}
                            className={classes('button is-fullwidth', isLatestWork ? 'is-danger' : 'is-success', loading && 'is-loading')}
                        >
                            {isLatestWork ? 'Remove from' : 'Add to'} latest works
                        </button>
                        {error && <p className="has-text-centered has-text-danger is-size-7">Whoops! Something went wrong</p>}
                    </div>
                </div>
            </div>
        </div>
    )
}

interface CollectionsProps {
    items: Collection[]
    error: any
    loading: boolean
    refetch: () => void
}

function Collections({ items, error, loading, refetch }: CollectionsProps) {
    if (error) {
        return (
            <div className="section">
                <div className="container">
                    <Error errorTrace={error} retry={refetch} />
                </div>
            </div>
        )
    }
    if (loading) {
        return null
    }
    return (
        <div className="columns is-multiline">
            {items.length === 0 && <p className={styles.emptyCollectionText}>You don&apos;t have any collections in this section.</p>}
            {items.map(collection => (
                <div key={collection.collection_id} className="column is-4">
                    <CollectionItemForLatestWork collection={collection} />
                </div>
            ))}
        </div>
    )
}

interface CollectionsByListingStatusProps {
    listingStatus: string
}

function CollectionsByListingStatus({ listingStatus }: CollectionsByListingStatusProps) {
    const { loading, error, data, refetch } = useCollectionsQuery({ listingStatus })
    const { items } = data?.listCollectionsByListingStatus || {}
    return <Collections items={items || []} error={error} loading={loading} refetch={refetch} />
}

interface CollectionsByPortfolioSectionProps {
    portfolioSection: PortfolioSection
}

function CollectionsByPortfolioSection({ portfolioSection }: CollectionsByPortfolioSectionProps) {
    const { loading, error, data, refetch } = usePortfolioSectionsSlugQuery({ slug: portfolioSection.slug || '' })
    const { portfolio_section } = (data?.showSlug?.value || {}) as SlugPortfolioSection
    return <Collections items={portfolio_section?.collections?.items || []} error={error} loading={loading} refetch={refetch} />
}

function SectionList() {
    const { activeSection, setActiveSection } = useContext(LatestWorkContext)
    const { data } = usePortfolioSectionsQuery({})
    const portfolioSections = useMemo(() => orderBy(data?.listPortfolioSections?.items || [], ['sort_key'], ['asc']), [data])
    return (
        <aside className="menu">
            <p className="menu-label">Others</p>
            <ul className="menu-list">
                <li>
                    <button
                        onClick={() => setActiveSection({ listingStatus: 'PROJECTS' })}
                        className={classes('button is-white', styles.sectionButton, activeSection.listingStatus === 'PROJECTS' && 'is-active')}
                    >
                        Projects
                    </button>
                </li>
            </ul>
            <p className="menu-label">Portfolio</p>
            <ul className="menu-list">
                {portfolioSections.map(portfolioSection => (
                    <li key={portfolioSection.portfolio_section_key}>
                        <button
                            onClick={() =>
                                setActiveSection({
                                    listingStatus: 'PORTFOLIO_SECTION',
                                    portfolioSection: portfolioSection,
                                })
                            }
                            className={classes(
                                'button is-white',
                                styles.sectionButton,
                                activeSection.portfolioSection?.portfolio_section_key === portfolioSection.portfolio_section_key && 'is-active'
                            )}
                        >
                            {portfolioSection.name}
                        </button>
                    </li>
                ))}
            </ul>
        </aside>
    )
}

interface ModalLatestWorkProps extends BasicModalProps {
    latestWorks: LatestWorkCollection[]
}

export default function ModalLatestWork({ latestWorks, isOpen, onClose }: ModalLatestWorkProps) {
    const [activeSection, setActiveSection] = useState<Section>({
        listingStatus: 'PROJECTS',
    })
    return (
        <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
            <LatestWorkContext.Provider
                value={{
                    latestWorks,
                    activeSection,
                    setActiveSection,
                }}
            >
                <div className="columns is-marginless">
                    <div className="column is-2">
                        <SectionList />
                    </div>
                    <div className="column">
                        {activeSection.listingStatus === 'PORTFOLIO_SECTION' && activeSection.portfolioSection && (
                            <CollectionsByPortfolioSection portfolioSection={activeSection.portfolioSection} />
                        )}
                        {activeSection.listingStatus === 'PROJECTS' && <CollectionsByListingStatus listingStatus={activeSection.listingStatus} />}
                    </div>
                </div>
            </LatestWorkContext.Provider>
        </Modal>
    )
}
