import { useQuery, useMutation } from '@apollo/client'
import {
    SHOW_PROFILE,
    SAVE_PROFILE,
    Maybe,
    QueryShowProfileArgs,
    ProfileType,
    ProfileInput,
    MutationSaveProfileArgs,
    Mutation,
    AboutMeInput,
    LatestWorksInput,
    Collection,
    LatestWorkCollection,
    Profile,
} from 'vo-components'
import { MutationArgs } from './customTypes'

const valueKey = new Map<ProfileType, keyof ProfileInput>([
    [ProfileType.AboutMe, 'about_me'],
    [ProfileType.LatestWorks, 'latest_works'],
])

interface ProfileCreateMutationArgs extends ProfileInput, MutationArgs {
    pageKey: string
    value: AboutMeInput | LatestWorksInput
}

export function useProfileMutation({ pageKey, type, value, shouldRefetch }: ProfileCreateMutationArgs) {
    const key = valueKey.get(type)
    return useMutation<Partial<Mutation>, MutationSaveProfileArgs>(SAVE_PROFILE, {
        variables: {
            page_key: pageKey,
            input: {
                type,
                ...(key && { [key]: value }),
            },
        },
        refetchQueries: shouldRefetch ? [{ query: SHOW_PROFILE, variables: { page_key: pageKey } }] : undefined,
    })
}

interface ProfileSortLatestWorksMutationArgs extends MutationArgs {
    pageKey: string
    latestWorks: LatestWorkCollection[]
}

export function useProfileSortLatestWork({ pageKey, latestWorks }: ProfileSortLatestWorksMutationArgs) {
    return useMutation<Partial<Mutation>, MutationSaveProfileArgs>(SAVE_PROFILE, {
        variables: {
            page_key: pageKey,
            input: {
                type: ProfileType.LatestWorks,
                latest_works: {
                    items: latestWorks.map(latestWork => ({ collection_id: latestWork.collection_id, sort_key: latestWork.sort_key })),
                },
            },
        },
        optimisticResponse: {
            __typename: 'Mutation',
            saveProfile: {
                __typename: 'Profile',
                page_key: pageKey,
                type: ProfileType.LatestWorks,
                value: {
                    __typename: 'ProfileLatestWorks',
                    items:
                        latestWorks.map(latestWork => ({
                            ...latestWork,
                            collection: {
                                __typename: 'Collection',
                                ...(latestWork.collection as Collection),
                            },
                        })) || [],
                },
            },
        },
    })
}

interface ProfileQueryArgs {
    pageKey: string
}

export function useProfileQuery({ pageKey }: ProfileQueryArgs) {
    return useQuery<{ showProfile: Maybe<Profile> }, QueryShowProfileArgs>(SHOW_PROFILE, {
        variables: { page_key: pageKey },
    })
}
