import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import orderBy from 'lodash.orderby'
import { Customer, Collection } from 'vo-components'
import ModalCustomer from '../allsite/ModalCustomer'
import { ModalFormMode } from '../../models/ModalModel'
import { useCustomersDeleteMutation } from '../../graphql/useCustomers'
import { DialogModal } from '../../components/Dialog'
import { classes, stylesheet } from 'typestyle'
import ModalCustomerReview from '../allsite/ModalCustomerReview'

const styles = stylesheet({
    inlineButton: {
        height: 'unset',
        verticalAlign: 'unset',
        lineHeight: 'unset',
    },
})

interface SessionInfoProps {
    customer: Customer
    sessions: Collection[]
}

function SessionInfo({ sessions }: SessionInfoProps) {
    const [isModalReviewOpen, setIsModalReviewOpen] = useState(false)
    if (sessions.length === 0)
        return (
            <>
                <p className="has-text-right is-marginless">No sessions registered for this client.</p>
            </>
        )
    const [firstSession] = sessions
    return (
        <>
            <ModalCustomerReview isOpen={isModalReviewOpen} onClose={() => setIsModalReviewOpen(false)} collection={firstSession} />
            <p className="has-text-right">
                {firstSession.title}
                {firstSession.customer_review && (
                    <>
                        &nbsp;-&nbsp;
                        <button
                            onClick={() => setIsModalReviewOpen(true)}
                            className={classes('button is-text has-text-success is-paddingless', styles.inlineButton)}
                        >
                            See Review
                        </button>
                    </>
                )}
                <br />
                Duration: <strong>{firstSession.duration_in_hour} hour(s)</strong> | Price: <strong>${firstSession.hourly_price}/hour</strong>
                {sessions.length > 1 && (
                    <>
                        <br />
                        ... and <strong>{sessions.length - 1}</strong> more other session(s)
                    </>
                )}
            </p>
        </>
    )
}

interface CustomerItemProps {
    customer: Customer
}

export default function CustomerItem({ customer }: CustomerItemProps) {
    const [isModalCustomerOpen, setIsModalCustomerOpen] = useState(false)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [deleteCustomer, { loading, error }] = useCustomersDeleteMutation({ customer })
    const sortedSessions = useMemo(() => orderBy(customer?.sessions?.items || [], ['sort_key'], ['desc']), [customer])
    return (
        <>
            <ModalCustomer
                mode={ModalFormMode.UPDATE}
                isOpen={isModalCustomerOpen}
                onClose={() => setIsModalCustomerOpen(false)}
                customer={customer}
            />
            <DialogModal
                isOpen={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                onYes={() => deleteCustomer().catch(console.error)}
                title="Are you sure you want to delete this client?"
                subtitle="You won't be able to recover them"
                yesLabel="Yes, delete this client"
                noLabel="Cancel"
                loading={loading}
                error={error}
            />
            <article className="media">
                <div className="media-content">
                    <div className="content">
                        <div className="columns level">
                            <div className="column is-narrow level-item">
                                <Link to={`/clients/${customer.customer_id}`}>
                                    <p className="has-text-dark">
                                        <strong>
                                            <u>{customer.name}</u>
                                        </strong>
                                        &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                                        <small>{customer.email}</small>
                                        {customer.instagram_username && (
                                            <>
                                                &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                                                <small>@{customer.instagram_username}</small>
                                            </>
                                        )}
                                        {!customer.is_tutorial_viewed && (
                                            <>
                                                &nbsp;&nbsp;&middot;&nbsp;&nbsp;<span className="tag">user hasn&apos;t logged in...</span>
                                            </>
                                        )}
                                        <br />
                                        {customer.description}
                                    </p>
                                </Link>
                            </div>
                            <div className="column level-item">
                                <SessionInfo customer={customer} sessions={sortedSessions} />
                            </div>
                            <div className="column is-narrow level-item">
                                <p className="buttons">
                                    <button onClick={() => setIsDeleteDialogOpen(true)} className="button is-danger">
                                        <span className="icon is-small">
                                            <i className="fas fa-trash" />
                                        </span>
                                    </button>
                                    <button onClick={() => setIsModalCustomerOpen(true)} className="button is-info">
                                        <span className="icon is-small">
                                            <i className="fas fa-edit" />
                                        </span>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </>
    )
}
