import React, { useMemo } from 'react'
import { IndividualReportProps } from './Reports'
import { parseReportResponse, parseStandardReport, ReportValues, groupByDeviceCategory } from '../../util/googleAnalyticsReport'
import { ResponsiveContainer, BarChart, XAxis, CartesianGrid, YAxis, Legend, Bar } from 'recharts'
import { Tooltip } from '@atlaskit/tooltip/dist/cjs/styled'

const colors = new Map<string, string>([
    ['desktop', '#8884d8'],
    ['mobile', '#82ca9d'],
])

export default function ReportSiteReferrer({ report }: IndividualReportProps) {
    const parsedReport = useMemo(() => parseReportResponse(report), [report])
    const siteReferrerReport = useMemo(() => parseStandardReport(parsedReport, ['ga:source', 'ga:deviceCategory']), [parsedReport]) as Map<
        string,
        Map<string, ReportValues>
    >
    const deviceCategories = useMemo(() => Object.keys(groupByDeviceCategory(parsedReport)), [parsedReport])
    const data = useMemo(
        () =>
            Array.from(siteReferrerReport.keys()).map(referrer => {
                const reportsByDevice = siteReferrerReport.get(referrer)
                return {
                    referrer,
                    ...Array.from(reportsByDevice?.keys() || []).reduce(
                        (reports, device) => ({
                            ...reports,
                            [device]: reportsByDevice?.get(device),
                        }),
                        {}
                    ),
                }
            }),
        [siteReferrerReport]
    )
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-header-title is-size-5">How did your user visits your page?</h3>
            </div>
            <div className="card-content">
                <ResponsiveContainer height={400}>
                    <BarChart data={data}>
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="referrer" />
                        <YAxis allowDecimals={false} />
                        <Tooltip />
                        <Legend />
                        {deviceCategories.map(deviceCategory => (
                            <Bar
                                key={deviceCategory}
                                name={deviceCategory}
                                dataKey={row => (row[deviceCategory] || {})['ga:entrances'] || 0}
                                fill={colors.get(deviceCategory) || '#ff7300'}
                            />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}
