import { useState, useCallback } from 'react'
import { useKeyPressEvent } from 'react-use'

const keyMappings = new Map<string, string>([['MacIntel', 'Meta']])

export default function useCtrlPressed() {
    const [isHeld, setIsHeld] = useState(false)
    const hold = useCallback(() => setIsHeld(true), [])
    const release = useCallback(() => setIsHeld(false), [])
    const key = keyMappings.get(window.navigator.platform) || 'Control'
    useKeyPressEvent(key, hold, release)
    return isHeld
}
