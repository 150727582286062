import React, { useContext, useMemo } from 'react'
import { useParams, Link } from 'react-router-dom'
import Error from '../../components/Error'
import CollectionDetailTitleBanner from './CollectionDetailTitleBanner'
import { LottieMoon } from '../../components/Lottie'
import { useCollectionsSlugQuery, CollectionCacheHandlerKey } from '../../graphql/useCollections'
import { SlugCollection, Collection, CollectionAssetType } from 'vo-components'
import { PageContext, PageKey } from '../../components/PageContext'

interface CollectionDetailContext {
    collection: Collection
    baseUrl: string
    cacheHandlerKey: CollectionCacheHandlerKey
}

export const CollectionDetailContext = React.createContext<CollectionDetailContext>({} as CollectionDetailContext)

interface CollectionDetailProps {
    children: React.ReactNode
    assetTypes: CollectionAssetType[]
}

export default function CollectionDetail({ children, assetTypes }: CollectionDetailProps) {
    const { pageKey } = useContext(PageContext)
    const { portfolioSlug, collectionSlug, listingSlug, customerId } = useParams()
    const { loading, error, data, refetch } = useCollectionsSlugQuery({ slug: collectionSlug || '', types: assetTypes })
    const { collection } = (data?.showSlug?.value || {}) as SlugCollection
    const baseUrl = useMemo<string>(() => {
        if (pageKey === PageKey.PORTFOLIO_COLLECTION_ASSET_LIST) {
            return `/portfolio/${portfolioSlug}`
        } else if (pageKey === PageKey.CUSTOMER_SESSION_ASSET_LIST) {
            return `/clients/${customerId}`
        }
        return `/${listingSlug}`
    }, [pageKey, portfolioSlug, customerId, listingSlug])
    const cacheHandlerKey = useMemo<CollectionCacheHandlerKey>(() => {
        if (pageKey === PageKey.PORTFOLIO_COLLECTION_ASSET_LIST) {
            return 'PORTFOLIO_SECTION'
        } else if (pageKey === PageKey.CUSTOMER_SESSION_ASSET_LIST) {
            return 'CUSTOMER_SESSION'
        }
        return 'COLLECTION'
    }, [pageKey])
    if (error) {
        return (
            <div className="section">
                <div className="container">
                    <Error errorTrace={error} retry={refetch} />
                </div>
            </div>
        )
    }
    if (loading) {
        return null
    }

    if (!collection) {
        return (
            <div className="section">
                <div className="container has-text-centered">
                    <LottieMoon height={320} />
                    <h1 className="is-size-3 title">Collection not found</h1>
                    <div className="buttons is-centered">
                        <button onClick={() => refetch()} className="button is-info">
                            Refresh
                        </button>
                        <Link to={baseUrl} className="button">
                            Go Back
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <CollectionDetailContext.Provider
            value={{
                collection,
                baseUrl,
                cacheHandlerKey,
            }}
        >
            <div className="section">
                <div className="container">
                    <nav className="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            {pageKey === PageKey.PORTFOLIO_COLLECTION_ASSET_LIST && collection.portfolio_section && (
                                <>
                                    <li>
                                        <Link to="/portfolio">Portfolio</Link>
                                    </li>
                                    <li>
                                        <Link to={`/portfolio/${collection.portfolio_section.slug}`}>{collection.portfolio_section.name}</Link>
                                    </li>
                                    <li className="is-active">
                                        <Link to={`/portfolio/${collection.portfolio_section.slug}/${collection.slug}`}>{collection.title}</Link>
                                    </li>
                                </>
                            )}
                            {pageKey === PageKey.CUSTOMER_SESSION_ASSET_LIST && collection.customer && (
                                <>
                                    <li>
                                        <Link to="/clients">Clients</Link>
                                    </li>
                                    <li>
                                        <Link to={`/clients/${collection.customer.customer_id}`}>{collection.customer.name}</Link>
                                    </li>
                                    <li className="is-active">
                                        <Link to={`/clients/${collection.customer.customer_id}/${collection.slug}`}>{collection.title}</Link>
                                    </li>
                                </>
                            )}
                            {pageKey === PageKey.PROJECT_ASSET_LIST && listingSlug && (
                                <>
                                    <li className="is-capitalized">
                                        <Link to={`/${listingSlug}`}>{listingSlug}</Link>
                                    </li>
                                    <li className="is-active">
                                        <Link to={`/${listingSlug}/${collection.slug}`}>{collection.title}</Link>
                                    </li>
                                </>
                            )}
                        </ul>
                    </nav>
                    <CollectionDetailTitleBanner />
                    {children}
                </div>
            </div>
        </CollectionDetailContext.Provider>
    )
}
