import { useMutation, useQuery } from '@apollo/client'
import {
    Maybe,
    MutationRequestCustomerFileArgs,
    REQUEST_CUSTOMER_FILE,
    QueryListCustomerFileArgs,
    LIST_CUSTOMER_FILE,
    CustomerFileConnection,
    CustomerFile,
    MutationDeleteCustomerFileArgs,
    DELETE_CUSTOMER_FILE,
} from 'vo-components'
import { MutationArgs } from './customTypes'

export function useRequestCustomerFileMutation({ onCompleted }: MutationArgs) {
    return useMutation<{ requestCustomerFile: CustomerFile }, MutationRequestCustomerFileArgs>(REQUEST_CUSTOMER_FILE, {
        onCompleted,
        update: (cache, { data: requestFileData }) => {
            if (!requestFileData?.requestCustomerFile) return
            try {
                const cacheQuery = {
                    query: LIST_CUSTOMER_FILE,
                    variables: {
                        collection_id: requestFileData.requestCustomerFile?.collection_id || '',
                    },
                }
                const data = cache.readQuery<{ listCustomerFile: Maybe<CustomerFileConnection> }, QueryListCustomerFileArgs>({
                    ...cacheQuery,
                })
                if (!data?.listCustomerFile) return
                cache.writeQuery<{ listCustomerFile: Maybe<CustomerFileConnection> }, QueryListCustomerFileArgs>({
                    ...cacheQuery,
                    data: {
                        ...data,
                        listCustomerFile: {
                            ...data.listCustomerFile,
                            items: [requestFileData.requestCustomerFile, ...(data.listCustomerFile.items || [])],
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useRequestCustomerFileMutation`')
            }
        },
    })
}

interface DeleteCustomerFileMutationArgs {
    collection_id: string
}

export function useDeleteCustomerFileMutation({ collection_id }: DeleteCustomerFileMutationArgs) {
    return useMutation<{ deleteCustomerFile: Maybe<CustomerFile> }, MutationDeleteCustomerFileArgs>(DELETE_CUSTOMER_FILE, {
        update: (cache, { data: deleteFileData }) => {
            if (!deleteFileData?.deleteCustomerFile) return
            try {
                const cacheQuery = {
                    query: LIST_CUSTOMER_FILE,
                    variables: {
                        collection_id: collection_id,
                    },
                }
                const data = cache.readQuery<{ listCustomerFile: Maybe<CustomerFileConnection> }, QueryListCustomerFileArgs>({
                    ...cacheQuery,
                })
                if (!data?.listCustomerFile) return
                cache.writeQuery<{ listCustomerFile: Maybe<CustomerFileConnection> }, QueryListCustomerFileArgs>({
                    ...cacheQuery,
                    data: {
                        ...data,
                        listCustomerFile: {
                            ...data.listCustomerFile,
                            items: data.listCustomerFile.items.filter(
                                customerFile => customerFile.file_id !== deleteFileData.deleteCustomerFile?.file_id
                            ),
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useDeleteCustomerFileMutation`')
            }
        },
    })
}

interface CustomerFileQueryArgs {
    collection_id: string
}

export function useCustomerFileQuery({ collection_id }: CustomerFileQueryArgs) {
    return useQuery<{ listCustomerFile: Maybe<CustomerFileConnection> }, QueryListCustomerFileArgs>(LIST_CUSTOMER_FILE, {
        variables: {
            collection_id,
        },
    })
}
