import React from 'react'

export enum PageKey {
    LOGIN = 'LOGIN',
    DASHBOARD = 'DASHBOARD',
    HOME = 'HOME',
    CUSTOMER_LIST = 'CUSTOMER_LIST',
    CUSTOMER_SESSION_LIST = 'CUSTOMER_SESSION_LIST',
    CUSTOMER_SESSION_ASSET_LIST = 'CUSTOMER_SESSION_ASSET_LIST',
    PROJECT_LIST = 'PROJECT_LIST',
    PROJECT_ASSET_LIST = 'PROJECT_ASSET_LIST',
    PORTFOLIO_LIST = 'PORTFOLIO_SECTION_LIST',
    PORTFOLIO_COLLECTION_LIST = 'PORTFOLIO_SECTION_COLLECTION_LIST',
    PORTFOLIO_COLLECTION_ASSET_LIST = 'PORTFOLIO_SECTION_COLLECTION_ASSET_LIST',
    CONTACT_ME = 'CONTACT_ME',
}

interface Page {
    pageKey: PageKey
}

export const PageContext = React.createContext<Page>({} as Page)
