import React, { useMemo } from 'react'
import { IndividualReportProps } from './Reports'
import { LineChart, XAxis, Tooltip, CartesianGrid, Line, YAxis, Legend, ResponsiveContainer } from 'recharts'
import { subDays, format } from 'date-fns'
import { parseReportResponse, groupByCountry, parseStandardReport, ReportValues } from '../../util/googleAnalyticsReport'

const REPORT_N_DAYS = 7
const dates = new Array(REPORT_N_DAYS)
    .fill(null)
    .map((_, i) => subDays(new Date(), i + 1))
    .reverse()

const countryColours = [
    '#3366cc',
    '#dc3912',
    '#ff9900',
    '#109618',
    '#990099',
    '#0099c6',
    '#dd4477',
    '#66aa00',
    '#b82e2e',
    '#316395',
    '#3366cc',
    '#994499',
    '#22aa99',
    '#aaaa11',
    '#6633cc',
    '#e67300',
    '#8b0707',
    '#651067',
    '#329262',
    '#5574a6',
    '#3b3eac',
    '#b77322',
    '#16d620',
    '#b91383',
    '#f4359e',
    '#9c5935',
    '#a9c413',
    '#2a778d',
    '#668d1c',
    '#bea413',
    '#0c5922',
    '#743411',
]

export default function ReportUsers({ report }: IndividualReportProps) {
    const parsedReport = useMemo(() => parseReportResponse(report), [report])
    const userReport = useMemo(() => parseStandardReport(parsedReport, ['ga:date', 'ga:country']), [parsedReport]) as Map<
        string,
        Map<string, ReportValues>
    >
    const countries = useMemo(() => Object.keys(groupByCountry(parsedReport)), [parsedReport])
    const data = useMemo(
        () =>
            dates.map(date => {
                const formattedDate = format(date, 'yyyyMMdd')
                const reportsByDate = userReport.get(formattedDate)
                return {
                    date: format(date, 'E, d MMM yy'),
                    ...Array.from(reportsByDate?.keys() || []).reduce(
                        (reports, country) => ({
                            ...reports,
                            [country]: reportsByDate?.get(country),
                        }),
                        {}
                    ),
                }
            }),
        [userReport]
    )
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-header-title is-size-5">Users by country (up to yesterday)</h3>
            </div>
            <div className="card-content">
                <ResponsiveContainer height={400}>
                    <LineChart data={data}>
                        <XAxis dataKey="date" />
                        <YAxis allowDecimals={false} />
                        <Tooltip />
                        <Legend />
                        <CartesianGrid stroke="#f5f5f5" />
                        {countries.map((country, index) => (
                            <Line
                                key={country}
                                name={country}
                                type="monotone"
                                dataKey={row => (row[country] || {})['ga:users'] || 0}
                                stroke={countryColours[index % countryColours.length]}
                            />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}
