import { useState, useCallback } from 'react'
import nanoid from 'nanoid'
import { Storage } from 'aws-amplify'
import { useDropzone } from 'react-dropzone'
import head from 'lodash.head'

interface UploaderArgs {
    onCompleted?: () => void
    onError?: (e: any) => void
}

export interface UploadArgs {
    handler: string
    id: string
    file: File
    access_level: 'private' | 'public'
    disablePrefix?: boolean
    folderPrefix?: string
}

export function useUploader({ onCompleted, onError }: UploaderArgs) {
    const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined)
    const [called, setCalled] = useState(false)
    const upload = useCallback(
        async ({ handler, id, file, access_level, disablePrefix, folderPrefix }: UploadArgs) => {
            setCalled(true)
            try {
                const filePath = `${handler.toLowerCase()}/${folderPrefix ? `${folderPrefix}/` : ''}${disablePrefix ? '' : `${nanoid(10)}-`}${
                    file.name
                }`
                await Storage.put(`${filePath}`, file, {
                    level: 'protected',
                    contentType: file.type,
                    metadata: {
                        handler,
                        id,
                        access_level,
                    },
                    progressCallback: (progress: { loaded: number; total: number }) => {
                        setUploadProgress(parseFloat(((progress.loaded / progress.total) * 100).toFixed(2)))
                    },
                })
                if (onCompleted) {
                    onCompleted()
                }
            } catch (e) {
                if (onError) {
                    onError(e)
                }
            }
        },
        [onCompleted, onError]
    )
    const resetProgress = useCallback(() => setUploadProgress(undefined), [])
    return { upload, uploadProgress, called, resetProgress }
}

interface PosterUploaderArgs {
    handler: string
    id: string
    originalUrl?: string
    noClick?: boolean
}

export function useImmediateUploader({ handler, id, originalUrl, noClick }: PosterUploaderArgs) {
    const [outputUrl, setOutputUrl] = useState<string | undefined>(originalUrl)
    const { upload, uploadProgress, resetProgress } = useUploader({
        onCompleted: () => {
            resetProgress()
        },
    })
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            const file = head(acceptedFiles)
            if (file) {
                setOutputUrl(URL.createObjectURL(file))
                upload({ handler, id, file, access_level: 'public' })
            }
        },
        [handler, id, upload]
    )
    const dropzone = useDropzone({
        onDrop,
        accept: ['image/*'],
        noClick: noClick || !outputUrl,
    })
    return { uploadProgress, outputUrl, ...dropzone }
}
