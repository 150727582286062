import React from 'react'
import { classes, stylesheet } from 'typestyle'
import ParticleImage, { forces, ParticleOptions } from 'react-particle-image'

const styles = stylesheet({
    bannerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
})

const particleOptions: ParticleOptions = {
    filter: ({ x, y, image }) => {
        const pixel = image.get(x, y)
        return pixel.a > 0
    },
    mass: () => 40,
    friction: () => 0.15,
}
const motionForce = (x: number, y: number) => {
    return forces.disturbance(x, y, 7)
}

export default function Banner() {
    return (
        <section className="hero is-fullheight has-background-black">
            <figure className="image is-1by1">
                <div className={classes('has-ratio is-flex', styles.bannerContainer)}>
                    <ParticleImage
                        src="/logo.png"
                        backgroundColor="rgba(0, 0, 0, 0)"
                        scale={0.75}
                        entropy={20}
                        maxParticles={4200}
                        mouseMoveForce={motionForce}
                        touchMoveForce={motionForce}
                        particleOptions={particleOptions}
                    />
                </div>
            </figure>
        </section>
    )
}
