import React, { useMemo } from 'react'
import { DragPlaceholder, useSortKey, Sortable } from '../../components/Sortable'
import { CollectionThumbnailPreview } from '../allsite/CollectionThumbnail'
import { useProfileSortLatestWork } from '../../graphql/useProfile'
import { LatestWorkCollection } from 'vo-components'
import { BasicModalProps } from '../../models/ModalModel'
import SortingPanel from '../../components/SortingPanel'

const PAGE_KEY = 'LATEST_WORKS'

interface LatestWorkItemProps extends Sortable {
    latestWork: LatestWorkCollection
    latestWorks: LatestWorkCollection[]
}

function LatestWorkItem({ latestWorks, latestWork, placeholder, previousSortKey, nextSortKey }: LatestWorkItemProps) {
    const candidateSortKey = useSortKey({ originalSortKey: latestWork.sort_key || '', previousSortKey, nextSortKey, skip: !placeholder })
    const [sort] = useProfileSortLatestWork({
        pageKey: PAGE_KEY,
        latestWorks: latestWorks.map(item => ({
            ...item,
            sort_key: item.collection_id === latestWork.collection_id ? candidateSortKey : item.sort_key,
        })),
    })
    if (placeholder) {
        return <DragPlaceholder mutate={sort}>{ref => <div ref={ref} className="is-overlay" />}</DragPlaceholder>
    }
    if (!latestWork.collection) return null
    return <CollectionThumbnailPreview collection={latestWork.collection} />
}

interface LatestWorksSortingPanel extends BasicModalProps {
    items: LatestWorkCollection[]
}

export default function LatestWorksSortingPanel({ isOpen, onClose, items }: LatestWorksSortingPanel) {
    const filteredItems = useMemo(() => items.filter(latestWork => !!latestWork.collection), [items])
    return (
        <SortingPanel<LatestWorkCollection>
            isOpen={isOpen}
            onClose={onClose}
            title="Sort your latest works"
            type="LatestWork"
            items={filteredItems}
            getIdFromItem={latestWork => latestWork.collection_id}
            renderPlaceholder={(item, index, sortedItems) => (
                <LatestWorkItem
                    latestWorks={filteredItems}
                    latestWork={item}
                    placeholder
                    previousSortKey={(index !== 0 && sortedItems[index - 1].sort_key) || undefined}
                    nextSortKey={(index !== sortedItems.length - 1 && sortedItems[index + 1].sort_key) || undefined}
                />
            )}
            renderItem={item => <LatestWorkItem latestWorks={filteredItems} latestWork={item} />}
        />
    )
}
