import React from 'react'
import BannerItem from './BannerItem'
import { BasicModalProps } from '../../models/ModalModel'
import { Banner } from 'vo-components'
import SortingPanel from '../../components/SortingPanel'

interface BannerSortingPanelProps extends BasicModalProps {
    items: Banner[]
}

export default function BannersSortingPanel({ isOpen, onClose, items }: BannerSortingPanelProps) {
    return (
        <SortingPanel<Banner>
            isOpen={isOpen}
            onClose={onClose}
            title="Sort your banners"
            items={items}
            type="Banner"
            getIdFromItem={banner => banner.banner_id}
            renderPlaceholder={(item, index, sortedItems) => (
                <BannerItem
                    banner={item}
                    placeholder
                    previousSortKey={(index !== 0 && sortedItems[index - 1].sort_key) || undefined}
                    nextSortKey={(index !== sortedItems.length - 1 && sortedItems[index + 1].sort_key) || undefined}
                />
            )}
            renderItem={item => <BannerItem isPreviewOnly banner={item} />}
        />
    )
}
