import React from 'react'
import CollectionAssets from './CollectionAssets'
import CollectionDetail from './CollectionDetail'
import { defaultPhotoSegmentLayoutConfig, defaultVideoSegmentLayoutConfig } from './CollectionAssetsSegment'
import { CollectionAssetType } from 'vo-components'

export default function PageCollectionAssetList() {
    return (
        <>
            <CollectionDetail assetTypes={[CollectionAssetType.Image, CollectionAssetType.Video]}>
                <CollectionAssets photoConfig={defaultPhotoSegmentLayoutConfig} videoConfig={defaultVideoSegmentLayoutConfig} />
            </CollectionDetail>
        </>
    )
}
