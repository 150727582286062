import { useQuery, useMutation } from '@apollo/client'
import {
    LIST_BANNERS,
    DELETE_BANNER,
    UPDATE_BANNER,
    CREATE_BANNER,
    SORT_BANNER,
    MutationCreateBannerArgs,
    Banner,
    QueryListBannersArgs,
    Maybe,
    BannerConnection,
    BannerInput,
    MutationUpdateBannerArgs,
    MutationDeleteBannerArgs,
    MutationSortBannerArgs,
    Mutation,
} from 'vo-components'
import { FileUpload } from '../models/FileModel'
import { generateNextSortKey } from '../util/sortKey'
import { MutationArgs, baseFile } from './customTypes'

interface BannersCreateMutationArgs extends BannerInput, MutationArgs {
    file: FileUpload
    latestSortKey?: string
}

export function useBannersCreateMutation({ link, file, listing_status, sort_key, latestSortKey, onCompleted, onError }: BannersCreateMutationArgs) {
    const listingStatus = listing_status || 'HOME'
    const sortKey = sort_key || generateNextSortKey(latestSortKey)
    return useMutation<Partial<Mutation>, MutationCreateBannerArgs>(CREATE_BANNER, {
        onCompleted,
        onError,
        variables: {
            input: {
                listing_status: listingStatus,
                sort_key: sortKey,
                link,
            },
        },
        update: (cache, { data: newData }) => {
            if (!newData?.createBanner) return
            try {
                const data = cache.readQuery<{ listBanners: Maybe<BannerConnection> }, QueryListBannersArgs>({
                    query: LIST_BANNERS,
                    variables: { listing_status: listingStatus },
                })
                cache.writeQuery<{ listBanners: Maybe<BannerConnection> }, QueryListBannersArgs>({
                    query: LIST_BANNERS,
                    variables: { listing_status: listingStatus },
                    data: {
                        ...data,
                        listBanners: {
                            ...data?.listBanners,
                            items: [
                                ...(data?.listBanners?.items || []),
                                {
                                    ...newData.createBanner,
                                    file: {
                                        ...baseFile,
                                        public_url: file.previewUrl,
                                        contentType: file.file?.type,
                                    },
                                },
                            ],
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useBannersCreateMutation`')
            }
        },
    })
}

interface BannersUpdateMutationArgs extends BannerInput, MutationArgs {
    banner: Banner
}

export function useBannersUpdateMutation({ banner, link, onCompleted, onError }: BannersUpdateMutationArgs) {
    const listingStatus = banner.listing_status || 'HOME'
    return useMutation<Partial<Mutation>, MutationUpdateBannerArgs>(UPDATE_BANNER, {
        onCompleted,
        onError,
        variables: {
            banner_id: banner.banner_id || '',
            input: {
                sort_key: banner.sort_key,
                link,
            },
        },
        update: (cache, { data: newData }) => {
            if (!newData?.updateBanner) return
            try {
                const updatedBanner = newData.updateBanner
                const data = cache.readQuery<{ listBanners: Maybe<BannerConnection> }, QueryListBannersArgs>({
                    query: LIST_BANNERS,
                    variables: { listing_status: listingStatus },
                })
                cache.writeQuery<{ listBanners: Maybe<BannerConnection> }, QueryListBannersArgs>({
                    query: LIST_BANNERS,
                    variables: { listing_status: listingStatus },
                    data: {
                        ...data,
                        listBanners: {
                            ...data?.listBanners,
                            items:
                                data?.listBanners?.items.map(bannerItem =>
                                    bannerItem.banner_id === banner.banner_id ? updatedBanner : bannerItem
                                ) || [],
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useBannersUpdateMutation`')
            }
        },
    })
}

interface BannersDeleteMutationArgs extends MutationArgs {
    banner: Banner
}

export function useBannersDeleteMutation({ banner, onCompleted, onError }: BannersDeleteMutationArgs) {
    const listingStatus = banner.listing_status || 'HOME'
    return useMutation<{ deleteBanner: Banner }, MutationDeleteBannerArgs>(DELETE_BANNER, {
        onCompleted,
        onError,
        variables: {
            banner_id: banner.banner_id,
        },
        update: (cache, { data: deletedBanner }) => {
            if (!deletedBanner) return
            try {
                const data = cache.readQuery<{ listBanners: Maybe<BannerConnection> }, QueryListBannersArgs>({
                    query: LIST_BANNERS,
                    variables: { listing_status: listingStatus },
                })
                cache.writeQuery<{ listBanners: Maybe<BannerConnection> }, QueryListBannersArgs>({
                    query: LIST_BANNERS,
                    variables: { listing_status: listingStatus },
                    data: {
                        ...data,
                        listBanners: {
                            ...data?.listBanners,
                            items: data?.listBanners?.items.filter(banner => banner.banner_id !== deletedBanner.deleteBanner.banner_id) || [],
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useBannersDeleteMutation`')
            }
        },
    })
}

interface BannersSortMutationArgs extends MutationArgs {
    banner: Banner
    sortKey: string
}

export function useBannersSortMutation({ banner, sortKey, onCompleted, onError }: BannersSortMutationArgs) {
    const listingStatus = banner.listing_status || 'HOME'
    return useMutation<Partial<Mutation>, MutationSortBannerArgs>(SORT_BANNER, {
        onCompleted,
        onError,
        variables: {
            banner_id: banner.banner_id,
            sort_key: sortKey,
        },
        update: (cache, { data: newData }) => {
            if (!newData?.sortBanner) return
            try {
                const data = cache.readQuery<{ listBanners: Maybe<BannerConnection> }, QueryListBannersArgs>({
                    query: LIST_BANNERS,
                    variables: { listing_status: listingStatus },
                })
                cache.writeQuery<{ listBanners: Maybe<BannerConnection> }, QueryListBannersArgs>({
                    query: LIST_BANNERS,
                    variables: { listing_status: listingStatus },
                    data: {
                        ...data,
                        listBanners: {
                            ...data?.listBanners,
                            items:
                                data?.listBanners?.items.map(bannerItem =>
                                    bannerItem.banner_id === banner.banner_id
                                        ? {
                                              ...bannerItem,
                                              ...newData.sortBanner,
                                          }
                                        : bannerItem
                                ) || [],
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `useBannersSortMutation`')
            }
        },
        optimisticResponse: {
            __typename: 'Mutation',
            sortBanner: {
                __typename: 'Banner',
                ...banner,
                sort_key: sortKey,
            },
        },
    })
}

interface BannersQueryArgs {
    listingStatus?: string
}

export function useBannersQuery({ listingStatus }: BannersQueryArgs) {
    return useQuery<{ listBanners: Maybe<BannerConnection> }, QueryListBannersArgs>(LIST_BANNERS, {
        variables: { listing_status: listingStatus || 'HOME' },
    })
}
