import React, { useMemo } from 'react'
import { Modal, Theme, CollectionAsset, DynamicImage } from 'vo-components'
import { classes, stylesheet } from 'typestyle'
import { em, viewWidth, percent, important } from 'csx'
import { BasicModalProps } from '../../models/ModalModel'
import orderBy from 'lodash.orderby'

const styles = stylesheet({
    modal: {
        width: viewWidth(50),
    },
    container: {
        backgroundColor: Theme.colors.white,
        height: percent(100),
        padding: `${em(1)} ${em(1)}`,
    },
    mediaImage: {
        marginBottom: important(0),
    },
})

interface ModalAssetSegmentListProps extends BasicModalProps {
    assets: CollectionAsset[]
}

export default function ModalAssetSegmentList({ isOpen, onClose, assets }: ModalAssetSegmentListProps) {
    const sortedAssets = useMemo(() => orderBy(assets, ['file.original_s3_key'], ['asc']), [assets])
    return (
        <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
            <div className={classes('content', styles.container)}>
                <h1 className="is-size-4">File List ({sortedAssets.length})</h1>
                {sortedAssets.map((asset, index) => (
                    <article key={asset.asset_id} className="media">
                        <figure className={classes('media-left', styles.mediaImage)}>
                            <p className="image is-64x64">
                                <DynamicImage src={asset.file?.public_url || ''} width={120} />
                            </p>
                        </figure>
                        <div className="media-content">
                            <div className="content">
                                <p>
                                    {asset.file?.original_s3_key
                                        ?.split('/')
                                        // just extract file name
                                        .pop()}
                                </p>
                            </div>
                        </div>
                        <div className="media-right">
                            <span>{index + 1}</span>
                        </div>
                    </article>
                ))}
            </div>
        </Modal>
    )
}
