import { LexoRank } from 'lexorank'

export function generateNextSortKey(latestSortKey?: string) {
    let sortKey = LexoRank.middle().toString()
    if (latestSortKey) {
        try {
            sortKey = LexoRank.parse(latestSortKey)
                .genNext()
                .toString()
        } catch (e) {
            // ignore. not a lexorank
        }
    }
    return sortKey
}
