import React from 'react'
import PortfolioSectionItem from './PortfolioSectionItem'
import { BasicModalProps } from '../../models/ModalModel'
import { PortfolioSection } from 'vo-components'
import SortingPanel from '../../components/SortingPanel'

interface PortfolioSectionsSortingPanelProps extends BasicModalProps {
    items: PortfolioSection[]
}

export default function PortfolioSectionsSortingPanel({ isOpen, onClose, items }: PortfolioSectionsSortingPanelProps) {
    return (
        <SortingPanel<PortfolioSection>
            isOpen={isOpen}
            onClose={onClose}
            title="Sort your portfolio sections"
            type="PortfolioSection"
            items={items}
            getIdFromItem={portfolioSection => portfolioSection.portfolio_section_key}
            renderPlaceholder={(item, index, sortedItems) => (
                <PortfolioSectionItem
                    portfolioSection={item}
                    placeholder
                    previousSortKey={(index !== 0 && sortedItems[index - 1].sort_key) || undefined}
                    nextSortKey={(index !== sortedItems.length - 1 && sortedItems[index + 1].sort_key) || undefined}
                />
            )}
            renderItem={item => <PortfolioSectionItem isPreviewOnly portfolioSection={item} />}
        />
    )
}
