import React, { useState, useCallback, useMemo } from 'react'
import useCtrlPressed from './useCtrlPressed'

interface MultiSelectContext {
    isCtrlPressed: boolean
    selectedItems: string[]
    registerItem: (item: string) => void
    removeItem: (item: string) => void
    setSelectedItems: (selectedItems: string[]) => void
}

export const MultiSelectContext = React.createContext<MultiSelectContext>({} as MultiSelectContext)

interface MultiSelectContainerProps {
    children: React.ReactNode
}

export default function MultiSelectContainer({ children }: MultiSelectContainerProps) {
    const [selectedItems, setSelectedItems] = useState<string[]>([])
    const isCtrlPressed = useCtrlPressed()
    const registerItem = useCallback((item: string) => setSelectedItems(items => [...items, item]), [])
    const removeItem = useCallback((item: string) => setSelectedItems(items => items.filter(originalItem => originalItem !== item)), [])
    const contextValue = useMemo(() => ({ isCtrlPressed, selectedItems, registerItem, removeItem, setSelectedItems }), [
        isCtrlPressed,
        selectedItems,
        registerItem,
        removeItem,
        setSelectedItems,
    ])
    return <MultiSelectContext.Provider value={contextValue}>{children}</MultiSelectContext.Provider>
}
