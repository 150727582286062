import React from 'react'
import { Modal, Theme } from 'vo-components'
import { classes, stylesheet } from 'typestyle'
import { viewWidth, translateX } from 'csx'
import { BasicModalProps } from '../models/ModalModel'
import { SortableProvider, Sortable, useSortable, SortableItem } from './Sortable'

const styles = stylesheet({
    modal: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        maxWidth: viewWidth(50),
        width: 750,
        borderRadius: 0,
        transform: translateX('-100%'),
        transition: 'transform 600ms ease-in-out',
        overflow: 'visible',
        $nest: {
            '&.ReactModal__Content--after-open': {
                transform: translateX(0),
            },
            '&.ReactModal__Content--before-close': {
                transform: translateX('-100%'),
            },
            '& .modal-close:before,& .modal-close:after': {
                backgroundColor: Theme.colors.greyDarker,
            },
        },
    },
    overlay: {
        backgroundColor: Theme.colors.overlayLight,
    },
    dragPlaceholder: {
        position: 'relative',
        borderColor: Theme.colors.info,
        borderRadius: 5,
        borderWidth: 4,
        borderStyle: 'solid',
        backgroundColor: Theme.colors.infoFade,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        minHeight: 100,
    },
    sortingContainer: {
        cursor: 'move',
    },
})

interface SortingPanelProps<T> extends BasicModalProps {
    title?: string
    items: T[]
    type: string
    renderPlaceholder: (item: T, index: number, items: T[]) => React.ReactNode
    renderItem: (item: T, index: number, items: T[]) => React.ReactNode
    getIdFromItem: (item: T) => string
}

export default function SortingPanel<T>({ title, isOpen, onClose, items, type, renderPlaceholder, renderItem, getIdFromItem }: SortingPanelProps<T>) {
    const { onDrag, sortedItems } = useSortable<T & Sortable>(items)
    return (
        <Modal isOpen={isOpen} isBodyScrollable onClose={onClose} className={styles.modal} overlayClassName={styles.overlay} closeTimeoutMS={600}>
            <div className="section has-background-white">
                {title && <h3 className="is-size-3 has-text-centered title">{title}</h3>}
                <SortableProvider<T & Sortable> type={type} onDrag={onDrag}>
                    <div className="columns is-multiline">
                        {sortedItems.map((item, index) => {
                            if (item.placeholder) {
                                return (
                                    <div key={`drag-placeholder-${getIdFromItem(item)}`} className={classes('column is-4', styles.dragPlaceholder)}>
                                        {renderPlaceholder(item, index, sortedItems)}
                                    </div>
                                )
                            }
                            return (
                                <SortableItem id={getIdFromItem(item)} originalItem={item} index={index} key={getIdFromItem(item)}>
                                    {ref => (
                                        <div ref={ref} className={classes('column is-4', styles.sortingContainer)}>
                                            {renderItem(item, index, sortedItems)}
                                        </div>
                                    )}
                                </SortableItem>
                            )
                        })}
                    </div>
                </SortableProvider>
            </div>
        </Modal>
    )
}
