import React, { useContext } from 'react'
import { CollectionDetailContext } from './CollectionDetail'
import { CollectionAsset, useColumns, calculateColumnOriginalIndex } from 'vo-components'
import CollectionAssetItem from './CollectionAssetItem'
import { CollectionAssetsSegmentContext } from './CollectionAssetsSegment'

export enum CollectionAssetsLayoutType {
    MASONRY = 'MASONRY',
    DEFAULT = 'DEFAULT',
}

interface CollectionAssetsLayoutProps {
    layoutType?: CollectionAssetsLayoutType
}

export default function CollectionAssetsLayout({ layoutType }: CollectionAssetsLayoutProps) {
    const finalLayoutType = layoutType || CollectionAssetsLayoutType.DEFAULT
    return (
        <>
            {finalLayoutType === CollectionAssetsLayoutType.MASONRY && <MasonryLayout />}
            {finalLayoutType === CollectionAssetsLayoutType.DEFAULT && <DefaultLayout />}
        </>
    )
}

function DefaultLayout() {
    const { assets } = useContext(CollectionAssetsSegmentContext)
    const { collection } = useContext(CollectionDetailContext)
    return (
        <div className="columns is-multiline is-centered">
            {assets.map((asset, idx) => (
                <div key={asset.asset_id} className="column is-6">
                    <CollectionAssetItem collection={collection} asset={asset} index={idx} />
                </div>
            ))}
        </div>
    )
}

function MasonryLayout() {
    const { assets } = useContext(CollectionAssetsSegmentContext)
    const { collection } = useContext(CollectionDetailContext)
    const { chunkedItems, columnCount } = useColumns<CollectionAsset>({ items: assets })
    return (
        <div className="columns is-multiline is-variable is-1-mobile is-3-desktop">
            {chunkedItems.map((assetsColumn, chunkIdx) => (
                <div key={`chunk-${chunkIdx}`} className="column is-6-tablet is-4-desktop">
                    {assetsColumn.map((asset, itemIdx) => (
                        <CollectionAssetItem
                            key={asset.asset_id}
                            collection={collection}
                            asset={asset}
                            index={calculateColumnOriginalIndex(chunkIdx, itemIdx, columnCount)}
                        />
                    ))}
                </div>
            ))}
        </div>
    )
}
