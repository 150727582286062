import React from 'react'
import { useCustomersQuery } from '../../graphql/useCustomers'
import Error from '../../components/Error'
import Customers from './Customers'

export default function PageCustomerList() {
    const { loading, error, data, refetch } = useCustomersQuery({ listingStatus: 'DEFAULT' })
    const { items } = data?.listCustomers || {}
    if (error) {
        return (
            <div className="section">
                <div className="container">
                    <Error errorTrace={error} retry={refetch} />
                </div>
            </div>
        )
    }
    if (loading) {
        return null
    }
    return (
        <div className="section">
            <div className="container">
                <Customers customers={items || []} />
            </div>
        </div>
    )
}
