import React from 'react'
import Reports from './Reports'

export default function PageDashboard() {
    return (
        <>
            <div className="section">
                <div className="container">
                    <Reports />
                </div>
            </div>
        </>
    )
}
