import { useQuery, useMutation } from '@apollo/client'
import { generateNextSortKey } from '../util/sortKey'
import {
    LIST_PORTFOLIO_SECTIONS,
    SHOW_SLUG,
    DELETE_PORTFOLIO_SECTION,
    CREATE_PORTFOLIO_SECTION,
    UPDATE_PORTFOLIO_SECTION,
    UPDATE_SLUG,
    SORT_PORTFOLIO_SECTION,
    PortfolioSectionInput,
    MutationCreatePortfolioSectionArgs,
    Mutation,
    Maybe,
    PortfolioSectionConnection,
    QueryListPortfolioSectionsArgs,
    PortfolioSection,
    MutationUpdatePortfolioSectionArgs,
    MutationDeletePortfolioSectionArgs,
    MutationUpdateSlugArgs,
    SlugType,
    MutationSortPortfolioSectionArgs,
    Slug,
    QueryShowSlugArgs,
} from 'vo-components'
import { MutationArgs, baseFile } from './customTypes'
import { FileUpload } from '../models/FileModel'

interface PortfolioSectionsCreateMutationArgs extends PortfolioSectionInput, MutationArgs {
    file?: FileUpload
    latestSortKey?: string
}

export function usePortfolioSectionCreateMutation({ name, file, latestSortKey, onCompleted, onError }: PortfolioSectionsCreateMutationArgs) {
    const sortKey = generateNextSortKey(latestSortKey)
    return useMutation<Partial<Mutation>, MutationCreatePortfolioSectionArgs>(CREATE_PORTFOLIO_SECTION, {
        onCompleted,
        onError,
        variables: {
            input: {
                listing_status: 'ALL',
                sort_key: sortKey,
                name,
            },
        },
        update: (cache, { data: newData }) => {
            if (!newData?.createPortfolioSection) return
            try {
                const data = cache.readQuery<{ listPortfolioSections: Maybe<PortfolioSectionConnection> }, QueryListPortfolioSectionsArgs>({
                    query: LIST_PORTFOLIO_SECTIONS,
                    variables: { listing_status: 'ALL' },
                })
                if (!data?.listPortfolioSections) return
                cache.writeQuery<{ listPortfolioSections: Maybe<PortfolioSectionConnection> }, QueryListPortfolioSectionsArgs>({
                    query: LIST_PORTFOLIO_SECTIONS,
                    variables: { listing_status: 'ALL' },
                    data: {
                        ...data,
                        listPortfolioSections: {
                            ...data.listPortfolioSections,
                            items: [
                                ...data.listPortfolioSections.items,
                                {
                                    ...(newData?.createPortfolioSection as PortfolioSection),
                                    banner: file?.file && {
                                        ...baseFile,
                                        public_url: file.previewUrl,
                                        contentType: file.file.type,
                                        customisation: {
                                            __typename: 'FileCustomisation',
                                            backgroundPosition: 'center center',
                                            ...file.fileCustomisations,
                                        },
                                    },
                                },
                            ],
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `usePortfolioSectionCreateMutation`')
            }
        },
    })
}

interface PortfolioSectionsUpdateMutationArgs extends PortfolioSectionInput, MutationArgs {
    portfolioSection?: PortfolioSection
    file?: FileUpload
    latestSortKey?: string
}

export function usePortfolioSectionUpdateMutation({ portfolioSection, name, file, onCompleted, onError }: PortfolioSectionsUpdateMutationArgs) {
    return useMutation<Partial<Mutation>, MutationUpdatePortfolioSectionArgs>(UPDATE_PORTFOLIO_SECTION, {
        onCompleted,
        onError,
        variables: {
            portfolio_section_key: portfolioSection?.portfolio_section_key || '',
            input: {
                listing_status: portfolioSection?.listing_status,
                sort_key: portfolioSection?.sort_key,
                name,
            },
        },
        update: (cache, { data: newData }) => {
            if (!newData?.updatePortfolioSection || !portfolioSection) return
            try {
                const data = cache.readQuery<{ listPortfolioSections: Maybe<PortfolioSectionConnection> }, QueryListPortfolioSectionsArgs>({
                    query: LIST_PORTFOLIO_SECTIONS,
                    variables: { listing_status: 'ALL' },
                })
                if (!data?.listPortfolioSections) return
                cache.writeQuery<{ listPortfolioSections: Maybe<PortfolioSectionConnection> }, QueryListPortfolioSectionsArgs>({
                    query: LIST_PORTFOLIO_SECTIONS,
                    variables: { listing_status: 'ALL' },
                    data: {
                        ...data,
                        listPortfolioSections: {
                            ...data.listPortfolioSections,
                            items:
                                data.listPortfolioSections.items.map(portfolioSectionItem =>
                                    portfolioSectionItem.portfolio_section_key === portfolioSection.portfolio_section_key
                                        ? {
                                              ...(newData.updatePortfolioSection as PortfolioSection),
                                              banner: file?.file
                                                  ? {
                                                        ...baseFile,
                                                        public_url: file.previewUrl,
                                                        contentType: file.file.type,
                                                        customisation: {
                                                            __typename: 'FileCustomisation',
                                                            backgroundPosition: 'center center',
                                                            ...file.fileCustomisations,
                                                        },
                                                    }
                                                  : newData.updatePortfolioSection?.banner,
                                          }
                                        : portfolioSectionItem
                                ) || [],
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `usePortfolioSectionUpdateMutation`')
            }
        },
    })
}

interface PortfolioSectionsUpdateSlugMutationArgs extends MutationArgs {
    portfolioSection: PortfolioSection
    slug: string
}

export function usePortfolioSectionsUpdateSlugMutation({ portfolioSection, slug, onCompleted, onError }: PortfolioSectionsUpdateSlugMutationArgs) {
    return useMutation<Partial<Mutation>, MutationUpdateSlugArgs>(UPDATE_SLUG, {
        onCompleted,
        onError,
        variables: {
            input: {
                slug,
                type: SlugType.PortfolioSection,
                resource: portfolioSection.portfolio_section_key,
            },
        },
        update: (cache, { data: newData }) => {
            if (!newData?.updateSlug) return
            try {
                const data = cache.readQuery<{ listPortfolioSections: Maybe<PortfolioSectionConnection> }, QueryListPortfolioSectionsArgs>({
                    query: LIST_PORTFOLIO_SECTIONS,
                    variables: { listing_status: 'ALL' },
                })
                if (!data?.listPortfolioSections) return
                cache.writeQuery<{ listPortfolioSections: Maybe<PortfolioSectionConnection> }, QueryListPortfolioSectionsArgs>({
                    query: LIST_PORTFOLIO_SECTIONS,
                    variables: { listing_status: 'ALL' },
                    data: {
                        ...data,
                        listPortfolioSections: {
                            ...data.listPortfolioSections,
                            items: data.listPortfolioSections.items.map(item => ({
                                ...item,
                                slug: item.portfolio_section_key === portfolioSection.portfolio_section_key ? newData.updateSlug?.slug : item.slug,
                            })),
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `usePortfolioSectionsUpdateSlugMutation`')
            }
        },
    })
}

interface PortfolioSectionsSortMutationArgs extends MutationArgs {
    portfolioSection: PortfolioSection
    sortKey: string
}

export function usePortfolioSectionsSortMutation({ portfolioSection, sortKey }: PortfolioSectionsSortMutationArgs) {
    return useMutation<Partial<Mutation>, MutationSortPortfolioSectionArgs>(SORT_PORTFOLIO_SECTION, {
        variables: {
            portfolio_section_key: portfolioSection.portfolio_section_key,
            sort_key: sortKey,
        },
        update: (cache, { data: newData }) => {
            if (!newData?.sortPortfolioSection) return
            try {
                const data = cache.readQuery<{ listPortfolioSections: Maybe<PortfolioSectionConnection> }, QueryListPortfolioSectionsArgs>({
                    query: LIST_PORTFOLIO_SECTIONS,
                    variables: { listing_status: 'ALL' },
                })
                if (!data?.listPortfolioSections) return
                cache.writeQuery<{ listPortfolioSections: Maybe<PortfolioSectionConnection> }, QueryListPortfolioSectionsArgs>({
                    query: LIST_PORTFOLIO_SECTIONS,
                    variables: { listing_status: 'ALL' },
                    data: {
                        ...data,
                        listPortfolioSections: {
                            ...data.listPortfolioSections,
                            items: data.listPortfolioSections.items.map(portfolioSectionItem =>
                                portfolioSectionItem.portfolio_section_key === portfolioSection.portfolio_section_key
                                    ? {
                                          ...portfolioSectionItem,
                                          ...newData.sortPortfolioSection,
                                      }
                                    : portfolioSectionItem
                            ),
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `usePortfolioSectionsSortMutation`')
            }
        },
        optimisticResponse: {
            __typename: 'Mutation',
            sortPortfolioSection: {
                __typename: 'PortfolioSection',
                ...portfolioSection,
                sort_key: sortKey,
            },
        },
    })
}

interface PortfolioSectionsDeleteMutationArgs extends MutationArgs {
    portfolioSection: PortfolioSection
}

export function usePortfolioSectionsDeleteMutation({ portfolioSection, onCompleted, onError }: PortfolioSectionsDeleteMutationArgs) {
    return useMutation<Partial<Mutation>, MutationDeletePortfolioSectionArgs>(DELETE_PORTFOLIO_SECTION, {
        onCompleted,
        onError,
        variables: {
            portfolio_section_key: portfolioSection.portfolio_section_key,
        },
        update: cache => {
            try {
                const data = cache.readQuery<{ listPortfolioSections: Maybe<PortfolioSectionConnection> }, QueryListPortfolioSectionsArgs>({
                    query: LIST_PORTFOLIO_SECTIONS,
                    variables: { listing_status: 'ALL' },
                })
                if (!data?.listPortfolioSections) return
                cache.writeQuery<{ listPortfolioSections: Maybe<PortfolioSectionConnection> }, QueryListPortfolioSectionsArgs>({
                    query: LIST_PORTFOLIO_SECTIONS,
                    variables: { listing_status: 'ALL' },
                    data: {
                        ...data,
                        listPortfolioSections: {
                            ...data.listPortfolioSections,
                            items: data.listPortfolioSections.items.filter(
                                portfolioSectionItem => portfolioSectionItem.portfolio_section_key !== portfolioSection.portfolio_section_key
                            ),
                        },
                    },
                })
            } catch (e) {
                console.debug('no cache to be updated for `usePortfolioSectionsDeleteMutation`')
            }
        },
    })
}

interface SlugPortfolioSectionQueryArgs {
    slug: string
}

export function usePortfolioSectionsSlugQuery({ slug }: SlugPortfolioSectionQueryArgs) {
    return useQuery<{ showSlug: Maybe<Slug> }, QueryShowSlugArgs>(SHOW_SLUG, {
        variables: { type: SlugType.PortfolioSection, slug },
    })
}

interface CollectionsQueryArgs {
    listingStatus?: string
}

export function usePortfolioSectionsQuery({ listingStatus }: CollectionsQueryArgs) {
    return useQuery<{ listPortfolioSections: Maybe<PortfolioSectionConnection> }, QueryListPortfolioSectionsArgs>(LIST_PORTFOLIO_SECTIONS, {
        variables: { listing_status: listingStatus || 'ALL' },
    })
}
