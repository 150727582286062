import React, { useState, useCallback, useMemo, useRef, useContext, useEffect } from 'react'
import { Modal, Theme, Mutation, FileCustomisation, PortfolioSection } from 'vo-components'
import { classes, stylesheet } from 'typestyle'
import { em, viewWidth, viewHeight, percent } from 'csx'
import { useDropzone } from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'
import head from 'lodash.head'
import { usePortfolioSectionUpdateMutation, usePortfolioSectionCreateMutation } from '../../graphql/usePortfolioSections'
import { useFormContext, useForm, FormContext } from 'react-hook-form'
import { BasicModalProps, ModalInnerFormProps, ModalFormProps, ModalFormMode } from '../../models/ModalModel'
import { FileUpload } from '../../models/FileModel'
import { useUploader } from '../../components/useUploader'

const styles = stylesheet({
    /**
     * Main Modal Styles
     */
    modal: {
        position: 'absolute',
        top: viewHeight(10),
        left: viewWidth(15),
        right: viewWidth(15),
        bottom: viewHeight(10),
    },
    container: {
        height: percent(100),
        padding: em(2),
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    formContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    formControls: {
        marginTop: em(0.5),
    },
    progressBar: {
        minWidth: 200,
        width: viewWidth(20),
    },
    submitInnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    /**
     * CoverImage styles
     */
    imageEmptyContainer: {
        borderWidth: 1,
        borderRadius: 5,
        borderStyle: 'dashed',
        borderColor: Theme.colors.greyLight,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: em(2),
        paddingBottom: em(2),
    },
    imageContainer: {
        alignSelf: 'center',
        width: 250,
    },
    image: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: percent(100),
        height: percent(100),
        overflow: 'hidden',
    },
    imageOverlay: {
        backgroundColor: Theme.colors.transparent,
        opacity: 0,
        transition: 'opacity 300ms ease-out',
        $nest: {
            '&:hover': {
                opacity: 1,
            },
        },
    },
    actionButtons: {
        position: 'absolute',
        top: 0,
        right: 0,
        paddingTop: em(0.5),
        paddingRight: em(0.5),
    },
    /**
     * Crop Styles
     */
    cropConfirmButtonsContainer: {
        marginTop: em(1),
    },
    cropConfirmButton: {
        flex: 1,
    },
})

interface PortfolioSectionModalContext extends BasicModalProps {
    file: FileUpload
    setFile: (file?: File) => void
    setFileCustomisations: (args: FileCustomisation) => void
    uploadProgress?: number
    onCompleted: (data: Partial<Mutation>) => void
}

const PortfolioSectionModalContext = React.createContext<PortfolioSectionModalContext>({} as PortfolioSectionModalContext)

interface PortfolioSectionFormModel {
    name: string
}

function CoverImage() {
    const {
        file: { previewUrl, file, fileCustomisations },
        setFile,
        setFileCustomisations,
    } = useContext(PortfolioSectionModalContext)
    const editorRef = useRef<AvatarEditor>(null)
    const [isCropMode, setIsCropMode] = useState(false)
    const [cropPosition, setCropPosition] = useState({ x: 0.5, y: 0.5 })
    const resetCrop = useCallback(() => {
        setCropPosition({ x: 0.5, y: 0.5 })
        setFileCustomisations({})
    }, [setCropPosition, setFileCustomisations])
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            // Do something with the files
            const acceptedFile = head(acceptedFiles)
            if (acceptedFile) {
                resetCrop()
                setFile(acceptedFile)
            }
        },
        [setFile, resetCrop]
    )
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        accept: ['image/*'],
        noClick: !file && !previewUrl,
    })
    if (file && isCropMode) {
        return (
            <>
                <AvatarEditor
                    ref={editorRef}
                    image={previewUrl}
                    width={250}
                    height={250}
                    border={0}
                    borderRadius={0}
                    position={cropPosition}
                    onPositionChange={setCropPosition}
                />
                <div className={classes('buttons is-flex', styles.cropConfirmButtonsContainer)}>
                    <button onClick={() => resetCrop()} className={classes('button is-danger', styles.cropConfirmButton)}>
                        Reset
                    </button>
                    <button
                        onClick={() => {
                            const cropRect = editorRef.current?.getCroppingRect()
                            if (!cropRect) return
                            // TODO: this is not perfect. Need to research on how to translate these variables into background position
                            const x = cropPosition.x === 0.5 ? 0.5 : cropPosition.x > 0.5 ? cropRect.x + cropRect.width : cropRect.x
                            const y = cropPosition.y === 0.5 ? 0.5 : cropPosition.y > 0.5 ? cropRect.y + cropRect.height : cropRect.y
                            setFileCustomisations({
                                ...fileCustomisations,
                                backgroundPosition: `${x * 100}% ${y * 100}%`,
                            })
                            setIsCropMode(false)
                        }}
                        className={classes('button is-success', styles.cropConfirmButton)}
                    >
                        OK
                    </button>
                </div>
            </>
        )
    }
    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {!file && !previewUrl && (
                <div
                    className={styles.imageEmptyContainer}
                    style={{
                        backgroundColor: isDragActive ? Theme.colors.whiteBis : 'transparent',
                    }}
                >
                    <p className="is-size-5 has-text-centered title">Drop an image for this section&apos;s cover picture</p>
                    <button onClick={() => open()} className="button is-info">
                        Select a file
                    </button>
                </div>
            )}
            {(file || previewUrl) && (
                <figure className="image is-1by1">
                    <div className="has-ratio is-clipped">
                        <div
                            style={{
                                backgroundPosition: fileCustomisations?.backgroundPosition || 'center center',
                                backgroundImage: `url(${previewUrl})`,
                            }}
                            className={styles.image}
                        />
                    </div>

                    {file && (
                        <div
                            className="is-overlay"
                            style={{
                                backgroundColor: isDragActive ? Theme.colors.overlayDark : 'transparent',
                            }}
                        >
                            <div className={classes('buttons', styles.actionButtons)}>
                                <button
                                    onClick={e => {
                                        e.stopPropagation()
                                        setIsCropMode(true)
                                    }}
                                    className="button is-small is-info"
                                >
                                    <span className="icon is-small">
                                        <i className="fas fa-crop" />
                                    </span>
                                </button>
                                <button
                                    onClick={e => {
                                        e.stopPropagation()
                                        resetCrop()
                                        setFile()
                                    }}
                                    className="button is-small is-danger"
                                >
                                    <span className="icon is-small">
                                        <i className="fas fa-trash" />
                                    </span>
                                </button>
                            </div>
                        </div>
                    )}
                </figure>
            )}
        </div>
    )
}

function PortfolioSectionForm({ write, loading, error }: ModalInnerFormProps) {
    const { isOpen, onClose, uploadProgress } = useContext(PortfolioSectionModalContext)
    const { register, errors, handleSubmit } = useFormContext<PortfolioSectionFormModel>()
    return (
        <Modal isOpen={isOpen} onClose={() => onClose()} className={styles.modal}>
            <div className={classes('columns', 'is-marginless', styles.container)}>
                <div className={classes('column', styles.innerContainer)}>
                    <div className={styles.imageContainer}>
                        <CoverImage />
                    </div>
                    <form
                        onSubmit={handleSubmit(() => {
                            write().catch(console.error)
                        })}
                        className={classes(styles.formContainer)}
                    >
                        <div>
                            <div className="field">
                                <label className="label">Name</label>
                                <div className="control">
                                    <input
                                        name="name"
                                        ref={register({ required: 'Please provide a name of your portfolio section' })}
                                        className={classes('input', errors.name && 'is-danger')}
                                        type="text"
                                        placeholder="Provide a name for this portfolio section. e.g. Wedding, Portrait, Documentary, etc."
                                    />
                                </div>
                                {errors.name && <p className="help is-danger">{errors.name.message}</p>}
                            </div>
                        </div>

                        <div className={styles.formControls}>
                            <div className="columns">
                                <div className={classes('column is-narrow', styles.submitInnerContainer)}>
                                    {error && <p className="has-text-danger">Whoops! Something went wrong</p>}
                                    {uploadProgress && (
                                        <>
                                            <p>
                                                uploading ({uploadProgress}
                                                %)...
                                            </p>
                                            <progress className={classes('progress is-primary', styles.progressBar)} value={uploadProgress} max="100">
                                                {uploadProgress}%
                                            </progress>
                                        </>
                                    )}
                                </div>
                                <div className="column">
                                    <div className="field is-grouped is-grouped-right">
                                        <p className="control">
                                            <button type="button" onClick={() => onClose()} className="button is-light">
                                                Cancel
                                            </button>
                                        </p>
                                        <p className="control">
                                            <button
                                                type="submit"
                                                className={classes('button is-primary', (loading || !!uploadProgress) && 'is-loading')}
                                            >
                                                Submit
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

interface PortfolioSectionUpdateProps {
    portfolioSection: PortfolioSection
}

function ModalPortfolioSectionUpdate({ portfolioSection }: PortfolioSectionUpdateProps) {
    const { onCompleted, file } = useContext(PortfolioSectionModalContext)
    const { watch } = useFormContext<PortfolioSectionFormModel>()
    const { name } = watch({ nest: true })
    const [update, { loading, error }] = usePortfolioSectionUpdateMutation({
        portfolioSection,
        name,
        file,
        onCompleted,
    })
    return <PortfolioSectionForm write={update} loading={loading} error={error} />
}

interface PortfolioSectionCreateProps {
    latestSortKey?: string
}

function ModalPortfolioSectionCreate({ latestSortKey }: PortfolioSectionCreateProps) {
    const { onCompleted, file } = useContext(PortfolioSectionModalContext)
    const { watch } = useFormContext<PortfolioSectionFormModel>()
    const { name } = watch({ nest: true })
    const [create, { loading, error }] = usePortfolioSectionCreateMutation({
        name,
        file,
        onCompleted,
        latestSortKey,
    })
    return <PortfolioSectionForm write={create} loading={loading} error={error} />
}

interface ModalPortfolioSectionProps extends BasicModalProps, ModalFormProps {
    portfolioSection?: PortfolioSection
    latestSortKey?: string
}

export default function ModalPortfolioSection({ mode, isOpen, onClose, portfolioSection, latestSortKey }: ModalPortfolioSectionProps) {
    const [fileToBeUploaded, setFileToBeUploaded] = useState<File | undefined>(undefined)
    const [fileCustomisations, setFileCustomisations] = useState<FileCustomisation>(portfolioSection?.banner?.customisation || {})
    const previewUrl = useMemo(
        () => (fileToBeUploaded && URL.createObjectURL(fileToBeUploaded)) || portfolioSection?.banner?.public_url || undefined,
        [fileToBeUploaded, portfolioSection]
    )
    const formMethods = useForm<PortfolioSectionFormModel>({
        submitFocusError: true,
        validateCriteriaMode: 'all',
        defaultValues: {
            name: portfolioSection?.name || '',
        },
    })
    const finish = () => {
        const form = formMethods.getValues()
        formMethods.reset(mode === ModalFormMode.UPDATE ? form : undefined)
        onClose()
    }
    const { upload, uploadProgress, resetProgress } = useUploader({
        onCompleted: () => {
            finish()
        },
    })
    useEffect(() => {
        if (isOpen) {
            resetProgress()
            setFileToBeUploaded(undefined)
            setFileCustomisations(mode === ModalFormMode.UPDATE ? portfolioSection?.banner?.customisation || {} : {})
        }
    }, [isOpen, resetProgress, mode, portfolioSection])

    const onCompleted = async (data: Partial<Mutation>) => {
        const portfolioSection = data.createPortfolioSection || data.updatePortfolioSection
        if (fileToBeUploaded && portfolioSection) {
            upload({ id: portfolioSection.portfolio_section_key, handler: 'PortfolioSection', file: fileToBeUploaded, access_level: 'public' })
        } else {
            finish()
        }
    }
    const file: FileUpload = {
        file: fileToBeUploaded,
        previewUrl,
        fileCustomisations,
    }

    return (
        <PortfolioSectionModalContext.Provider
            value={{
                isOpen,
                onClose,
                file,
                setFile: setFileToBeUploaded,
                setFileCustomisations: setFileCustomisations,
                uploadProgress,
                onCompleted,
            }}
        >
            <FormContext {...formMethods}>
                {mode === ModalFormMode.CREATE && <ModalPortfolioSectionCreate latestSortKey={latestSortKey} />}
                {mode === ModalFormMode.UPDATE && portfolioSection && <ModalPortfolioSectionUpdate portfolioSection={portfolioSection} />}
            </FormContext>
        </PortfolioSectionModalContext.Provider>
    )
}
