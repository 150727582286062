import React, { useState } from 'react'
import { classes, stylesheet } from 'typestyle'
import { em } from 'csx'
import { Link } from 'react-router-dom'
import { DropdownMenu, DynamicImage, Theme, PortfolioSection, SlugType } from 'vo-components'
import Dialog from '../../components/Dialog'
import ModalPortfolioSection from '../allsite/ModalPortfolioSection'
import ModalSlug from '../allsite/ModalSlug'
import { usePortfolioSectionsDeleteMutation, usePortfolioSectionsSortMutation } from '../../graphql/usePortfolioSections'
import { DragPlaceholder, useSortKey, Sortable } from '../../components/Sortable'
import { Thumbnail } from '../../models/ThumbnailModel'
import { ModalFormMode } from '../../models/ModalModel'

const styles = stylesheet({
    container: {
        position: 'relative',
    },
    title: {
        marginTop: em(1),
        marginBottom: em(1),
        color: Theme.colors.greyDark,
        $nest: {
            '&:hover': {
                color: Theme.colors.black,
            },
        },
    },
})

interface PortfolioSectionItemProps extends Sortable, Thumbnail {
    portfolioSection: PortfolioSection
}

export default function PortfolioSectionItem({
    portfolioSection,
    placeholder,
    previousSortKey,
    nextSortKey,
    isPreviewOnly,
}: PortfolioSectionItemProps) {
    const [isModalPortfolioSectionOpen, setIsModalPortfolioSectionOpen] = useState<boolean>(false)
    const [isModalSlugOpen, setIsModalSlugOpen] = useState<boolean>(false)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
    const [deletePortfolioSection, { loading: deleteLoading, error: deleteError }] = usePortfolioSectionsDeleteMutation({ portfolioSection })
    const candidateSortKey = useSortKey({ originalSortKey: portfolioSection.sort_key || '', previousSortKey, nextSortKey, skip: !placeholder })
    const [sort] = usePortfolioSectionsSortMutation({ portfolioSection, sortKey: candidateSortKey })
    if (!portfolioSection) return null
    if (placeholder) {
        return <DragPlaceholder mutate={sort}>{ref => <div ref={ref} className="is-overlay" />}</DragPlaceholder>
    }
    return (
        <>
            <ModalSlug
                type={SlugType.PortfolioSection}
                isOpen={isModalSlugOpen}
                onClose={() => setIsModalSlugOpen(false)}
                portfolioSection={portfolioSection}
                baseUrl="/portfolio"
            />
            <ModalPortfolioSection
                mode={ModalFormMode.UPDATE}
                isOpen={isModalPortfolioSectionOpen}
                portfolioSection={portfolioSection}
                onClose={() => setIsModalPortfolioSectionOpen(false)}
            />

            <div className={styles.container}>
                <div>
                    <Link to={`portfolio/${portfolioSection.slug}`}>
                        <figure className="image is-1by1">
                            <DynamicImage
                                src={portfolioSection.banner?.public_url || ''}
                                srcSet={[{ maxScreenWidth: 480, imageWidth: 480 }]}
                                width={720}
                            />
                        </figure>
                    </Link>
                    {!isPreviewOnly && (
                        <div className="is-overlay is-top-right">
                            <DropdownMenu>
                                <button onClick={() => setIsModalPortfolioSectionOpen(true)} className="button is-white dropdown-item">
                                    Edit
                                </button>
                                <button onClick={() => setIsModalSlugOpen(true)} className="button is-white dropdown-item">
                                    Change URL
                                </button>
                                <button onClick={() => setIsDeleteDialogOpen(true)} className="button is-white dropdown-item has-text-danger">
                                    Delete
                                </button>
                            </DropdownMenu>
                        </div>
                    )}
                </div>

                {!isPreviewOnly ? (
                    <Link to={`portfolio/${portfolioSection.slug}`}>
                        <h2 className={classes('is-size-5 is-uppercase has-letter-spacing has-text-weight-bold', styles.title)}>
                            <u>{portfolioSection.name}</u>
                        </h2>
                    </Link>
                ) : (
                    <h2 className={classes('is-size-5 is-uppercase has-letter-spacing has-text-weight-bold', styles.title)}>
                        {portfolioSection.name}
                    </h2>
                )}
                <Dialog
                    isOpen={isDeleteDialogOpen}
                    onClose={() => setIsDeleteDialogOpen(false)}
                    onYes={() => deletePortfolioSection().catch(console.error)}
                    title="Are you sure you want to delete this portfolio section?"
                    subtitle="All collections within this section will be deleted as well..."
                    yesLabel="Yes, delete this portfolio section"
                    noLabel="Cancel"
                    loading={deleteLoading}
                    error={deleteError}
                />
            </div>
        </>
    )
}
