import React, { useContext, useEffect, useState } from 'react'
import { classes, media, stylesheet } from 'typestyle'
import { em, important, percent } from 'csx'
import { NavLink, useLocation } from 'react-router-dom'
import { Theme } from 'vo-components'
import { zIndex } from '../util/themes'
import { AuthContext } from '../Authenticator'

const styles = stylesheet({
    navbar: {
        marginTop: em(2),
        zIndex: zIndex.NAVBAR,
        ...media(Theme.screen.phone, {
            marginTop: 0,
        }),
    },
    brandContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    navbarItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        letterSpacing: 2,
        fontSize: em(0.75),
        $nest: {
            '&:hover': {
                backgroundColor: important('white'),
                color: important(Theme.colors.black),
                $nest: {
                    '&::after': {
                        content: "''",
                        height: 2,
                        background: Theme.colors.black,
                        display: 'block',
                        marginTop: 2,
                        width: percent(100),
                    },
                },
            },
            '&:focus': {
                backgroundColor: important('white'),
                color: important(Theme.colors.black),
            },
        },
    },
    navbarItemActive: {
        color: important(Theme.colors.black),
        $nest: {
            '&::after': {
                content: "''",
                height: 2,
                background: Theme.colors.black,
                display: 'block',
                marginTop: 2,
                width: percent(100),
            },
        },
    },
})

export default function Header() {
    const { logout } = useContext(AuthContext)
    const [isActive, setIsActive] = useState(false)
    const location = useLocation()
    useEffect(() => {
        setIsActive(false)
    }, [location])
    return (
        <>
            <div className="buttons is-right">
                <button onClick={() => logout()} className="button is-text is-right">
                    Log out
                </button>
            </div>
            <nav className={classes('navbar is-marginless', styles.navbar)} role="navigation" aria-label="main navigation">
                <div className="container">
                    <div className="navbar-brand">
                        <a className={classes('navbar-item', styles.brandContainer)} href="https://veronicaonggoro.com">
                            <p>VERONICA</p>
                            <p>ONGGORO</p>
                        </a>

                        <button
                            onClick={() => {
                                setIsActive(!isActive)
                            }}
                            className={classes('button is-white navbar-burger burger', isActive && 'is-active')}
                        >
                            <span aria-hidden="true" />
                            <span aria-hidden="true" />
                            <span aria-hidden="true" />
                        </button>
                    </div>

                    <div className={classes('navbar-menu', isActive && 'is-active')}>
                        <div className="navbar-start">
                            <NavLink
                                to="/"
                                className={classes('navbar-item has-text-weight-bold', styles.navbarItem)}
                                exact
                                activeClassName={styles.navbarItemActive}
                            >
                                DASHBOARD
                            </NavLink>
                            <NavLink
                                to="/clients"
                                className={classes('navbar-item has-text-weight-bold', styles.navbarItem)}
                                activeClassName={styles.navbarItemActive}
                            >
                                CLIENTS
                            </NavLink>
                        </div>
                        <div className="navbar-end">
                            <NavLink
                                to="/home"
                                className={classes('navbar-item has-text-weight-bold', styles.navbarItem)}
                                exact
                                activeClassName={styles.navbarItemActive}
                            >
                                HOME
                            </NavLink>
                            <NavLink
                                to="/portfolio"
                                className={classes('navbar-item has-text-weight-bold', styles.navbarItem)}
                                activeClassName={styles.navbarItemActive}
                            >
                                PORTFOLIO
                            </NavLink>
                            <NavLink
                                to="/projects"
                                className={classes('navbar-item has-text-weight-bold', styles.navbarItem)}
                                activeClassName={styles.navbarItemActive}
                            >
                                PROJECTS
                            </NavLink>
                            <NavLink
                                to="/contact-me"
                                className={classes('navbar-item has-text-weight-bold', styles.navbarItem)}
                                activeClassName={styles.navbarItemActive}
                            >
                                CONTACT ME
                            </NavLink>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}
